import { find, flatMapDeep } from 'lodash-es'
import { Category } from '@/modules/categories/types'

const getSelectableCategoryForSearchTerm = (
  categories: Category[],
  categorySearchTerm: string | undefined
): Category | undefined => {
  if (categorySearchTerm) {
    // search term provided -> search for it in categories
    const foundCategory = findCategoryBySearchTerm(categories, categorySearchTerm)

    if (foundCategory && foundCategory.isSelectable) {
      // category found and is selectabled -> return found category
      return foundCategory
    } else if (foundCategory && !foundCategory.isSelectable) {
      // category found but is not selectable -> search in children
      return getFirstSelectableCategory(foundCategory.children || [])
    } else if (!foundCategory) {
      // no category found for search term -> return first selectable category
      return getFirstSelectableCategory(categories)
    }
  } else {
    // no search term provided -> return first selectable category
    return getFirstSelectableCategory(categories)
  }
}

const getFirstSelectableCategory = (categories: Category[]): Category | undefined => {
  return findFirstSelectableCategory(getCategoriesRootNode(categories))
}

const findFirstSelectableCategory = (category: Category): Category | undefined => {
  if (category.isSelectable) {
    return category
  } else if (category.children && category.children.length > 0) {
    let result = undefined
    for (let i = 0; result == undefined && i < category.children.length; i++) {
      result = findFirstSelectableCategory(category.children[i])
    }
    return result
  }
  return undefined
}

const findCategoryBySearchTerm = (
  categories: Category[],
  categorySearchTerm: string
): Category | undefined => {
  const getChildren = (category: Category): (Category | Category[])[] | Category => {
    if (!category.children || !category.children.length) {
      return category
    }
    return [category, flatMapDeep(category.children, getChildren)]
  }

  return find(
    flatMapDeep(categories, getChildren),
    ({ searchTerm }) => searchTerm == categorySearchTerm
  )
}

const getOpenCategories = (categories: Category[], category: Category): Category[] => {
  const mapToParent = new Map<number, Category>()

  const recurse = (arr: Category[], parent: Category) => {
    arr.forEach((cat) => {
      mapToParent.set(cat.id, parent)
      if (cat.children) {
        recurse(cat.children, cat)
      }
    })
  }

  recurse(categories, getCategoriesRootNode(categories))

  const openCategories: Category[] = [category]

  let parent = mapToParent.get(category.id)
  while (parent && parent.id != 0) {
    openCategories.push(parent)
    parent = mapToParent.get(parent.id)
  }

  return openCategories
}

const removeEmptyChildren = (categories: Category[]): Category[] => {
  const recurse = (cat: Category) => {
    if (!cat.children || cat.children.length === 0) {
      cat.children = undefined
      return
    }

    cat.children.forEach((child) => {
      recurse(child)
    })
  }

  categories.forEach((category) => recurse(category))

  return categories
}

const getCategoriesRootNode = (categories: Category[]): Category => {
  return {
    id: 0,
    name: 'root',
    searchTerm: 'root',
    isSelectable: false,
    children: categories
  }
}

export {
  getSelectableCategoryForSearchTerm,
  getOpenCategories,
  removeEmptyChildren,
  findCategoryBySearchTerm
}
