import { ProductSearchView } from '@/modules/product/types'

type InvitationDTO = {
  isAlreadyRegistered?: string[]
  isAlreadyInvited?: string[]
  invalidEmails?: string[]
}

type UserPermissions = {
  submitOrder: boolean
  orderLimit?: number
  shopAdmin: boolean
  changeSubsidiary?: boolean
  isApprover: boolean
}

type CustomTerms = {
  isCustomTerms: boolean
  terms?: string
}

type User = {
  id: string
  title: ContactTitle
  academicTitle: AcademicTitle
  firstName: string
  lastName: string
  email: string
  avatarUrl?: string
  alpha2language: string
  companyName: string
  department: Department
  position?: string
  phoneMobile?: string
  phoneOffice?: string
  fax?: string
  permission: UserPermissions
  setting: UserSettings
}

type GuestUser = {
  title: ContactTitle
  firstName: string
  lastName: string
  email: string
  isRegistred: boolean
}

type UserSettings = {
  preferedProductSearchView: ProductSearchView
}

type UserPermissionPatchDTO = {
  submitOrder?: boolean
  shopAdmin?: boolean
  changeSubsidiary?: boolean
}

type UserSettingsPatchDTO = {
  preferedProductSearchView?: ProductSearchView
}

type ShortUser = {
  id: string
  avatarUrl?: string
  firstName?: string
  lastName?: string
  email: string
  department: Department
  userRegistered: boolean
  companyName: string
}

type ContactUser = {
  firstName: string
  lastName: string
  email: string
  avatarUrl?: string
  phoneMobile?: string
  phoneOffice?: string
}

type ChangePassword = {
  currentPassword: string
  newPassword: string
}

type Password = {
  password: string
}

enum EmailStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  REGISTERED = 'REGISTERED',
  INVITED = 'INVITED',
  BLACKLISTED = 'BLACKLISTED'
}

enum ContactTitle {
  Male = 'Male',
  Female = 'Female',
  Diverse = 'Diverse',
  Unspecified = 'Unspecified'
}

enum AcademicTitle {
  None = 'None',
  Bachelor = 'Bachelor',
  Master = 'Master',
  Magister = 'Magister',
  Diplom = 'Diplom',
  DiplFH = 'DiplFH',
  Doktor = 'Doktor',
  Professor = 'Professor',
  ProfessorDr = 'ProfessorDr',
  PhD = 'PhD'
}

enum Department {
  Sales = 'Sales',
  Purchasing = 'Purchasing',
  Marketing = 'Marketing',
  ServiceSupport = 'ServiceSupport',
  IT = 'IT',
  Accounting = 'Accounting',
  Management = 'Management',
  Extern = 'Extern',
  Other = 'Other',
  RegistrationPending = 'RegistrationPending'
}

type Selection = {
  value: number
  title: string
  translation: string
}

export {
  AcademicTitle,
  ChangePassword,
  ContactTitle,
  ContactUser,
  CustomTerms,
  Department,
  EmailStatus,
  GuestUser,
  InvitationDTO,
  Password,
  Selection,
  ShortUser,
  User,
  UserPermissionPatchDTO,
  UserPermissions,
  UserSettings,
  UserSettingsPatchDTO
}
