import { ShortUser } from '@/modules/user/types'
import { ArticleNumber, SimplePrice, SimpleStock } from '@/modules/product/types'

type ShopCollection = {
  id: string
  name: string
  createdDate: string
  lastModifiedDate?: string
  itemCount: number
  assignee?: ShortUser
  isArchived: boolean
}

type DetailedShopCollection = {
  id: string
  name: string
  createdDate: string
  lastModifiedDate?: string
  assignee?: ShortUser
  description?: string
  items: CollectionItem[]
  isArchived: boolean
}

type CollectionItem = {
  productId: number
  quantity: number
  productTitle?: string
  productImageUrl?: string
  comment?: string
  price?: SimplePrice
  stock?: SimpleStock
  articleNumber?: ArticleNumber
}

type CollectionItemPatch = {
  comment?: string
  quantity?: number
}

type CollectionPatch = {
  name?: string
  description?: string
  assignedUserId?: string
}

type CollectionName = {
  name: string
}

type CollectionItemPostDTO = {
  productId: number
}

type SendCollectionPostDTO = {
  emails: string[]
  subject?: string
  withAttachment: boolean
}

type ProductCollectionRelation = 'including' | 'excluding'

enum CollectionsSortOrder {
  DATE_DESC = 'DATE_DESC',
  DATE_ASC = 'DATE_ASC',
  LAST_MODIFIED = 'LAST_MODIFIED',
  TITLE_DESC = 'TITLE_DESC',
  TITLE_ASC = 'TITLE_ASC'
}

type CollectionId = {
  id: string
}

type PagedCollections = {
  collections: ShopCollection[]
  page: number
  pageCount: number
}

export {
  ShopCollection,
  DetailedShopCollection,
  CollectionName,
  CollectionPatch,
  CollectionItem,
  CollectionItemPostDTO,
  CollectionItemPatch,
  CollectionsSortOrder,
  SendCollectionPostDTO,
  CollectionId,
  PagedCollections,
  ProductCollectionRelation
}
