import { ApiError, handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import api from '@/modules/quote/api'
import helpers from '@/modules/message/helpers'
import { QuoteRequestDraft } from '@/modules/quote/types'
import useQuoteRequest from '@/modules/quote/useQuoteRequest'

interface AddToQuoteState {
  quotesExcludingProduct: QuoteRequestDraft[]
  quotesIncludingProduct: QuoteRequestDraft[]
  isInQuote: boolean
}

const initialState = (): AddToQuoteState => {
  return {
    quotesExcludingProduct: [],
    quotesIncludingProduct: [],
    isInQuote: false
  }
}

const useAddProductToQuoteRequest = defineStore('addToQuote', {
  state: () => initialState(),
  actions: {
    addToQuoteRequests(draftQuotes: QuoteRequestDraft[], productId: number, reload = false) {
      if (draftQuotes.length > 0) {
        this.isInQuote = true
        const promises: Promise<string>[] = []

        draftQuotes.forEach((quote) => {
          promises.push(
            api.addToQuoteRequest(quote.id, { productId }).then((data) => {
              helpers.reportSuccess('success.addedToQuoteLink', [quote.name], `/quotes/${quote.id}`)
              if (reload) {
                useQuoteRequest().getQuoteRequest(quote.id, true)
              }
              return Promise.resolve(data.data).catch((error: ApiError) => {
                handleApiError(error, {
                  appearance: 'NONE'
                })
                return Promise.reject(error)
              })
            })
          )
        })
        return Promise.allSettled(promises)
      }
    },

    async addToNewQuoteRequest(name: string, productId: number) {
      this.isInQuote = true
      const items = [{ productId: productId }]
      return api
        .createQuoteRequest({ name, items })
        .then(({ data }) => {
          helpers.reportSuccess('success.addedToQuoteLink', [name], `/quotes/${data}`)
        })
        .catch(handleApiError)
    },
    async getDraftQuotes(productId: string) {
      return api
        .getDraftQuoteRequestForProduct(productId)
        .then(({ data }) => {
          this.quotesIncludingProduct = []
          this.quotesExcludingProduct = []
          data.forEach((item) => {
            item.hasProduct
              ? this.quotesIncludingProduct.push(item)
              : this.quotesExcludingProduct.push(item)
          })
        })
        .catch(handleApiError)
    },
    async isProductInQuoteRequest(productId: string) {
      return api.isProductInDraftQuoteRequest(productId).then(({ data }) => {
        this.isInQuote = data
      })
    }
  }
})

export default useAddProductToQuoteRequest
