import { Cart, SupplierCart } from '@/modules/cart/types'
import { SimpleStock, StockStatus } from '@/modules/product/types'

const isProductUnavailable = (stock: SimpleStock): boolean => {
  return stock.status === StockStatus.NotAvailable || stock.status === StockStatus.Unknown
}

const isSupplierCart = (cart: Cart | SupplierCart): cart is SupplierCart => {
  return !('supplierCarts' in (cart as SupplierCart))
}

export { isProductUnavailable, isSupplierCart }
