<template>
  <list-content v-if="quoteRequest" class="mt-1">
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12" sm="6" md="">
          <v-card height="100%">
            <v-card-item>
              <v-card-title>
                <sf-heading>{{ $t('quotes.author') }}</sf-heading>
              </v-card-title>
            </v-card-item>
            <v-card-text v-if="quoteRequest.author">
              <avatar-image ref="avatar" :user="quoteRequest.author" :size="40" class="mr-1" />
              <sf-text ref="name">{{ getFullName(quoteRequest.author) }}</sf-text>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="">
          <v-card height="100%">
            <v-card-item>
              <v-card-title>
                <sf-heading>{{ $t('collection.data') }}</sf-heading>
              </v-card-title>
            </v-card-item>
            <v-card-text class="d-flex flex-column">
              <sf-text>
                {{ $t('createdDate') }}:
                {{ formatDate(quoteRequest.createdDate, false) }}
              </sf-text>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="isEditable || quoteRequest.comment" cols="12" md="">
          <comment-card
            v-model="quoteRequest.comment"
            :editable="isEditable"
            @debounce="updateComment"
          />
        </v-col>
      </v-row>

      <template v-if="isEditable">
        <v-divider class="my-2" />
        <v-row dense align="center">
          <v-col cols="12" sm="">
            <quote-request-add-product-input />
          </v-col>
          <v-col cols="12" sm="auto">
            <sf-text-button @click="addFreeLineItem">
              {{ $t('quoteRequest.textLineItemAdd') }}
            </sf-text-button>
          </v-col>
        </v-row>
      </template>
      <v-divider class="my-2" />

      <v-row dense>
        <v-col>
          <request-position
            v-for="item in quoteRequest.items"
            :key="item.productId"
            :quote-item="item"
            :editable="isEditable"
          />
        </v-col>
      </v-row>

      <unsaved-free-line-item v-if="showTextItemPrototype" @submit="createTextItem" />

      <v-row v-if="!isQuotesPortal && quoteRequest.items.length > 0" dense>
        <v-col class="d-flex justify-end text-h5 font-weight-bold">
          <span>∑ {{ formatMoney(quoteRequest.sum, true, true) }}</span>
        </v-col>
      </v-row>

      <list-empty
        v-if="quoteRequest.items.length === 0 && !showTextItemPrototype"
        :message="$t('quoteRequest.emptyRequest')"
        :icon="mdiStarOutline"
      />
    </v-col>
  </list-content>
</template>

<script lang="ts">
import AvatarImage from '@/components/AvatarImage.vue'
import CommentCard from '@/components/CommentCard.vue'
import SfTextButton from '@/components/button/SfTextButton.vue'
import ListContent from '@/components/list/ListContent.vue'
import ListEmpty from '@/components/list/ListEmpty.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { getFullName, isQuotesPortal } from '@/helpers'
import { formatMoney } from '@/modules/product/helpers'
import QuoteRequestAddProductInput from '@/modules/quote/components/QuoteRequestAddProductInput.vue'
import RequestPosition from '@/modules/quote/components/RequestPosition.vue'
import UnsavedFreeLineItem from '@/modules/quote/components/UnsavedFreeLineItem.vue'
import { getQuoteStatusColor } from '@/modules/quote/helpers'
import { QuoteStatus } from '@/modules/quote/types'
import useQuote from '@/modules/quote/useQuote'
import useQuoteRequest from '@/modules/quote/useQuoteRequest'
import QuoteEvent from '@/modules/tracking/events/quote'
import { trackEvent } from '@/modules/tracking/useTracking'
import { formatDate } from '@/utils/timeFormatHelpers'
import { mdiStarOutline } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { computed, defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'RequestDetails',
  components: {
    AvatarImage,
    CommentCard,
    ListContent,
    ListEmpty,
    QuoteRequestAddProductInput,
    RequestPosition,
    SfHeading,
    SfText,
    SfTextButton,
    UnsavedFreeLineItem
  },
  setup() {
    const { rejecting, quote } = storeToRefs(useQuote())
    const { quoteRequest } = storeToRefs(useQuoteRequest())
    const currentRoute = useRoute()

    const rejectQuote = (rejectionComment: string) => {
      useQuote().rejectQuote(currentRoute.params.id as string, rejectionComment)
      trackEvent(QuoteEvent.Decline)
    }

    const showTextItemPrototype = ref(false)
    const addFreeLineItem = () => {
      showTextItemPrototype.value = true
    }
    const createTextItem = (label: string) => {
      if (label) {
        useQuoteRequest().addLineItem({ label }, true)
      }
      showTextItemPrototype.value = false
    }

    const updateComment = (comment: string) => {
      if (quoteRequest.value) {
        useQuoteRequest().updateQuoteRequest(quoteRequest.value.id, {
          comment
        })
      }
    }

    const isEditable = computed(() => {
      return quote.value && quote.value.quoteStatus === QuoteStatus.DRAFT
    })

    return {
      addFreeLineItem,
      createTextItem,
      formatDate,
      formatMoney,
      getFullName,
      getQuoteStatusColor,
      isEditable,
      isQuotesPortal,
      quote,
      quoteRequest,
      QuoteStatus,
      rejecting,
      rejectQuote,
      showTextItemPrototype,
      updateComment,
      mdiStarOutline
    }
  }
})
</script>
