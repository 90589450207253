import { ShortUser } from '@/modules/user/types'

type Address = {
  id?: string
  companyName: string
  addition1: string
  addition2: string
  street: string
  zipCode: string
  city: string
  postBox: string
  iso3country: string
  phone: string
  fax: string
  email: string
  clientNumber: string
  url: string
  isPrimaryAddress?: boolean
  isDefaultBillingAddress: boolean
  isDefaultDeliveryAddress: boolean
  user?: ShortUser
  canEdit: boolean
}

type OrderAddress = {
  companyName: string
  clientNumber?: string
  addition1?: string
  addition2?: string
  street: string
  zipCode: string
  city: string
  iso3country: string
  phone: string
  fax: string
  email: string
  url: string
}

type Dialables = 'phone' | 'fax'

enum AddressType {
  BILLING = 'billing',
  DELIVERY = 'delivery'
}

type AddressValidation = {
  valid: boolean
  error?: string
}

export { Address, Dialables, AddressType, AddressValidation, OrderAddress }
