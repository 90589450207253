<template>
  <sf-large-button
    v-if="bigButton"
    id="order-button"
    :block="$vuetify.display.xs"
    :to="{
      name: 'Checkout',
      params: { id: supplierCartId }
    }"
    :disabled="canOrder == CanOrderType.DISABLED"
  >
    {{ $t('order.goToOrder') }}
  </sf-large-button>
  <sf-text-button
    v-else
    :block="$vuetify.display.xs"
    :to="{
      name: 'Checkout',
      params: { id: supplierCartId }
    }"
    :disabled="canOrder == CanOrderType.DISABLED"
  >
    {{ $t('order.goToOrder') }}
  </sf-text-button>
</template>

<script lang="ts">
import SfLargeButton from '@/components/button/SfLargeButton.vue'
import SfTextButton from '@/components/button/SfTextButton.vue'
import { CanOrderType } from '@/modules/cart/types'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'CartOrderButton',
  components: {
    SfTextButton,
    SfLargeButton
  },
  props: {
    supplierCartId: {
      type: String,
      default: undefined
    },
    canOrder: {
      type: String as PropType<CanOrderType>,
      required: true
    },
    bigButton: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      CanOrderType
    }
  }
})
</script>
