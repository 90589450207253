import { Event } from '@/modules/tracking/types'

export default class ApplicationHeaderEvent extends Event {
  static ProductComparison = new ApplicationHeaderEvent('Produktvergleich')
  static Logo = new ApplicationHeaderEvent('Logo angeklickt')
  static Cart = new ApplicationHeaderEvent('Einkaufswagen')
  static Profile = new ApplicationHeaderEvent('Mein Profil')
  static Logout = new ApplicationHeaderEvent('Ausloggen')
  static GenericMenuItem: (germanName: string) => ApplicationHeaderEvent = (germanName: string) =>
    new ApplicationHeaderEvent(germanName)
  static InviteColleagues = new ApplicationHeaderEvent('Kollegen eingeladen')
  constructor(action: string, label?: string) {
    super('Anwendungsheader', action, label ?? '')
  }
}
