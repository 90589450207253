<template>
  <v-dialog :model-value="dialog" scrollable :width="500" @keydown.esc="toggleDialog">
    <template #activator="{ props }">
      <sf-icon-button
        v-bind="props"
        :color="isInCollection ? 'green' : 'primary'"
        @click="toggleDialog"
      >
        {{ isInCollection ? mdiStarCheckOutline : mdiStarPlusOutline }}
      </sf-icon-button>
    </template>
    <v-card>
      <v-card-item>
        <v-card-title>
          <sf-heading>{{ $t('collection.collectProduct') }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text class="d-flex flex-column">
        <template v-if="includingProduct.collections.length > 0">
          <div
            class="d-flex flex-column pb-1 overflow-y-auto overflow-x-hidden scrollbar"
            style="max-height: 128px"
          >
            <div
              v-for="collectionIncludingProduct in includingProduct.collections"
              :key="collectionIncludingProduct.id"
              class="d-flex align-center"
            >
              <v-icon color="green" class="mr-1" :icon="mdiCheck" />
              <sf-text class="text-truncate">
                {{ collectionIncludingProduct.name }}
              </sf-text>
            </div>
            <div v-intersect="loadMoreCollectionsIncludingProduct" />
          </div>
          <v-divider class="my-1" />
        </template>
        <sf-text class="mb-1">
          {{ $t('collection.addProductToExistingCollection') }}
        </sf-text>
        <v-autocomplete
          v-model="selectedCollections"
          v-model:search="search"
          color="primary"
          variant="outlined"
          density="compact"
          clearable
          chips
          closable-chips
          multiple
          hide-details
          :items="excludingProduct.collections"
          item-title="name"
          item-value="id"
          return-object
          :no-data-text="$t('collection.noCollectionAvailable')"
        >
          <template #append-item>
            <div v-intersect="loadMoreCollectionsExcludingProduct" />
          </template>
        </v-autocomplete>
        <sf-text class="my-1">
          {{ $t('collection.addProductToNewCollection') }}
        </sf-text>
        <sf-text-field v-model="newCollection" clearable hide-details autocomplete="off" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <sf-text-button
          color="primary"
          :disabled="(!selectedCollections || selectedCollections.length === 0) && !newCollection"
          @click="addToCollection"
        >
          {{ $t('save') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import SfIconButton from '@/components/button/SfIconButton.vue'
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfTextField from '@/components/input/SfTextField.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { ShopCollection } from '@/modules/collection/types'
import useAddToCollection from '@/modules/collection/useAddToCollection'
import { Event } from '@/modules/tracking/types'
import { trackEvent } from '@/modules/tracking/useTracking'
import { mdiCheck } from '@mdi/js'
import { debounce } from 'lodash-es'
import { storeToRefs } from 'pinia'
import { defineComponent, PropType, ref, watch, watchEffect } from 'vue'
import { VDivider } from 'vuetify/components'
import { mdiStarCheckOutline, mdiStarPlusOutline } from '@mdi/js'

export default defineComponent({
  name: 'CollectionButton',
  components: {
    SfTextField,
    SfHeading,
    SfIconButton,
    SfTextButton,
    SfText,
    VDivider
  },
  props: {
    productId: {
      type: Number,
      required: true
    },
    addToCollectionEvent: {
      type: Object as PropType<Event>,
      default: undefined
    },
    createCollectionEvent: {
      type: Object as PropType<Event>,
      default: undefined
    }
  },
  setup(props) {
    const dialog = ref(false)
    const search = ref('')
    const newCollection = ref<string | undefined>(undefined)
    const selectedCollections = ref<ShopCollection[]>([])
    const { includingProduct, excludingProduct, isInCollection } = storeToRefs(useAddToCollection())

    watchEffect(() => {
      useAddToCollection().checkIsInCollection(props.productId)
    })

    const toggleDialog = () => {
      if (!dialog.value) {
        useAddToCollection().getFirstPage(search.value, props.productId, 'excluding')
        useAddToCollection().getFirstPage(search.value, props.productId, 'including')
        search.value = ''
        selectedCollections.value = []
        newCollection.value = undefined
      }
      dialog.value = !dialog.value
    }

    const addToCollection = () => {
      if (newCollection.value) {
        if (typeof newCollection.value === 'string') {
          useAddToCollection().addToNewCollection(newCollection.value, props.productId)
        }
        if (props.createCollectionEvent) {
          trackEvent(props.createCollectionEvent.withProduct(props.productId))
        }
      }
      if (selectedCollections.value) {
        useAddToCollection().addToCollections(selectedCollections.value, props.productId)
        if (props.addToCollectionEvent) {
          trackEvent(props.addToCollectionEvent.withProduct(props.productId))
        }
      }
      dialog.value = !dialog.value
    }

    const loadMoreCollectionsExcludingProduct = (isIntersecting: boolean) => {
      if (isIntersecting) {
        useAddToCollection().getNextPage(search.value, props.productId, 'excluding')
      }
    }

    const loadMoreCollectionsIncludingProduct = (isIntersecting: boolean) => {
      if (isIntersecting) {
        useAddToCollection().getNextPage(search.value, props.productId, 'including')
      }
    }

    watch(search, () => {
      debounce(
        (newValue) => useAddToCollection().getFirstPage(newValue, props.productId, 'excluding'),
        500
      )
    })

    return {
      isInCollection,
      excludingProduct,
      includingProduct,
      dialog,
      selectedCollections,
      newCollection,
      search,
      addToCollection,
      toggleDialog,
      loadMoreCollectionsExcludingProduct,
      loadMoreCollectionsIncludingProduct,
      mdiCheck,
      mdiStarCheckOutline,
      mdiStarPlusOutline
    }
  }
})
</script>
