<template>
  <v-autocomplete
    v-model="selection"
    class="my-1"
    color="primary"
    :multiple="multiple"
    density="compact"
    variant="outlined"
    hide-details
    clearable
    :custom-filter="customFilter"
    :menu-props="{ closeOnContentClick: true }"
    :items="filterNode.values"
    :placeholder="filterNode.name"
    :no-data-text="$t('noDataAvailable')"
    return-object
    item-value="term"
    item-title="name"
    :disabled="disabled"
    @update:model-value="
      () => {
        $emit('update')
      }
    "
    @update:focused="
      (focus) => {
        hasFocus = focus
      }
    "
  >
    <template v-if="multiple" #selection="{ index }">
      <span v-if="index === 0 && !hasFocus">{{ filterNode.name }}</span>
    </template>
    <template v-else #selection="{ item }">
      <span v-if="!hasFocus">
        {{ item.raw.name + (item.raw.count ? ' (' + item.raw.count + ')' : '') }}
      </span>
    </template>
    <template #item="{ item, props }">
      <v-list-item v-bind="props" title="">
        <v-list-item-title>
          {{ item.raw.name + (item.raw.count ? ' (' + item.raw.count + ')' : '') }}
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
  <template v-if="multiple && selection">
    <v-chip
      v-for="filter in selection"
      :key="toFilterValue(filter).term"
      class="mr-1 mb-1"
      :closable="!disabled"
      @click:close="onRemove(filter)"
    >
      {{ toFilterValueUnsafe(filter).name }}
    </v-chip>
  </template>
</template>

<script lang="ts">
import { FilterIdentifier, FilterValue } from '@/components/filter/types'
import FilterEvent from '@/modules/tracking/events/filter'
import { trackEvent } from '@/modules/tracking/useTracking'
import { InternalItem } from '@/types'
import { computed, defineComponent, PropType, Ref, ref } from 'vue'
import { isFilterValue, isFilterValueArray } from '@/components/filter/helpers'
import { difference } from 'lodash-es'

export default defineComponent({
  name: 'SearchFilterInput',
  props: {
    filterNode: {
      type: Object as PropType<FilterIdentifier>,
      required: true
    },
    getSelectionForFilter: {
      type: Function as PropType<(name: string) => FilterValue[] | undefined>,
      required: true
    },
    updateFilterSelection: {
      type: Function as PropType<(name: string, value: FilterValue[] | undefined) => void>,
      required: true
    },
    removeItemFromFilterSelection: {
      type: Function as PropType<(name: string, item: FilterValue) => void>,
      required: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    filterEvent: {
      type: Function as PropType<(label: string) => FilterEvent | undefined>,
      default: undefined
    }
  },
  emits: ['update'],
  setup(props, { emit }) {
    const hasFocus = ref(false)
    let selection: Ref<FilterValue | FilterValue[] | undefined>

    if (props.multiple) {
      selection = computed({
        get: () => props.getSelectionForFilter(props.filterNode.name),
        set: (value) => {
          trackFilterChange(value)
          props.updateFilterSelection(props.filterNode.name, value)
        }
      })
    } else {
      selection = computed({
        get: () => props.getSelectionForFilter(props.filterNode.name)?.[0],
        set: (value) => {
          trackFilterChange(value)
          props.updateFilterSelection(props.filterNode.name, value ? [value] : [])
        }
      })
    }

    const trackFilterChange = (value: FilterValue | FilterValue[] | undefined) => {
      let oldValues: FilterValue[] = []
      let newValues: FilterValue[] = []

      if (isFilterValue(value)) {
        newValues = [value]
      } else if (isFilterValueArray(value)) {
        newValues = value
      }

      if (selection.value == undefined) {
        oldValues = []
      } else if (isFilterValue(selection.value)) {
        oldValues = [selection.value]
      } else if (isFilterValueArray(selection.value)) {
        oldValues = selection.value
      }

      const calculatedDifference = difference(newValues, oldValues)

      if (calculatedDifference.length > 0 && props.filterEvent) {
        const event = props.filterEvent(props.filterNode.name + ':' + calculatedDifference[0].name)
        if (event) {
          if (event.action == 'Filter geändert' && event.label.startsWith('Hersteller:')) {
            event.action = 'Herstellerfilter geändert'
            event.label = event.label.substring(11)
          }
          trackEvent(event)
        }
      }
    }

    const customFilter = (_: string, query: string, item?: InternalItem<FilterValue>) => {
      return item?.raw.name.toLowerCase().includes(query.toLocaleLowerCase()) || false
    }

    const onRemove = (filter: FilterValue) => {
      props.removeItemFromFilterSelection(props.filterNode.name, filter)
      emit('update')
    }

    const toFilterValue = (filter: FilterValue) => filter as FilterValue
    const toFilterValueUnsafe = (filter: FilterValue) => filter as FilterValue

    return {
      customFilter,
      hasFocus,
      selection,
      onRemove,
      toFilterValue,
      toFilterValueUnsafe
    }
  }
})
</script>
