<template>
  <three-point-menu dense>
    <v-list-item ref="add-to-cart" @click="addToCart">
      <v-list-item-title>{{ $t('addToCart') }}</v-list-item-title>
    </v-list-item>

    <v-list-item ref="archive-order" @click="toggleOrderArchivation">
      <v-list-item-title>
        {{ order.isArchived ? $t('common.dearchive') : $t('common.archive') }}
      </v-list-item-title>
    </v-list-item>

    <sf-delete-confirm-dialog
      v-if="isDeletable"
      ref="delete-order"
      :title="$t('order.deleteOrder').toString()"
      :description="$t('order.deleteDialog').toString()"
      @confirm="deleteOrder"
    >
      <template #activator="{ props }">
        <v-list-item v-bind="props">
          <v-list-item-title class="text-error">{{ $t('order.deleteOrder') }}</v-list-item-title>
        </v-list-item>
      </template>
    </sf-delete-confirm-dialog>
  </three-point-menu>
</template>

<script lang="ts">
import SfDeleteConfirmDialog from '@/components/dialog/SfDeleteConfirmDialog.vue'
import ThreePointMenu from '@/components/three-point-menu/ThreePointMenu.vue'
import { deleteableStatuses } from '@/modules/order/helpers'
import { DetailedOrder } from '@/modules/order/types'
import useOrder from '@/modules/order/useOrder'
import OrderEvent from '@/modules/tracking/events/order'
import { trackEvent } from '@/modules/tracking/useTracking'
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'OrderMenu',
  components: { ThreePointMenu, SfDeleteConfirmDialog },
  props: {
    order: {
      type: Object as PropType<DetailedOrder>,
      required: true
    }
  },
  setup(props) {
    const isDeletable = computed(() => deleteableStatuses.includes(props.order.orderStatus))

    const toggleOrderArchivation = () => {
      useOrder().archiveOrder(props.order.id, !props.order.isArchived)
      trackEvent(props.order.isArchived ? OrderEvent.Dearchive : OrderEvent.Archive)
    }

    const deleteOrder = () => {
      useOrder().deleteOrder(props.order.id)
    }

    const addToCart = () => {
      useOrder().addToCart(props.order.id)
      trackEvent(OrderEvent.AddToCart)
    }

    return { isDeletable, toggleOrderArchivation, deleteOrder, addToCart }
  }
})
</script>
