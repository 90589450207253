enum Relation {
  NONE = 'NONE',
  BRANCH = 'BRANCH',
  OWNER = 'OWNER'
}

type ImpersonationStatus = {
  relation: Relation
  customer: string
}

type Subsidiary = {
  id: string
  name: string
}

export { Relation, ImpersonationStatus, Subsidiary }
