import { ActivityView } from '@/modules/quote/types'
import { Event } from '@/modules/tracking/types'
import { addressEvents } from '@/modules/tracking/events/addresses'
import {
  checkoutCommentEvents,
  checkoutOrderNumberEvents,
  checkoutPositionCommentEvents
} from '@/modules/tracking/events/checkout'

export default class QuoteEvent extends Event {
  constructor(action: string, label?: string) {
    super('Angebot', action, label ?? '')
  }

  static Download = class {
    static Quote = new QuoteEvent('Ein Angebot wurde heruntergeladen.')
  }

  static ToCart = class {
    static InHeader = new QuoteEvent('In den Warenkorb', 'Über Header Button')
    static InBody = new QuoteEvent('In den Warenkorb', 'Über Button im Angebot')
  }

  static CreateRequestFromCollection = new QuoteEvent(
    'Anfrage erstellt',
    'Anfrage aus Sammlung erstellt'
  )

  static CreateRequest = new QuoteEvent(
    'Anfrage erstellt',
    'Anfrage aus der Angebotsliste erstellt'
  )

  static SendRequest = new QuoteEvent('Anfrage abgesendet')
  static RequestAgain = new QuoteEvent('Erneut angefragt')
  static DeleteRequest = new QuoteEvent('Anfrage gelöscht')
  static EditRequestTitle = new QuoteEvent('Titel der Anfrage bearbeitet')
  static Decline = new QuoteEvent('Angebot abgelehnt')
  static TextSearch = new QuoteEvent('Angebot suchen')
  static Archive = new QuoteEvent('Archiviert')
  static Dearchive = new QuoteEvent('Dearchiviert')
  static AddMessage = new QuoteEvent('Nachricht geschrieben')
  static Accept = new QuoteEvent('Angebot bestellt')

  static ToggleView = (view: string): QuoteEvent => {
    if (view === ActivityView.CHAT) {
      return new QuoteEvent('Aktivitätenansicht umgeschaltet', 'Kommentare')
    }
    if (view === ActivityView.HISTORY) {
      return new QuoteEvent('Aktivitätenansicht umgeschaltet', 'Änderungen')
    }

    return new QuoteEvent('Aktivitätenansicht umgeschaltet', view)
  }
}

export class QuoteCheckoutEvent extends Event {
  constructor(action: string, label?: string) {
    super('Angebots-Checkout', action, label ?? '')
  }
  static Address = class {
    static Billing = addressEvents('Rechnungsadresse', QuoteCheckoutEvent)
    static Shipping = addressEvents('Lieferadresse', QuoteCheckoutEvent)
  }
  static Comment = {
    Position: checkoutPositionCommentEvents(QuoteCheckoutEvent),
    Checkout: checkoutCommentEvents(QuoteCheckoutEvent)
  }
  static OrderNumber = checkoutOrderNumberEvents(QuoteCheckoutEvent)
  static SendOrder = new QuoteCheckoutEvent('Bestellung gesendet')
}

export class GuestQuoteEvent extends Event {
  constructor(action: string, label?: string) {
    super('Gastangebot', action, label ?? '')
  }

  static Accept = new GuestQuoteEvent('Angebot bestellt')
  static Reject = new GuestQuoteEvent('Angebot abgelehnt')
  static Print = new GuestQuoteEvent('Ein Angebot wurde heruntergeladen.')
  static AddMessage = new GuestQuoteEvent('Nachricht geschrieben')

  static ChangeAddress = addressEvents('Angebotsadresse', GuestQuoteEvent)
  static ChangeContactData = new GuestQuoteEvent('Kontaktdaten geändert')

  static ToggleView = (view: string): GuestQuoteEvent => {
    if (view === ActivityView.CHAT) {
      return new GuestQuoteEvent('Aktivitätenansicht umgeschaltet', 'Kommentare')
    }
    if (view === ActivityView.HISTORY) {
      return new GuestQuoteEvent('Aktivitätenansicht umgeschaltet', 'Änderungen')
    }

    return new GuestQuoteEvent('Aktivitätenansicht umgeschaltet', view)
  }

  static Register = new GuestQuoteEvent('Benutzer hat sich registriert')
}
