<template>
  <v-col cols="12" sm="6" md="4" lg="3">
    <v-card height="100%">
      <v-card-text class="d-flex flex-column ga-1">
        <div class="d-flex">
          <div ref="address" class="d-flex flex-column align-start">
            <sf-heading>{{ address.companyName }}</sf-heading>
            <sf-text>{{ address.addition1 }}</sf-text>
            <sf-text>{{ address.addition2 }}</sf-text>
            <sf-text v-if="address.postBox">
              {{ $t('address.postBox') }} {{ address.postBox }}
            </sf-text>
            <sf-text>{{ address.street }}</sf-text>
            <sf-text>{{ address.zipCode }} {{ address.city }}</sf-text>
            <sf-text>
              {{ getCountryName(address.iso3country, $i18n.locale) }}
            </sf-text>
            <sf-text v-if="address.phone"> {{ $t('address.phone') }}: {{ address.phone }}</sf-text>
            <sf-text v-if="address.fax"> {{ $t('address.fax') }}: {{ address.fax }}</sf-text>
            <sf-text v-if="address.email">
              {{ $t('address.email') }}:
              <sf-link :href="'mailto:' + address.email">
                {{ address.email }}
              </sf-link>
            </sf-text>
            <sf-text v-if="address.clientNumber">
              {{ $t('address.clientNumber') }}: {{ address.clientNumber }}
            </sf-text>
            <sf-text v-if="address.url">
              <sf-link :href="getFullUrl(address.url)">{{ $t('address.website') }}</sf-link>
            </sf-text>
            <address-default-chips v-if="showAddressDefaultChips(address)" :address class="mt-1" />
          </div>
          <v-spacer />
          <address-form-dialog
            v-if="address.canEdit"
            :address-data="address"
            :show-delete-button="!address.isPrimaryAddress"
            :disable-default-billing-address-checkbox="
              address.isDefaultBillingAddress || !permissions.canSetDefaultBillingAddress
            "
            :disable-default-delivery-address-checkbox="
              address.isDefaultDeliveryAddress || !permissions.canSetDefaultDeliveryAddress
            "
            :show-default-address-checkboxes="!isQuotesPortal"
            :title="$t('editAddress')"
            @delete="$emit('delete', address.id)"
            @save="saveAddress"
          >
            <template #activator="{ props }">
              <sf-edit-button v-bind="props" />
            </template>
          </address-form-dialog>
        </div>
        <div v-if="address.user" class="d-flex align-center">
          <avatar-image class="mr-1" :user="address.user" :size="30" />
          <sf-text>{{ getFullName(address.user) }}</sf-text>
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script lang="ts">
import AvatarImage from '@/components/AvatarImage.vue'
import SfEditButton from '@/components/button/SfEditButton.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfLink from '@/components/text/SfLink.vue'
import SfText from '@/components/text/SfText.vue'
import { getFullName, getFullUrl, isQuotesPortal } from '@/helpers'
import { defineComponent, PropType } from 'vue'
import { getCountryName, showAddressDefaultChips } from '@/modules/address/helpers'
import { Address } from '@/modules/address/types'
import AddressFormDialog from '@/modules/address/components/AddressFormDialog.vue'
import AddressDefaultChips from '@/modules/address/components/AddressDefaultChips.vue'
import { AddressPermissions } from '@/modules/portalSettings/types'

export default defineComponent({
  name: 'AddressCard',
  components: {
    SfText,
    SfHeading,
    SfLink,
    AddressFormDialog,
    SfEditButton,
    AvatarImage,
    AddressDefaultChips
  },
  props: {
    address: {
      type: Object as PropType<Address>,
      required: true
    },
    permissions: {
      type: Object as PropType<AddressPermissions>,
      required: true
    }
  },
  emits: ['delete', 'save'],
  setup(_, { emit }) {
    const saveAddress = (
      address: Address,
      changes: {
        defaultBillingChanged: boolean
        defaultDeliveryChanged: boolean
      }
    ) => {
      emit('save', address, changes)
    }

    return {
      saveAddress,
      getCountryName,
      getFullUrl,
      getFullName,
      isQuotesPortal,
      showAddressDefaultChips
    }
  }
})
</script>
