import { ShortProduct } from '@/modules/product/types'

type StartPage = {
  startPageType: StartPageType
  htmlText?: string
  featuredProducts: ShortProduct[]
}

enum StartPageType {
  categories = 'categories',
  individual = 'individual',
  popular = 'popular',
  productList = 'productList'
}

export { StartPage, StartPageType }
