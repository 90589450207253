import {
  DEFAULT_FILTER_TERM,
  FilterIdentifier,
  FilterValue,
  PRICE_RANGE_FILTER_PREFIX,
  PriceRangeFilter,
  STOCK_COUNT_FILTER_PREFIX
} from '@/components/filter/types'
import i18n from '@/plugins/i18n'

const getDefaultFilters = (
  filters: FilterIdentifier[],
  defaultTerm = DEFAULT_FILTER_TERM
): FilterIdentifier[] => {
  for (const filter of filters) {
    const filterValue = filter.values.find((filterValue) => filterValue.term === defaultTerm)

    if (filterValue) {
      return [{ name: filter.name, values: [filterValue] }]
    }
  }

  return []
}

const getSelectionForFilter = (
  selection: FilterIdentifier[],
  name: string
): FilterValue[] | undefined => {
  if (selection.findIndex((item) => item.name === name) === -1) {
    selection.push({ name, values: [] })
  }
  return selection.find((item) => item.name === name)?.values
}

const updateFilterSelection = (
  selection: FilterIdentifier[],
  name: string,
  items: FilterValue[] | undefined
): void => {
  if (selection.findIndex((item) => item.name === name) === -1) {
    selection.push({ name, values: [] })
  }
  const filter = selection.find((item) => item.name === name)
  if (filter && items) {
    filter.values = items
  }
}

const emptyFilterSelection = (selection: FilterIdentifier[], name: string): void => {
  const filter = selection.find((item) => item.name === name)
  if (filter) {
    filter.values = []
  }
}

const removeItemFromFilterSelection = (
  selection: FilterIdentifier[],
  name: string,
  item: FilterValue
): void => {
  const selectionGroup = getSelectionForFilter(selection, name)
  if (selectionGroup) {
    const index = selectionGroup.indexOf(item)
    if (index >= 0) {
      selectionGroup.splice(index, 1)
    }
  }
}

const addFilterSelection = (
  selection: FilterIdentifier[],
  filterIdentifier: FilterIdentifier,
  category: string
): void => {
  if (selection.findIndex((item) => item.name === filterIdentifier.name) === -1) {
    selection.push({ name: filterIdentifier.name, values: [] })
  }
  const filter = selection.find((item) => item.name === filterIdentifier.name)

  const categoryFilterValue = filterIdentifier.values.find(
    (filterValue) => filterValue.term == category
  )

  if (filter && categoryFilterValue) {
    filter.values = Array.of(categoryFilterValue)
  }
}

const isFilterValue = (filter: FilterValue | FilterValue[] | undefined): filter is FilterValue => {
  return filter != undefined && !Array.isArray(filter)
}

const isFilterValueArray = (
  filter: FilterValue | FilterValue[] | undefined
): filter is FilterValue[] => {
  return filter != undefined && Array.isArray(filter)
}

const getPriceRangeFilterTerm = (filter: PriceRangeFilter): string => {
  if (filter.start || filter.end) {
    return (
      PRICE_RANGE_FILTER_PREFIX +
      (filter.start ? filter.start : '') +
      '-' +
      (filter.end ? filter.end : '')
    )
  } else {
    return ''
  }
}

const getStockCountFilterTerm = (value: number | undefined): string => {
  if (value) {
    return STOCK_COUNT_FILTER_PREFIX + value
  } else {
    return ''
  }
}

const getPriceRangeFilterString = (filter: PriceRangeFilter): string => {
  if (filter.start && filter.end) {
    return i18n.global.t('priceRange.fromToPriceRange', [filter.start, filter.end])
  } else if (filter.start && !filter.end) {
    return i18n.global.t('priceRange.fromPriceRange', [filter.start])
  } else if (!filter.start && filter.end) {
    return i18n.global.t('priceRange.toPriceRange', [filter.end])
  } else {
    return ''
  }
}

const decodePriceRangeFilterTerm = (term: string): PriceRangeFilter => {
  const values = term.substring(3).split('-')

  return {
    start: values[0] ? Number.parseInt(values[0]) : undefined,
    end: values[1] ? Number.parseInt(values[1]) : undefined
  }
}

const decodeStockCountFilterTerm = (term: string): number => {
  return Number.parseInt(term.substring(3))
}

const isPositiveInteger = (number: number) => {
  return parseInt(number + '') == number && number > 0
}

export {
  addFilterSelection,
  decodePriceRangeFilterTerm,
  decodeStockCountFilterTerm,
  emptyFilterSelection,
  getDefaultFilters,
  getPriceRangeFilterString,
  getPriceRangeFilterTerm,
  getSelectionForFilter,
  getStockCountFilterTerm,
  isFilterValue,
  isFilterValueArray,
  isPositiveInteger,
  removeItemFromFilterSelection,
  updateFilterSelection
}
