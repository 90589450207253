import { ShortUser } from '@/modules/user/types'

type DefaultOrderLimit = {
  activated: boolean
  companyOrderLimit?: number
  defaultApprovers: ShortUser[]
}

type OrderLimitUser = {
  id: string
  avatarUrl?: string
  firstName: string
  lastName: string
  submitOrder: boolean
  orderLimitType: OrderLimitType
  userOrderLimit?: number
  approvers: ShortUser[]
}

type OrderLimitUserPatch = {
  submitOrder: boolean
  userOrderLimit?: number
  orderLimitType: OrderLimitType
  approverIds: string[]
}

type DefaultOrderLimitPatch = {
  activated?: boolean
  defaultOrderLimit?: number
  approverIds?: string[]
}

enum OrderLimitType {
  UNLIMITED = 'UNLIMITED',
  DEFAULT = 'DEFAULT',
  USER = 'USER'
}

export {
  DefaultOrderLimit,
  OrderLimitUser,
  OrderLimitUserPatch,
  DefaultOrderLimitPatch,
  OrderLimitType
}
