<template>
  <div class="flex-column flex-grow-1 text-right pl-1 mb-1">
    <div v-if="showShippingCost">
      <sf-text>
        {{ $t('order.shippingCosts') }}
        {{ formatMoney(cartInfo.shippingCost, false, true) }}
      </sf-text>
    </div>
    <div>
      <sf-text v-if="showMinimumOrderSurcharge">
        {{ $t('order.quantitySurcharge') }}
        {{ formatMoney(cartInfo.quantitySurcharge, false, true) }}
      </sf-text>
    </div>
    <div>
      <sf-text>
        {{
          cartInfo.vatPercent === 0
            ? $t('order.inEuTotalTax', [cartInfo.vatPercent, zeroTaxFormatted])
            : $t(grossPortal ? 'order.inclTotalTax' : 'order.exclTotalTax', [
                cartInfo.vatPercent,
                formatMoney(cartInfo.totalTax, false, true)
              ])
        }}
      </sf-text>
    </div>
    <div>
      <sf-title v-if="bigSum">
        {{
          'Σ ' + formatMoney(isSupplierCart ? cartInfo.itemCost : cartInfo.totalCost, false, true)
        }}
      </sf-title>
      <sf-text v-else>
        {{
          'Σ ' + formatMoney(isSupplierCart ? cartInfo.itemCost : cartInfo.totalCost, false, true)
        }}
      </sf-text>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import SfText from '@/components/text/SfText.vue'
import SfTitle from '@/components/text/SfTitle.vue'
import { formatMoney } from '@/modules/product/helpers'
import { CartInfo } from '@/modules/cart/types'
import { storeToRefs } from 'pinia'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'

export default defineComponent({
  name: 'PriceTable',
  components: {
    SfText,
    SfTitle
  },
  props: {
    cartInfo: {
      type: Object as PropType<CartInfo>,
      required: true
    },
    grossPortal: {
      type: Boolean,
      required: true
    },
    isSupplierCart: {
      type: Boolean,
      required: true
    },
    bigSum: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const { portalSettings } = storeToRefs(usePortalSettings())

    const zeroTaxFormatted = ref<string>(
      formatMoney({ value: 0, currency: props.cartInfo.totalTax.currency }, false, true)
    )

    const showShippingCost = computed(() => {
      if (props.isSupplierCart && portalSettings.value.shippingCostsOnlyApplyOncePerCart) {
        return false
      }
      return props.cartInfo.shippingCost && props.cartInfo.shippingCost.value
    })

    const showMinimumOrderSurcharge = computed(() => {
      if (props.isSupplierCart && portalSettings.value.minimumOrderSurchargesOnlyApplyOncePerCart) {
        return false
      }
      return props.cartInfo.quantitySurcharge && props.cartInfo.quantitySurcharge.value
    })

    return {
      formatMoney,
      portalSettings,
      showShippingCost,
      showMinimumOrderSurcharge,
      zeroTaxFormatted
    }
  }
})
</script>
