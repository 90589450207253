import { FilterIdentifier } from '@/components/filter/types'

type ShortProduct = {
  productId: number
  articleNumber: ArticleNumber
  title: string
  imageUrl?: string
  price?: SimplePrice
  stock?: SimpleStock
  productType: ProductType
  condition?: Condition
}

type MediumProduct = ShortProduct & {
  suppliers: SupplierRow[]
  description: string[]
}

type Product = MediumProduct & {
  manufacturer: Manufacturer
  productDescription: ProductDescription[]
  technicalDetails: TechnicalDetail[]
  manufacturerSuggestedRetailPrice?: Money
  isBundle: boolean
  imageUrls: string[]
  datasheet: Datasheet
  listedSince: string
}

type BundleItem = {
  product: BundleItemProduct
  amount: number
}

type BundleItemProduct = {
  productId: number
  manufacturer: Manufacturer
  productType: ProductType
  title: string
  articleNumber: ArticleNumber
  description: string[]
  datasheet: Datasheet
  listedSince: string
  imageUrls: string[]
  technicalDetails: TechnicalDetail[]
}

type ProductPriceUpdate = {
  productId: number
  price?: SimplePrice
  stock?: SimpleStock
  suppliers: SupplierRow[]
}

type AccessoryGroup = {
  groupName: string
  products: MediumProduct[]
}

type Manufacturer = {
  id: number
  name: string
}

type Money = {
  value: number
  currency: string
}

type ProductType = {
  id: number
  name: string
}

type Datasheet = {
  manufacturerUrl?: string
}

type ArticleNumber = {
  ean?: string
  manufacturerProductId?: string
  additionalId?: string
}

type SubscriptionModel = {
  billingPeriod?: number
  billingPeriodUnit?: string
  subscriptionPeriod?: number
  subscriptionPeriodUnit?: string
}

type PackagingModel = {
  contentUnit?: string
  intervalQuantity: number
  orderUnit?: string
  minQuantity: number
  supplierPackingQuantity: number
  priceQuantity: number
  numberContentUnitPerOrderUnit: number
}

type SimplePrice = {
  purchasePrice?: Money
  resellPrice: Money
  subscriptionModel?: SubscriptionModel
  packagingModel?: PackagingModel
  supplierRowId?: number
}

type SimpleStock = {
  status: StockStatus
  count: number
  deliveryDate?: string
  incomingAmount?: number
}

type Scale = {
  minScale: number
  resellPrice: Money
}

enum StockStatus {
  OnStock = 'OnStock',
  Available = 'Available',
  Ordered = 'Ordered',
  Warehouse = 'Warehouse',
  OnOrder = 'OnOrder',
  NotAvailable = 'NotAvailable',
  Unknown = 'Unknown'
}

type TechnicalDetail = {
  group: string
  name: string
  value: string
}

type SupplierRow = {
  id: number
  supplierName: string
  supplierPid?: string
  price?: SimplePrice
  stock?: SimpleStock
  scales: Scale[]
  condition?: Condition
}

type ProductIdentifier = {
  name: string
  value: string
}

type ProductDescription = {
  contentCategory: string
  value: string
}

type ProductShortDescription = {
  description: string[]
  type: ProductType
}

type RequestEmailResponse = {
  subject: string
  body?: string
}

type ProductFilterResponse = {
  filterIdentifiers: FilterIdentifier[]
  attributeIdentifiers: FilterIdentifier[]
}

enum ProductSearchView {
  CARDS = 'CARDS',
  LIST = 'LIST'
}

enum ProductSort {
  RELEVANCE = 'RELEVANCE',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  POPULARITY = 'POPULARITY',
  CALC_PRICE_ASC = 'CALC_PRICE_ASC',
  CALC_PRICE_DESC = 'CALC_PRICE_DESC',
  AVAILABILITY = 'AVAILABILITY',
  STOCK = 'STOCK'
}

enum Condition {
  NEW = 'NEW',
  BULK = 'BULK',
  BUILD_TO_ORDER = 'BUILD_TO_ORDER',
  FACTORY_REFURBISHED = 'FACTORY_REFURBISHED',
  REFURBISHED = 'REFURBISHED',
  DEMONSTRATION_MODEL = 'DEMONSTRATION_MODEL',
  B_STOCK = 'B_STOCK',
  RMA_WARE = 'RMA_WARE',
  USED = 'USED',
  NOT_FOR_RESALE = 'NOT_FOR_RESALE',
  NEWPULL = 'NEWPULL',
  USEDPULL = 'USEDPULL'
}

type RuntimeRequest = {
  productId: number
  scale: number
}

type ProductSearchOptions = Partial<{
  keyword: string
  filters: string[]
  category: string
  instantSearch: boolean
  page: number
  size: number
  sort: ProductSort
}>

type ProductPageMode = 'CATEGORY' | 'SEARCH'

export {
  AccessoryGroup,
  ArticleNumber,
  BundleItem,
  BundleItemProduct,
  Condition,
  Datasheet,
  Manufacturer,
  MediumProduct,
  Money,
  PackagingModel,
  Product,
  ProductDescription,
  ProductFilterResponse,
  ProductIdentifier,
  ProductPageMode,
  ProductPriceUpdate,
  ProductSearchOptions,
  ProductSearchView,
  ProductShortDescription,
  ProductSort,
  ProductType,
  RequestEmailResponse,
  RuntimeRequest,
  Scale,
  ShortProduct,
  SimplePrice,
  SimpleStock,
  StockStatus,
  SubscriptionModel,
  SupplierRow,
  TechnicalDetail
}
