import { OrderSearchModel } from '@/modules/order/types'
import { MediumProduct } from '@/modules/product/types'
import { InstantSearchTitle } from '@/modules/search/type'
import { VNode, h } from 'vue'

const isProduct = (product: unknown): product is MediumProduct => {
  return (product as MediumProduct).productId != undefined
}

const isOrder = (order: unknown): order is OrderSearchModel => {
  return (order as OrderSearchModel).orderNumber != undefined
}

const isSearchInstantTitle = (title: unknown): title is InstantSearchTitle => {
  return (title as InstantSearchTitle).key != undefined
}

// Inspired by vuetify function: https://github.com/vuetifyjs/vuetify/blob/master/packages/vuetify/src/components/VAutocomplete/VAutocomplete.tsx
const highlightResult = (text: string, searchTerm: string | undefined): VNode[] | string => {
  if (searchTerm == null) return text

  const firstMatch = text.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase())

  return firstMatch >= 0
    ? [
        h('span', { class: 'v-autocomplete__unmask' }, text.substring(0, firstMatch)),
        h(
          'span',
          { class: 'v-autocomplete__mask' },
          text.substring(firstMatch, firstMatch + searchTerm.length)
        ),
        h(
          'span',
          { class: 'v-autocomplete__unmask' },
          text.substring(firstMatch + searchTerm.length)
        )
      ]
    : text
}

export { isProduct, isOrder, isSearchInstantTitle, highlightResult }
