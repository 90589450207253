import { del, get, patch, post, Response } from '@/api'
import {
  User,
  ShortUser,
  UserPermissionPatchDTO,
  EmailStatus,
  InvitationDTO,
  ChangePassword,
  UserSettingsPatchDTO,
  GuestUser
} from '@/modules/user/types'

export default {
  getUsers: (): Promise<Response<ShortUser[]>> => {
    return get<ShortUser[]>('/user')
  },
  getOwnUser: (): Promise<Response<User>> => {
    return get<User>('/user/me')
  },
  getUser: (id: string): Promise<Response<User>> => {
    return get<User>('/user/' + id)
  },
  updateUser: (user: User): Promise<Response<string>> => {
    return patch<string, User>(`/user/${user.id}`, user)
  },
  updateAvatar: (id: string, data: FormData): Promise<Response<string>> => {
    return post<string, FormData>(`/user/${id}/avatar`, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
  },
  updateUserPermissions: (id: string, dto: UserPermissionPatchDTO): Promise<Response<string>> => {
    return patch<string, UserPermissionPatchDTO>(`/user/${id}/permission`, dto)
  },
  updateUserSettings: (id: string, dto: UserSettingsPatchDTO): Promise<Response<string>> => {
    return patch<string, UserSettingsPatchDTO>(`/user/${id}/setting`, dto)
  },
  validateMail: (mail: string, isAdmin: boolean): Promise<Response<EmailStatus>> => {
    return post<EmailStatus, { email: string; isAdmin: boolean }>(
      '/emails/validate',
      { email: mail, isAdmin: isAdmin },
      {}
    )
  },
  sendInvites: (mails: string[]): Promise<Response<InvitationDTO>> => {
    return post<InvitationDTO, { emails: string[] }>('/emails/invite', { emails: mails }, {})
  },
  deleteUser: (id: string): Promise<Response<string>> => {
    return del<string>('/user/' + id, {})
  },
  changePassword: (password: ChangePassword): Promise<Response<string>> => {
    return patch<string, ChangePassword>('/user/me/credentials', password)
  },
  getGuestUser: (): Promise<Response<GuestUser>> => {
    return get<GuestUser>('/guest/profile')
  }
}
