<template>
  <div>
    <form ref="formRef" :action="form.eprocurementUrl" method="post" :target="form.target">
      <span class="d-none" v-html="form.payload" />
      <sf-button :loading="sendingForm" @click="submit">{{
        $t('punchout.backToProcurementCaption', form.items.length)
      }}</sf-button>
    </form>
  </div>
</template>

<script lang="ts">
import SfButton from '@/components/button/SfButton.vue'
import { PunchoutForm } from '@/modules/punchout/types'
import { PunchoutEvent } from '@/modules/tracking/events/punchout'
import { trackEvent } from '@/modules/tracking/useTracking'
import { defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'PunchoutForm',
  components: { SfButton },
  props: {
    form: {
      type: Object as PropType<PunchoutForm>,
      required: true
    },
    isQuote: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const formRef = ref<HTMLFormElement>()

    const sendingForm = ref(false)
    const submit = async () => {
      sendingForm.value = true
      let event

      if (props.isQuote) {
        event = PunchoutEvent.ClickedPunchoutQuoteFormSubmit
      } else {
        event = PunchoutEvent.ClickedPunchoutFormSubmit
      }

      await trackEvent(event)
      formRef.value?.submit()
    }

    return {
      formRef,
      submit,
      sendingForm
    }
  }
})
</script>
