import { get, Response } from '@/api'
import { Subsidiary, ImpersonationStatus } from '@/modules/subsidiary/types'

export default {
  subsidiaries: (): Promise<Response<Subsidiary[]>> => {
    return get<Subsidiary[]>('/subsidiaries')
  },
  impersonationStatus: (): Promise<Response<ImpersonationStatus>> => {
    return get<ImpersonationStatus>('/subsidiaries/impersonation-status')
  },
  switchToSubsidiary: (subsidiaryId: string): Promise<Response<string>> => {
    return get<string>(`/subsidiaries/${subsidiaryId}/token`)
  }
}
