<template>
  <v-form ref="formRef" v-model="valid" lazy-validation>
    <v-row dense>
      <v-col cols="12">
        <sf-text-field
          ref="companyName"
          v-model="companyForm.name"
          :label="$t('user.company')"
          :rules="[rules.requiredRule]"
          hide-details="auto"
          autocomplete="organization"
        />
      </v-col>
      <v-col cols="12">
        <sf-text-field
          ref="street"
          v-model="companyForm.street"
          :label="$t('address.street')"
          :rules="[rules.requiredRule]"
          hide-details="auto"
          autocomplete="street-address"
        />
      </v-col>
      <v-col cols="4">
        <sf-text-field
          ref="zipCode"
          v-model="companyForm.zipCode"
          :label="$t('address.zipCode')"
          :rules="[rules.requiredRule]"
          hide-details="auto"
          autocomplete="postal-code"
        />
      </v-col>
      <v-col cols="8">
        <sf-text-field
          ref="city"
          v-model="companyForm.city"
          :label="$t('address.city')"
          :rules="[rules.requiredRule]"
          hide-details="auto"
          autocomplete="address-level2"
        />
      </v-col>
      <v-col cols="12">
        <sf-select
          ref="country"
          v-model="companyForm.country"
          :label="$t('address.country')"
          :items="getCountries($i18n.locale)"
          item-title="label"
          item-value="value"
          :rules="[rules.requiredRule]"
          autocomplete="country-name"
        />
      </v-col>
      <v-col cols="12">
        <sf-text-field
          ref="phone"
          v-model="companyForm.phone"
          :label="$t('address.phone')"
          :rules="[rules.requiredRule, rules.phoneRule]"
          hide-details="auto"
          autocomplete="tel"
        />
      </v-col>
    </v-row>

    <sf-large-button :disabled="!valid" block class="mt-2" @click="submit">
      {{ $t('requestRegistration') }}
    </sf-large-button>
  </v-form>
</template>

<script lang="ts">
import SfLargeButton from '@/components/button/SfLargeButton.vue'
import SfSelect from '@/components/input/SfSelect.vue'
import SfTextField from '@/components/input/SfTextField.vue'
import { validateForm } from '@/helpers'
import { getCountries } from '@/modules/address/helpers'
import { CompanyDTO } from '@/modules/authentication/types'
import rules from '@/rules'
import { VForm } from '@/types'
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'SelfRegistrationCompany',
  components: { SfLargeButton, SfSelect, SfTextField },
  emits: ['submit'],
  setup(_, { emit }) {
    const companyForm = ref<CompanyDTO>({
      name: '',
      street: '',
      zipCode: '',
      city: '',
      country: '',
      phone: ''
    })
    const valid = ref(true)
    const formRef = ref<VForm>()

    const submit = async () => {
      if (await !validateForm(formRef)) {
        return
      }
      const company: CompanyDTO = companyForm.value
      emit('submit', company)
    }

    return {
      companyForm,
      rules,
      valid,
      submit,
      formRef,
      getCountries
    }
  }
})
</script>
