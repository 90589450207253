import { defineStore } from 'pinia'
import { AccessoryGroup } from '@/modules/product/types'
import api from '@/modules/product/api'
import { ApiError, handleApiError } from '@/handleApiError'

interface State {
  accessories: AccessoryGroup[]
  loading: boolean
}

function initialState(): State {
  return {
    accessories: [],
    loading: false
  }
}

const useAccessories = defineStore('accessories', {
  state: initialState,
  actions: {
    async loadAccessories(id: number) {
      this.accessories = []
      this.loading = true
      return api
        .loadAccessories(id)
        .then(({ data }) => {
          this.accessories = data.filter((g) => g.products.length > 0)
        })
        .catch((error: ApiError) => handleApiError(error, { logError: false, appearance: 'NONE' }))
        .finally(() => (this.loading = false))
    }
  }
})

export default useAccessories
