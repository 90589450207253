import { Event } from '@/modules/tracking/types'
import AddToCartButtonEvents from '@/modules/tracking/events/helper/addToCartButton'

export default class CollectionEvent extends Event {
  static Sort = class {
    static Title = class {
      static Ascending = new CollectionEvent('Sortiert nach Titel', 'Aufsteigend')
      static Descending = new CollectionEvent('Sortiert nach Titel', 'Absteigend')
    }
    static Date = class {
      static Ascending = new CollectionEvent('Sortiert nach Datum', 'Aufsteigend')
      static Descending = new CollectionEvent('Sortiert nach Datum', 'Absteigend')
    }
    static LastModified = new CollectionEvent('Sortiert nach letzte Änderung')
  }
  static AddProductToCart: AddToCartButtonEvents = {
    addToCart: new CollectionEvent('Produkt zu Warenkorb hinzugefügt'),

    request: new CollectionEvent('Produkt angefragt')
  }
  static AddProduct = new CollectionEvent('Position hinzugefügt')
  static RemoveProduct = new CollectionEvent('Position entfernt')

  static Create = new CollectionEvent('Erstellt')
  static DuplicateCollection = new CollectionEvent(
    'Dupliziert',
    'Aus dem "Optionen"-Menu dupliziert'
  )
  static BuyCollection = new CollectionEvent('Zu Warenkorb hinzugefügt')
  static DeleteCollection = new CollectionEvent('Gelöscht')
  static Archive = new CollectionEvent('Archivieren')
  static Dearchive = new CollectionEvent('Dearchivieren')
  static ShareCollection = new CollectionEvent('Per E-Mail teilen')
  constructor(action: string, label?: string) {
    super('Sammlung', action, label ?? '')
  }
}
