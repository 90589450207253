import { get, post, del, patch, Response } from '@/api'
import {
  Cart,
  CartItemPostDTO,
  CartOptimizationResult,
  CartPatchDTO,
  OptimizationStrategy,
  OptimizationType,
  CartCount,
  CartItemMoveDTO,
  CartItemMoveInfo,
  SupplierCart
} from '@/modules/cart/types'

export default {
  loadCart: (realtime = false, vatCountry?: string): Promise<Response<Cart>> => {
    return get<Cart>('/carts', {
      params: {
        realtime,
        iso3country: vatCountry
      }
    })
  },

  loadSupplierCart: (
    id: string,
    realtime = false,
    vatCountry?: string
  ): Promise<Response<SupplierCart>> => {
    return get<SupplierCart>('/carts/' + id, {
      params: {
        realtime,
        iso3country: vatCountry
      }
    })
  },

  loadCartCount: (): Promise<Response<CartCount>> => {
    return get<CartCount>('/carts/items/count')
  },

  loadCartItemMoveInfo: (): Promise<Response<CartItemMoveInfo>> => {
    return get<CartItemMoveInfo>('/carts/items/move')
  },

  addCartItem: (item: CartItemPostDTO): Promise<Response<string>> => {
    return post<string, CartItemPostDTO>('/carts/items', item)
  },

  moveCartItem: (id: string, dto: CartItemMoveDTO): Promise<Response<string>> => {
    return post<string, CartItemMoveDTO>('/carts/items/' + id + '/move', dto)
  },

  removeCartItem: (id: string): Promise<Response<string>> => {
    return del<string>('/carts/items/' + id)
  },

  emptyCart: (): Promise<Response<string>> => {
    return post<string, null>('/carts/actions/empty')
  },

  createQuoteRequest: (): Promise<Response<string>> => {
    return post<string, null>('/carts/actions/request-quote')
  },

  patchCartItem: (id: string, item: CartPatchDTO): Promise<Response<string>> => {
    return patch<string, CartPatchDTO>('/carts/items/' + id, item)
  },

  getCartOptimizations: (): Promise<Response<OptimizationType[]>> => {
    return get<OptimizationType[]>('/carts/optimizations')
  },

  calculateCartOptimizations: (): Promise<Response<CartOptimizationResult[]>> => {
    return get<CartOptimizationResult[]>('/carts/optimizations/preview')
  },

  optimizeCart: (strategy: OptimizationStrategy): Promise<Response<string>> => {
    return post<string, null>('/carts/optimizations/apply', null, {
      params: {
        strategy
      }
    })
  }
}
