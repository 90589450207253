<template>
  <span
    v-if="
      subscriptionModel && subscriptionModel.billingPeriod && subscriptionModel.billingPeriodUnit
    "
  >
    {{
      $t('price.priceBillingPeriod', [
        formatDurationWithoutNumber(
          subscriptionModel.billingPeriod,
          subscriptionModel.billingPeriodUnit
        )
      ])
    }}
  </span>
</template>

<script lang="ts">
import { SubscriptionModel } from '@/modules/product/types'
import { formatDurationWithoutNumber } from '@/utils/timeFormatHelpers'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'BillingPeriod',
  props: {
    subscriptionModel: {
      type: Object as PropType<SubscriptionModel>,
      required: true
    }
  },
  setup() {
    return { formatDurationWithoutNumber }
  }
})
</script>
