<template>
  <v-card
    class="py-1 px-2 mb-1 pw-collection-row"
    :to="{ name: 'Collection', params: { id: collection.id } }"
  >
    <v-row dense>
      <v-col cols="auto" class="d-flex justify-center align-center">
        <avatar-image
          v-if="collection.assignee"
          ref="avatar"
          :user="collection.assignee"
          :size="40"
        />
      </v-col>
      <v-col cols="" class="d-flex flex-column justify-center">
        <sf-text class="text-truncate">{{ collection.name }}</sf-text>
        <sf-secondary-text ref="assignee">
          <template v-if="collection.assignee">
            <span>{{ getFullName(collection.assignee) }}</span>
            <span class="mx-1">-</span>
          </template>
          <span>{{ $t('positions', collection.itemCount) }}</span>
          <template v-if="collection.isArchived">
            <span class="mx-1">-</span>
            <span>{{ $t('common.archived') }}</span>
          </template>
        </sf-secondary-text>
      </v-col>
      <v-col
        v-if="$vuetify.display.mdAndUp"
        ref="created"
        cols="3"
        xl="2"
        class="d-flex justify-end align-center"
      >
        <sf-text v-if="collection.lastModifiedDate">
          {{ formatDate(collection.lastModifiedDate, true) }}
        </sf-text>
      </v-col>
      <v-col
        v-if="$vuetify.display.mdAndUp"
        ref="created"
        cols="3"
        xl="2"
        class="d-flex justify-end align-center"
      >
        <sf-text>
          {{ formatDate(collection.createdDate, true) }}
        </sf-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import AvatarImage from '@/components/AvatarImage.vue'
import SfSecondaryText from '@/components/text/SfSecondaryText.vue'
import SfText from '@/components/text/SfText.vue'
import { getFullName } from '@/helpers'
import { formatDate } from '@/utils/timeFormatHelpers'
import { defineComponent, PropType } from 'vue'
import { ShopCollection } from '@/modules/collection/types'

export default defineComponent({
  name: 'CollectionRow',
  components: {
    AvatarImage,
    SfText,
    SfSecondaryText
  },
  props: {
    collection: {
      type: Object as PropType<ShopCollection>,
      required: true
    }
  },
  setup() {
    return {
      getFullName,
      formatDate
    }
  }
})
</script>
