<template>
  <div class="d-flex flex-column">
    <sf-sub-heading>{{ address.companyName }}</sf-sub-heading>
    <sf-text v-if="address.addition1">{{ address.addition1 }}</sf-text>
    <sf-text v-if="address.addition2">{{ address.addition2 }}</sf-text>
    <sf-text>{{ address.street }}</sf-text>
    <sf-text>{{ address.zipCode }} {{ address.city }}</sf-text>
    <sf-text>{{ getCountryName(address.iso3country, $i18n.locale) }}</sf-text>
    <v-expansion-panels v-if="showExpansionPanel">
      <v-expansion-panel
        elevation="0"
        @group:selected="($event) => (contactDataOpen = $event.value)"
      >
        <v-expansion-panel-title hide-actions static class="justify-start">
          <v-icon :size="32">
            {{ contactDataOpen ? mdiChevronDown : mdiChevronRight }}
          </v-icon>
          <sf-text>{{ $t('address.details') }}</sf-text>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div class="d-flex flex-column">
            <sf-text v-if="address.clientNumber">{{
              $t('address.clientNumber') + ': ' + address.clientNumber
            }}</sf-text>
            <sf-text v-if="address.phone">{{ $t('address.phone') + ': ' + address.phone }}</sf-text>
            <sf-text v-if="address.fax">{{ $t('address.fax') + ': ' + address.fax }}</sf-text>
            <sf-text v-if="address.email">{{ $t('address.email') + ': ' + address.email }}</sf-text>
            <sf-text v-if="address.url">{{ $t('address.url') + ': ' + address.url }}</sf-text>
          </div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <address-default-chips v-if="showAddressDefaultChips(address)" :address class="mt-1" />
  </div>
</template>

<script lang="ts">
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { isQuotesPortal } from '@/helpers'
import { getCountryName, showAddressDefaultChips } from '@/modules/address/helpers'
import { Address } from '@/modules/address/types'
import { defineComponent, PropType, ref, computed } from 'vue'
import { mdiChevronDown, mdiChevronRight } from '@mdi/js'
import AddressDefaultChips from '@/modules/address/components/AddressDefaultChips.vue'

export default defineComponent({
  name: 'OrderAddress',
  components: { SfSubHeading, SfText, AddressDefaultChips },
  props: {
    address: {
      type: Object as PropType<Address>,
      required: true
    }
  },
  setup(props) {
    const contactDataOpen = ref(false)
    const showExpansionPanel = computed(() => {
      return (
        props.address.clientNumber ||
        props.address.phone ||
        props.address.fax ||
        props.address.email ||
        props.address.url
      )
    })

    return {
      contactDataOpen,
      getCountryName,
      isQuotesPortal,
      showExpansionPanel,
      mdiChevronDown,
      mdiChevronRight,
      showAddressDefaultChips
    }
  }
})
</script>

<style lang="scss" scoped>
.v-expansion-panels {
  justify-content: start;
}

.v-expansion-panel {
  min-height: 32px;
  padding: 0;

  &:deep(.v-expansion-panel-title__overlay) {
    opacity: 0;
  }
}

.v-expansion-panel-title {
  min-height: 32px;
  padding: 0;
}

:deep(.v-expansion-panel-text__wrapper) {
  padding: 0;
}
</style>
