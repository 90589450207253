<template>
  <div id="ordersummary" class="d-flex flex-column flex-sm-row align-start justify-space-between">
    <div class="d-flex order-last order-sm-first align-self-end align-self-sm-start mr-0 mr-md-2">
      <div class="d-flex flex-column mr-2">
        <sf-text v-if="!orderRejectionDate && orderSentDate">
          {{ $t('ordered') }}
        </sf-text>
        <sf-text v-if="!orderRejectionDate">{{ $t('updated') }}</sf-text>
        <sf-text v-if="orderRejectionDate">
          {{ $t('orderStatus.REJECTED') }}
        </sf-text>
      </div>
      <div class="d-flex flex-column">
        <sf-text v-if="!orderRejectionDate && orderSentDate" ref="sent-date" class="text-right">
          {{ formatDate(orderSentDate) }}
        </sf-text>
        <sf-text v-if="!orderRejectionDate" ref="last-update" class="text-right">
          {{ formatDate(orderLastUpdated) }}
        </sf-text>
        <sf-text v-if="orderRejectionDate" ref="rejected-update" class="text-right">
          {{ formatDate(orderRejectionDate) }}
        </sf-text>
      </div>
    </div>
    <v-spacer />
    <order-info-gross-table
      v-if="isGrossPortal"
      :shipping-cost="shippingCost"
      :quantity-surcharge="quantitySurcharge"
      :order-sum-information="orderSumInformation"
    />
    <order-info-net-table
      v-else
      :shipping-cost="shippingCost"
      :quantity-surcharge="quantitySurcharge"
      :order-sum-information="orderSumInformation"
    />
  </div>
</template>

<script lang="ts">
import { formatDate } from '@/utils/timeFormatHelpers'
import { defineComponent, PropType, ref } from 'vue'
import { OrderSumInformation } from '@/modules/order/types'
import { storeToRefs } from 'pinia'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import OrderInfoGrossTable from '@/modules/order/components/OrderInfoGrossTable.vue'
import OrderInfoNetTable from '@/modules/order/components/OrderInfoNetTable.vue'
import SfText from '@/components/text/SfText.vue'
import { Money } from '@/modules/product/types'

export default defineComponent({
  name: 'OrderInfo',
  components: { OrderInfoGrossTable, OrderInfoNetTable, SfText },
  props: {
    orderRejectionDate: {
      type: String,
      default: undefined
    },
    orderSentDate: {
      type: String,
      default: undefined
    },
    orderLastUpdated: {
      type: String,
      required: true
    },
    shippingCost: {
      type: Object as PropType<Money>,
      required: true
    },
    quantitySurcharge: {
      type: Object as PropType<Money>,
      default: undefined
    },
    orderSumInformation: {
      type: Object as PropType<OrderSumInformation>,
      required: true
    }
  },
  setup() {
    const { portalSettings } = storeToRefs(usePortalSettings())
    const isGrossPortal = ref<boolean>(portalSettings.value.grossPortal)

    return {
      formatDate,
      isGrossPortal
    }
  }
})
</script>
