import { Event } from '@/modules/tracking/types'
import AddToCartButtonEvents from '@/modules/tracking/events/helper/addToCartButton'

export default class ProductComparisonEvent extends Event {
  static HighlightDifferences = class {
    static on = new ProductComparisonEvent('Unterschiede hervorheben an')
    static off = new ProductComparisonEvent('Unterschiede hervorheben aus')
  }
  static AddToCart: AddToCartButtonEvents = {
    addToCart: new ProductComparisonEvent('In den Warenkorb gelegt'),
    request: new ProductComparisonEvent('Produkt angefragt')
  }
  static RemoveProduct = new ProductComparisonEvent('Produkt entfernt')
  constructor(action: string, label?: string) {
    super('Produktvergleich', action, label ?? '')
  }
}
