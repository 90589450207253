import { handleApiError } from '@/handleApiError'
import api from '@/modules/product/api'
import { BundleItem } from '@/modules/product/types'
import { defineStore } from 'pinia'

interface BundleItemsState {
  bundleItems: BundleItem[]
  loading: boolean
}

const initialState = (): BundleItemsState => {
  return {
    bundleItems: [],
    loading: false
  }
}

const useBundleItems = defineStore('bundleItems', {
  state: () => initialState(),
  actions: {
    async loadBundleItems(id: number) {
      this.loading = true

      return api
        .loadBundleItems(id)
        .then(({ data }) => {
          this.bundleItems = data
        })
        .catch(handleApiError)
        .finally(() => {
          this.loading = false
        })
    }
  }
})

export default useBundleItems
