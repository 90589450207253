<template>
  <list-row class="pw-order-row" :to="{ name: 'OrderDetails', params: { id: order.id } }">
    <template #title>
      <sf-text ref="title" class="text-truncate">
        {{ order.orderNumber }}
      </sf-text>
      <sf-secondary-text ref="info">
        <template v-if="order.distributorNames.length > 0">
          <span>{{ formatDistributors(order.distributorNames) }}</span>
          <span class="mx-1">-</span>
        </template>
        <span>{{ order.initiatorFullName }}</span>
        <span class="mx-1">-</span>
        <span>{{ $t('positions', order.itemCount) }}</span>
        <template v-if="order.isArchived">
          <span class="mx-1">-</span>
          <span ref="archived">{{ $t('common.archived') }}</span>
        </template>
      </sf-secondary-text>
    </template>
    <template #date>
      <sf-text v-if="order.sentDate" ref="date">
        {{ formatDate(order.sentDate) }}
      </sf-text>
    </template>
    <template #costs>
      <sf-heading ref="costs">
        {{ formatMoney(order.sum, false, true) }}
      </sf-heading>
    </template>
    <template #status>
      <sf-color-text ref="status" :color="getStatusColor(order.orderStatus)">
        {{ $t('orderStatus.' + order.orderStatus) }}
      </sf-color-text>
    </template>
  </list-row>
</template>

<script lang="ts">
import ListRow from '@/components/list/ListRow.vue'
import SfColorText from '@/components/text/SfColorText.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfSecondaryText from '@/components/text/SfSecondaryText.vue'
import SfText from '@/components/text/SfText.vue'
import { formatDistributors, getStatusColor } from '@/modules/order/helpers'
import { Order } from '@/modules/order/types'
import { formatMoney } from '@/modules/product/helpers'
import { formatDate } from '@/utils/timeFormatHelpers'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'OrderRow',
  components: { SfText, SfColorText, SfSecondaryText, ListRow, SfHeading },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true
    }
  },
  setup() {
    return {
      formatDate,
      formatMoney,
      formatDistributors,
      getStatusColor
    }
  }
})
</script>
