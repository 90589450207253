import {
  getDefaultFilters,
  getSelectionForFilter,
  removeItemFromFilterSelection,
  updateFilterSelection
} from '@/components/filter/helpers'
import { FilterIdentifier, FilterSelection, FilterValue } from '@/components/filter/types'
import { handleApiError } from '@/handleApiError'
import api from '@/modules/collection/api'
import { CollectionsSortOrder } from '@/modules/collection/types'
import { defineStore } from 'pinia'

interface CollectionsFilterState {
  filters: FilterIdentifier[]
  selection: FilterSelection<CollectionsSortOrder>
  loading: boolean
  initialized: boolean
}

const initSelection = (): FilterSelection<CollectionsSortOrder> => {
  return {
    sort: CollectionsSortOrder.DATE_DESC,
    filters: []
  }
}

const initialState = (): CollectionsFilterState => {
  return {
    filters: [],
    selection: initSelection(),
    loading: false,
    initialized: false
  }
}

const useCollectionsFilter = defineStore('collectionsFilter', {
  state: () => initialState(),
  getters: {
    getActiveFilters: (state) => {
      return state.selection.filters.filter((filter) => filter.values.length > 0).length
    }
  },
  actions: {
    async resetFilter() {
      await this.applyDefaultFilters()
    },
    getFilterTerm(): string | undefined {
      const filterTerm: string[] = []
      const filterValues = this.selection.filters.flatMap((filter) => filter.values)
      filterValues.forEach((value) => filterTerm.push(value.term))
      return filterTerm.length > 0 ? filterTerm.join(',') : undefined
    },
    async getCollectionsFilters(filter?: string) {
      this.loading = true

      return api
        .getCollectionsFilters(filter)
        .then(({ data }) => {
          this.filters = data
        })
        .catch(handleApiError)
        .finally(() => {
          this.loading = false
        })
    },
    async applyDefaultFilters() {
      await this.getCollectionsFilters()
      this.selection.filters = getDefaultFilters(this.filters)
      this.initialized = true
    },
    getSelectionForFilter(name: string) {
      return getSelectionForFilter(this.selection.filters, name)
    },
    updateFilterSelection(name: string, items: FilterValue[] | undefined) {
      updateFilterSelection(this.selection.filters, name, items)
    },
    removeItemFromFilterSelection(name: string, item: FilterValue) {
      removeItemFromFilterSelection(this.selection.filters, name, item)
    }
  }
})

export default useCollectionsFilter
