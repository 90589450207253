<template>
  <sf-color-text :color="getStatusColor(stock.status)">
    {{ stock.count > 0 ? stock.count : '' }}
    {{ $t('stockStatus.' + stock.status) }}
  </sf-color-text>
</template>

<script lang="ts">
import { getStatusColor } from '@/modules/product/helpers'
import { SimpleStock } from '@/modules/product/types'
import { defineComponent, PropType } from 'vue'
import SfColorText from '@/components/text/SfColorText.vue'

export default defineComponent({
  name: 'StockStatusLabel',
  components: { SfColorText },
  props: {
    stock: {
      type: Object as PropType<SimpleStock>,
      required: true
    }
  },
  setup() {
    return {
      getStatusColor
    }
  }
})
</script>
