import { get, Request, Response } from '@/api'
import { StartPage } from '@/modules/startPage/types'

export default {
  loadStartPage: (page: number, size: number): Promise<Response<StartPage>> => {
    const config: Request = {
      params: {
        page: page - 1,
        size: size
      }
    }

    return get<StartPage>('/startpages', config)
  }
}
