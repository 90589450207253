import { handleApiError } from '@/handleApiError'
import { isGuest } from '@/helpers'
import { LiveUpdateState } from '@/modules/eventStream/types'
import useEventStream from '@/modules/eventStream/useEventStream'
import api from '@/modules/quote/api'
import { QuoteActivity } from '@/modules/quote/types'
import { defineStore } from 'pinia'

interface QuoteActivityState extends LiveUpdateState {
  activityEntries: QuoteActivity[]
  loading: boolean
  sending: boolean
}

const initialState = (): QuoteActivityState => {
  return {
    activityEntries: [],
    updateStream: undefined,
    loading: false,
    sending: false
  }
}

const useQuotes = defineStore('quoteActivity', {
  state: () => initialState(),
  actions: {
    async getActivityEntries(quoteId: string, backgroundLoading = false) {
      this.loading = !backgroundLoading

      return api
        .getActivityEntries(quoteId, isGuest.value)
        .then(({ data }) => {
          this.activityEntries = data
        })
        .catch(handleApiError)
        .finally(() => {
          this.openActivitiesEntriesUpdateStream(quoteId, isGuest.value)
          this.loading = false
        })
    },
    openActivitiesEntriesUpdateStream(id: string, isGuest = false) {
      useEventStream.openStream(
        this.$state,
        api.getActivityEntriesUpdateStreamPath(id, isGuest),
        (update: QuoteActivity[]) => (this.activityEntries = update)
      )
    },
    closeActivitiesEntriesUpdateStream() {
      useEventStream.closeStream(this.$state)
    },
    async addActivityEntry(quoteId: string, comment: string) {
      this.sending = true

      return api
        .addActivityEntry(quoteId, { value: comment }, isGuest.value)
        .then(() => {
          this.getActivityEntries(quoteId, true)
        })
        .catch(handleApiError)
        .finally(() => {
          this.sending = false
        })
    }
  }
})

export default useQuotes
