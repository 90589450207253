import { Event } from '@/modules/tracking/types'

export default class FeatureAdEvent extends Event {
  static CallToAction = (): FeatureAdEvent =>
    new FeatureAdEvent('Call-To-Action geklickt', 'Anfragefunktion: Popup Startseite')

  static HideStart = (): FeatureAdEvent =>
    new FeatureAdEvent('Popup ausgeblendet', 'Anfragefunktion: Popup Startseite')

  static HideDetails = (): FeatureAdEvent =>
    new FeatureAdEvent('Popup ausgeblendet', 'Anfragefunktion: Popup Angebotsseite')

  protected constructor(action: string, label?: string) {
    super('Feature Ad', action, label ?? '')
  }
}
