<template>
  <div class="d-flex flex-column">
    <sf-sub-heading>{{ $t('contactData.contactData') + ':' }}</sf-sub-heading>
    <div>
      <sf-text v-if="contactData.firstName">{{ contactData.firstName + ' ' }}</sf-text>
      <sf-text>{{ contactData.lastName }}</sf-text>
    </div>
    <sf-text>{{ contactData.email }}</sf-text>
    <sf-text v-if="contactData.phone">{{ contactData.phone }}</sf-text>
  </div>
</template>

<script lang="ts">
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { ContactData } from '@/modules/contactData/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'OrderContactData',
  components: { SfText, SfSubHeading },
  props: {
    contactData: {
      type: Object as PropType<ContactData>,
      required: true
    }
  }
})
</script>
