import { Timestamped } from '@/utils/timestampUtils'
import { ArticleNumber, Condition, Money, SimplePrice, SimpleStock } from '@/modules/product/types'

const DEFAULT_POSITION_COMMENT_LENGTH = 255

type Cart = Timestamped & {
  supplierCarts: SupplierCart[]
  cartInfo: CartInfo
  canOrder: CanOrderType
  messages: CartMessage[]
}

type CartCount = {
  count: number
}

type CartItemMoveInfo = {
  availableSupplierCarts: Record<string, string[]>
}

type SupplierCart = Timestamped & {
  id: string
  supplierName?: string
  cartItems: CartItem[]
  cartInfo: CartInfo
  maxPositionCommentLength: number
  canOrder: CanOrderType
  messages: CartMessage[]
}

enum CanOrderType {
  FORBIDDEN = 'FORBIDDEN',
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED'
}

type CartItemSellerComment = {
  field1?: string
  field2?: string
  field3?: string
  field4?: string
  field5?: string
  field6?: string
}

type CartMessage = {
  type: CartMessageType
  message: string
  reason: CartMessageReason
}

enum CartMessageReason {
  MinOrderValue = 'MinOrderValue',
  Other = 'Other'
}

enum CartMessageType {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}

type CartItem = {
  id: string
  quantity: number
  productId: number
  articleNumber?: ArticleNumber
  productTitle?: string
  productImageUrl?: string
  price?: SimplePrice
  stock?: SimpleStock
  supplierComment?: string
  sellerComments: CartItemSellerComment
  condition?: Condition
}

type CartInfo = {
  shippingCost?: Money
  quantitySurcharge?: Money
  vatPercent: number
  totalTax: Money
  totalCost: Money
  itemCost: Money
}

type CartItemPostDTO = {
  id?: string
  productId: number
  supplierRowId?: number
  quantity: number
}

type CartItemMoveDTO = {
  toSupplierCartId: string
}

type CartPatchDTO = {
  quantity?: number
  supplierComment?: string
  sellerComments?: CartItemSellerComment
  timestamp: string
}

type OptimizationType = {
  optimizationStrategy: OptimizationStrategy
  customName?: string
}

type CartOptimizationResult = {
  optimizationStrategy: OptimizationStrategy
  sum: Money
  difference: Money
}

enum OptimizationStrategy {
  MANUAL = 'MANUAL',
  MINPRICE = 'MINPRICE',
  MINSUPPLIERS = 'MINSUPPLIERS',
  ADDITIONAL_SHOP_OPTIMIZATION = 'ADDITIONAL_SHOP_OPTIMIZATION'
}

export {
  CanOrderType,
  Cart,
  CartCount,
  CartInfo,
  CartItem,
  CartItemMoveDTO,
  CartItemMoveInfo,
  CartItemPostDTO,
  CartMessage,
  CartMessageReason,
  CartMessageType,
  CartOptimizationResult,
  CartPatchDTO,
  OptimizationStrategy,
  OptimizationType,
  SupplierCart,
  DEFAULT_POSITION_COMMENT_LENGTH
}
