<template>
  <div class="mt-2">
    <error-component
      v-if="mustHandleError"
      :code="error.code"
      :message="error.message"
      :transparent-background="true"
    />

    <v-row v-else dense>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="12" class="d-flex justify-center align-center">
            <search-bar :value="searchKeyword" @search="searchAddresses" @clear="resetSearch" />
            <v-spacer />
            <address-form-dialog
              v-if="portalSettings.addressPermissions.canCreate"
              :address-data="createEmptyAddress()"
              :show-delete-button="false"
              :disable-default-billing-address-checkbox="
                !portalSettings.addressPermissions.canSetDefaultBillingAddress
              "
              :disable-default-delivery-address-checkbox="
                !portalSettings.addressPermissions.canSetDefaultDeliveryAddress
              "
              :show-default-address-checkboxes="!isQuotesPortal"
              :title="$t('addAddress')"
              @save="createAddress"
            >
              <template #activator="{ props }">
                <sf-button v-bind="props">{{ $t('addAddress') }}</sf-button>
              </template>
            </address-form-dialog>
          </v-col>
        </v-row>

        <v-row v-if="loading" ref="addressLoading" dense>
          <v-col v-for="index in 12" :key="index" cols="12" sm="6" md="4" lg="3">
            <v-card class="pa-2">
              <v-skeleton-loader type="paragraph" />
              <v-skeleton-loader type="sentences" />
            </v-card>
          </v-col>
        </v-row>

        <v-row v-else id="addresses" dense>
          <address-card
            v-for="address in addressesFiltered"
            :key="address.id"
            :address="address"
            :permissions="portalSettings.addressPermissions"
            @delete="deleteAddress"
            @save="saveAddress"
          />
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import SearchBar from '@/components/SearchBar.vue'
import SfButton from '@/components/button/SfButton.vue'
import { isQuotesPortal, useTitle } from '@/helpers'
import AddressCard from '@/modules/address/components/AddressCard.vue'
import AddressFormDialog from '@/modules/address/components/AddressFormDialog.vue'
import { createEmptyAddress } from '@/modules/address/helpers'
import { Address } from '@/modules/address/types'
import useAddress from '@/modules/address/useAddress'
import ErrorComponent from '@/modules/error/components/ErrorComponent.vue'
import { customErrorHandling } from '@/modules/error/helpers'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import AddressEvent from '@/modules/tracking/events/addresses'
import { trackEvent } from '@/modules/tracking/useTracking'
import useOwnUser from '@/modules/user/useOwnUser'
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'AddressesPage',
  components: {
    SearchBar,
    AddressCard,
    AddressFormDialog,
    SfButton,
    ErrorComponent
  },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('myCompany')} - ${t('addresses')}`))
    const { error, mustHandleError } = customErrorHandling()
    useAddress().getAddresses()

    const { ownUser } = storeToRefs(useOwnUser())
    const { addressesFiltered, loading } = storeToRefs(useAddress())
    const { portalSettings } = storeToRefs(usePortalSettings())

    const createAddress = (address: Address) => {
      useAddress().createAddress(address)
      trackEvent(AddressEvent.Created)
      if (address.isDefaultBillingAddress) {
        trackEvent(AddressEvent.Set.DefaultBilling)
      }
      if (address.isDefaultDeliveryAddress) {
        trackEvent(AddressEvent.Set.DefaultDelivery)
      }
    }

    const deleteAddress = (id: string) => {
      useAddress().deleteAddress(id)
      trackEvent(AddressEvent.Deleted)
    }

    const saveAddress = (
      address: Address,
      changes: {
        defaultBillingChanged: boolean
        defaultDeliveryChanged: boolean
      }
    ) => {
      useAddress().updateAddress(address)
      trackEvent(AddressEvent.Edited)
      if (changes.defaultBillingChanged) {
        trackEvent(AddressEvent.Set.DefaultBilling)
      }
      if (changes.defaultDeliveryChanged) {
        trackEvent(AddressEvent.Set.DefaultDelivery)
      }
    }

    const searchKeyword = ref('')
    const searchAddresses = (keyword: string) => {
      useAddress().searchAddresses(keyword)
      searchKeyword.value = keyword
    }
    const resetSearch = () => {
      useAddress().resetSearch()
      searchKeyword.value = ''
    }

    return {
      searchKeyword,
      ownUser,
      loading,
      portalSettings,
      isQuotesPortal,
      createAddress,
      deleteAddress,
      saveAddress,
      createEmptyAddress,
      error,
      mustHandleError,
      searchAddresses,
      resetSearch,
      addressesFiltered
    }
  }
})
</script>
