<template>
  <v-card class="py-1 px-2 mb-1 d-flex">
    <v-row no-gutters align="center">
      <v-col cols="">
        <v-row no-gutters>
          <v-col cols="12" sm="3" md="2">
            <sf-heading ref="transaction-type">
              {{ $t('transactionType.' + orderDocument.transactionType) }}
            </sf-heading>
          </v-col>
          <v-col cols="12" sm="3" md="2" class="mt-1 my-sm-0 pr-sm-1">
            <sf-text ref="date">
              {{ formatDate(orderDocument.date) }}
            </sf-text>
          </v-col>
          <v-col v-if="!$vuetify.display.xs" cols="12" sm="3" md="2" lg="2" class="mt-1 my-sm-0">
            <sf-text v-if="orderDocument.documentNumber" ref="document-number" class="mr-2">
              {{ orderDocument.documentNumber }}
            </sf-text>
          </v-col>
          <v-col cols="12" sm="3" md="2" class="mt-1 my-sm-0">
            <sf-color-text ref="status" :color="getStatusColor(orderDocument.status)">
              {{ $t('orderStatus.' + orderDocument.status) }}
            </sf-color-text>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="auto">
        <v-btn
          ref="download-button"
          :loading="loading.downloadDocument && loading.documentRowId === orderDocument.id"
          variant="text"
          :icon="$vuetify.display.smAndDown"
          color="primary"
          :disabled="!orderDocument.hasPdfDocument"
          @click="downloadAttachment"
        >
          <v-icon :icon="mdiDownload" />
          <span class="d-none d-md-block">{{ $t('PDF') }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import SfColorText from '@/components/text/SfColorText.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { formatDistributors, getStatusColor } from '@/modules/order/helpers'
import { OrderDocument, TransactionType } from '@/modules/order/types'
import useOrderDocuments from '@/modules/order/useOrderDocuments'
import OrderEvent from '@/modules/tracking/events/order'
import { trackEvent } from '@/modules/tracking/useTracking'
import { formatDate } from '@/utils/timeFormatHelpers'
import { mdiDownload } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'OrderDocumentRow',
  components: { SfHeading, SfText, SfColorText },
  props: {
    orderDocument: {
      type: Object as PropType<OrderDocument>,
      required: true
    },
    orderId: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { loading } = storeToRefs(useOrderDocuments())
    const downloadAttachment = () => {
      useOrderDocuments().loadPDFDocument(props.orderId, props.orderDocument.id)

      let trackingEvent
      switch (props.orderDocument.transactionType) {
        case TransactionType.INVOICE:
          trackingEvent = OrderEvent.Download.Invoice
          break
        case TransactionType.DELIVERY:
          trackingEvent = OrderEvent.Download.Delivery
          break
        case TransactionType.ORDERSTATUS:
          trackingEvent = OrderEvent.Download.Confirmation
          break
        case TransactionType.ORDER:
          trackingEvent = OrderEvent.Download.Order
          break
      }

      if (trackingEvent) {
        trackEvent(trackingEvent)
      }
    }

    return {
      downloadAttachment,
      formatDate,
      formatDistributors,
      getStatusColor,
      loading,
      mdiDownload
    }
  }
})
</script>
