import { del, get, patch, post, Request, Response } from '@/api'
import { EmailPreview, PageableResponse } from '@/types'
import { FilterIdentifier } from '@/components/filter/types'
import {
  CollectionItemPostDTO,
  CollectionName,
  DetailedShopCollection,
  ShopCollection,
  CollectionsSortOrder,
  CollectionItemPatch,
  CollectionPatch,
  SendCollectionPostDTO,
  CollectionId
} from '@/modules/collection/types'
import { AddToCartSuccess } from '@/modules/order/types'

export default {
  getCollections: (
    page: number,
    size: number,
    sort: CollectionsSortOrder,
    filter?: string,
    search?: string
  ): Promise<Response<PageableResponse<ShopCollection>>> => {
    const config: Request = {
      params: {
        search,
        page: page - 1,
        size,
        sort,
        filter
      }
    }
    return get<PageableResponse<ShopCollection>>('/collections', config)
  },
  getCollectionsFilters: (
    filter?: string,
    search?: string
  ): Promise<Response<FilterIdentifier[]>> => {
    const config: Request = {
      params: {
        search,
        filter
      }
    }

    return get<FilterIdentifier[]>('/collections/filters', config)
  },
  getCollection: (collectionId: string): Promise<Response<DetailedShopCollection>> => {
    return get<DetailedShopCollection>('/collections/' + collectionId)
  },
  createCollection: (collectionName: CollectionName): Promise<Response<string>> => {
    return post<string, CollectionName>('/collections', collectionName)
  },
  addToCollection: (
    collectionId: string,
    collectionItemPostDTO: CollectionItemPostDTO
  ): Promise<Response<string>> => {
    return post<string, CollectionItemPostDTO>(
      '/collections/' + collectionId,
      collectionItemPostDTO
    )
  },
  updateCollection: (
    collectionId: string,
    collectionPatch: CollectionPatch
  ): Promise<Response<string>> => {
    return patch<string, CollectionPatch>('/collections/' + collectionId, collectionPatch)
  },
  updateCollectionItem: (
    collectionId: string,
    productId: number,
    collectionItemPatch: CollectionItemPatch
  ): Promise<Response<string>> => {
    return patch<string, CollectionItemPatch>(
      '/collections/' + collectionId + '/items/' + productId,
      collectionItemPatch
    )
  },
  deleteCollectionItem: (collectionId: string, productId: number): Promise<Response<string>> => {
    return del<string>('/collections/' + collectionId + '/items/' + productId)
  },
  deleteCollection: (collectionId: string): Promise<Response<string>> => {
    return del<string>('/collections/' + collectionId)
  },
  getEmailPreview: (id: string): Promise<Response<EmailPreview>> => {
    return get<EmailPreview>('/emails/send-collection/' + id)
  },
  sendEmail: (id: string, dto: SendCollectionPostDTO): Promise<Response<string>> => {
    return post<string, SendCollectionPostDTO>('/emails/send-collection/' + id, dto)
  },
  addCollectionToCart: (id: string): Promise<Response<AddToCartSuccess>> => {
    return post<AddToCartSuccess, CollectionId>('/collections/actions/to-cart', {
      id: id
    })
  },
  createQuoteRequest: (id: string): Promise<Response<string>> => {
    return post<string, CollectionId>('/collections/actions/request-quote', {
      id: id
    })
  },
  archiveCollection: (id: string): Promise<Response<string>> => {
    return post<string, CollectionId>('/collections/actions/archive', {
      id: id
    })
  },
  dearchiveCollection: (id: string): Promise<Response<string>> => {
    return post<string, CollectionId>('/collections/actions/dearchive', {
      id: id
    })
  },
  duplicateCollection: (id: string): Promise<Response<string>> => {
    return post<string, CollectionId>('/collections/actions/duplicate', {
      id: id
    })
  }
}
