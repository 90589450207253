import api from '@/modules/product/api'
import { ProductSearchOptions, ProductSort } from '@/modules/product/types'
import { handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import {
  FilterIdentifier,
  ProductFilterSelection,
  FilterValue,
  PriceRangeFilter,
  PRICE_RANGE_FILTER_PREFIX,
  STOCK_COUNT_FILTER_PREFIX
} from '@/components/filter/types'
import {
  getSelectionForFilter,
  removeItemFromFilterSelection,
  updateFilterSelection,
  addFilterSelection,
  getPriceRangeFilterTerm,
  decodePriceRangeFilterTerm,
  emptyFilterSelection,
  getStockCountFilterTerm,
  decodeStockCountFilterTerm
} from '@/components/filter/helpers'
import i18n from '@/plugins/i18n'

export interface ProductFilterState {
  filterNodes: FilterIdentifier[]
  attributeNodes: FilterIdentifier[]
  category: string
  selection: ProductFilterSelection
  loading: boolean
}

const initialState = (): ProductFilterState => {
  return {
    filterNodes: [],
    attributeNodes: [],
    category: '',
    selection: {
      sort: ProductSort.POPULARITY,
      filters: [],
      priceRangeFilter: {
        start: undefined,
        end: undefined
      },
      stockCountFilter: undefined
    },
    loading: false
  }
}

const useProductFilter = defineStore('productFilter', {
  state: () => initialState(),
  getters: {
    nonEmptyFilterNodes: (state) => {
      return state.filterNodes.filter((node) => node.values.length > 0)
    },
    getActiveFilters: (state) => {
      return state.selection.filters.filter((filter) => filter.values.length > 0)
    },
    getPriceRangeFilter: (state) => {
      return state.selection.priceRangeFilter
    },
    getStockCountFilter: (state) => {
      return state.selection.stockCountFilter
    },
    getActiveFilterLength: (state) => {
      return (
        state.selection.filters.filter((filter) => filter.values.length > 0).length +
        (state.selection.priceRangeFilter?.start || state.selection.priceRangeFilter?.end ? 1 : 0) +
        (state.selection.stockCountFilter ? 1 : 0)
      )
    }
  },
  actions: {
    resetFilter(sort: ProductSort) {
      this.selection = {
        sort: sort,
        filters: [],
        priceRangeFilter: {
          start: undefined,
          end: undefined
        },
        stockCountFilter: undefined
      }
      this.category = ''
    },
    isProductTypeSameAsCategory() {
      return (
        this.getSelectionForFilter(i18n.global.t('filter.productType').toString())?.find(
          (filterValue) => filterValue.term == this.category
        ) != undefined
      )
    },
    getFilterTerm(searchTerm?: string) {
      const filterTerm: string[] = searchTerm ? [searchTerm] : []
      const filterValues = this.selection.filters.flatMap((filter) => filter.values)
      filterValues.forEach((value) => filterTerm.push(value.term))
      if (this.selection.priceRangeFilter) {
        filterTerm.push(getPriceRangeFilterTerm(this.selection.priceRangeFilter))
      }
      if (this.selection.stockCountFilter) {
        filterTerm.push(getStockCountFilterTerm(this.selection.stockCountFilter))
      }

      // return array with unique values
      return [...new Set(filterTerm.filter((term) => term))]
    },
    getSelectionForFilter(name: string) {
      return getSelectionForFilter(this.selection.filters, name)
    },
    updateFilterSelection(name: string, items: FilterValue[] | undefined) {
      updateFilterSelection(this.selection.filters, name, items)
    },
    addFilterSelection(filterIdentifier: FilterIdentifier) {
      addFilterSelection(this.selection.filters, filterIdentifier, this.category)
    },
    removeItemFromFilterSelection(name: string, item: FilterValue) {
      removeItemFromFilterSelection(this.selection.filters, name, item)
    },
    updatePriceRangeFilter(filter: PriceRangeFilter) {
      this.selection.priceRangeFilter = filter
    },
    updateStockCountFilter(value: number | undefined) {
      this.selection.stockCountFilter = value
    },
    async getFilters(options: ProductSearchOptions) {
      this.loading = true

      return api
        .getFilters(options)
        .then(({ data }) => {
          this.filterNodes = data.filterIdentifiers
          this.attributeNodes = data.attributeIdentifiers
        })
        .then(() => this.applyFilters(options.filters ?? []))
        .catch(handleApiError)
        .finally(() => (this.loading = false))
    },
    applyFilters(filters: string[]) {
      const applyFilterNode = (filterNode: FilterIdentifier) => {
        const filterNodeValues = filterNode.values.filter((value) => filters.includes(value.term))
        if (filterNodeValues.length > 0) {
          updateFilterSelection(this.selection.filters, filterNode.name, filterNodeValues)
        } else {
          emptyFilterSelection(this.selection.filters, filterNode.name)
        }
      }

      this.filterNodes.forEach(applyFilterNode)
      this.attributeNodes.forEach(applyFilterNode)

      // price range filter
      const priceRangeFilterTerm = filters.find((value) =>
        value.startsWith(PRICE_RANGE_FILTER_PREFIX)
      )
      if (priceRangeFilterTerm) {
        this.selection.priceRangeFilter = decodePriceRangeFilterTerm(priceRangeFilterTerm)
      } else {
        this.selection.priceRangeFilter = { start: undefined, end: undefined }
      }

      // stock count filter
      const stockCountFilterTerm = filters.find((value) =>
        value.startsWith(STOCK_COUNT_FILTER_PREFIX)
      )
      if (stockCountFilterTerm) {
        this.selection.stockCountFilter = decodeStockCountFilterTerm(stockCountFilterTerm)
      } else {
        this.selection.stockCountFilter = undefined
      }

      // product type filter (category mode)
      const productTypeFilter = this.filterNodes.filter(
        (filterIdentifier) =>
          filterIdentifier.name == i18n.global.t('filter.productType').toString()
      )
      if (productTypeFilter.length > 0) {
        this.addFilterSelection(productTypeFilter[0])
      }
    }
  }
})

export default useProductFilter
