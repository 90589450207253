<template>
  <div>
    <div class="d-flex align-baseline ma-0 pa-0">
      <sf-sub-heading class="mt-2 mb-1 mr-1 ml-2">
        {{ name }}
      </sf-sub-heading>

      <sf-color-text
        v-if="products.length > showProductLimit"
        class="cursor-pointer"
        color="primary"
        @click="showLess = !showLess"
      >
        {{ showLess ? $t('more') : $t('less') }}
      </sf-color-text>
    </div>

    <div
      v-for="product in products.slice(0, showLess ? showProductLimit : products.length)"
      :key="product.productId"
      class="px-2 py-0"
    >
      <product-row
        v-if="$vuetify.display.mdAndUp"
        :product="product"
        :show-add-to-cart="true"
        :to="getProductLink(product.productId)"
      />
      <div v-else class="mb-1">
        <product-card :product="product" show-product-identifiers small />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { MediumProduct } from '@/modules/product/types'
import { defineComponent, PropType, ref } from 'vue'
import ProductRow from '@/modules/product/components/ProductRow.vue'
import router from '@/router'
import SfColorText from '@/components/text/SfColorText.vue'
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import ProductCard from '@/modules/product/components/ProductCard.vue'

export default defineComponent({
  name: 'ProductAccessoriesCategory',
  components: { ProductCard, SfSubHeading, SfColorText, ProductRow },
  props: {
    name: {
      type: String,
      required: true
    },
    products: {
      type: Array as PropType<MediumProduct[]>,
      required: true
    }
  },
  setup() {
    const showLess = ref(true)
    const showProductLimit = ref(2)

    const getProductLink = (productId: number) => {
      return router.resolve({ name: 'Product', params: { id: productId } }).href
    }

    return {
      showLess,
      showProductLimit,
      getProductLink
    }
  }
})
</script>
