import { defineStore } from 'pinia'
import { Product, ProductType } from '@/modules/product/types'
import api from '@/modules/product/api'
import helpers from '@/modules/message/helpers'
import { useStorage, RemovableRef } from '@vueuse/core'
import useFeatureAd from '@/modules/featureAd/useFeatureAd'

interface ProductComparisonState {
  products: RemovableRef<Product[]>
  groups: RemovableRef<ComparisonGroup[]>
  productIdsToLoad: RemovableRef<number[]>
}

export type ComparisonGroup = {
  name: string
  categories: string[]
}

const initialState = (): ProductComparisonState => {
  return {
    products: useStorage('comparisonProducts', []),
    groups: useStorage('comparisonGroups', []),
    productIdsToLoad: useStorage('comparisonProductIds', [])
  }
}

export const useProductComparison = defineStore('productComparison', {
  state: () => initialState(),
  getters: {
    inComparison: (state) => (productId: number) => state.productIdsToLoad.includes(productId),
    count: (state) => state.productIdsToLoad.length,
    currentCategoryID: (state) => {
      if (state.products.length > 0) {
        const firstProductType = state.products[0].productType.id
        if (state.products.every((elem) => elem.productType.id == firstProductType)) {
          return firstProductType
        }
        return 'INVALID'
      }
      return undefined
    }
  },
  actions: {
    async loadProducts() {
      if (this.productIdsToLoad.length == 0) {
        return
      }
      return api.loadComparableProducts(this.productIdsToLoad).then(({ data }) => {
        this.products = data

        const comparisonGroups: ComparisonGroup[] = []
        for (const product of this.products) {
          product.technicalDetails.forEach((technicalDetailToAdd) => {
            if (
              !comparisonGroups.find(
                (existingGroup) => technicalDetailToAdd.group == existingGroup.name
              )
            ) {
              comparisonGroups.push({
                name: technicalDetailToAdd.group,
                categories: [technicalDetailToAdd.name]
              })
            } else {
              const indexOfExistingGroup = comparisonGroups.findIndex(
                (element) => element.name == technicalDetailToAdd.group
              )
              if (
                !comparisonGroups[indexOfExistingGroup].categories.find(
                  (el) => el == technicalDetailToAdd.name
                )
              ) {
                comparisonGroups[indexOfExistingGroup].categories.push(technicalDetailToAdd.name)
              }
            }
          })
        }

        this.groups = comparisonGroups
      })
    },
    addProduct(productId: number, productTyoe: ProductType) {
      if (this.productIdsToLoad.length == 3) {
        helpers.reportError('error.productComparisonMaxNumber')
        return
      }

      if (this.productIdsToLoad.find((elem) => elem == productId)) {
        helpers.reportError('error.productAlreadyInComparison')
        return
      }
      if (this.count > 0 && this.currentCategoryID !== productTyoe.id) {
        helpers.reportError('error.productTypeIncorrectForComparison')
        return
      }

      useFeatureAd().hideFeatureAd()

      this.productIdsToLoad.push(productId)
      this.loadProducts()
      helpers.reportSuccess('success.addedToComparison')
    },
    removeProduct(productId: number) {
      this.productIdsToLoad = this.productIdsToLoad.filter((prod) => prod != productId)

      this.products = this.products.filter((prod) => prod.productId != productId)
      helpers.reportSuccess('success.removedFromComparison')
    },
    reset() {
      this.productIdsToLoad = []
      this.groups = []
      this.products = []
    }
  }
})

export default useProductComparison
