import { ApiError, handleApiError } from '@/handleApiError'
import api from '@/modules/authentication/api'
import { ChangePasswordDTO, PasswordResetDTO, Token } from '@/modules/authentication/types'
import helpers from '@/modules/message/helpers'
import router from '@/router'
import { defineStore } from 'pinia'

interface PasswordReset {
  tokenError: boolean
  sending: boolean
  loading: boolean
}

const initialState = (): PasswordReset => {
  return {
    tokenError: false,
    sending: false,
    loading: false
  }
}

const usePasswordReset = defineStore('resetPassword', {
  state: () => initialState(),
  actions: {
    async checkPasswordToken(dto: Token) {
      this.loading = true

      return api
        .checkPasswordToken(dto)
        .then(() => {
          this.tokenError = false
        })
        .catch((error: ApiError) => {
          if (error.code === 400) {
            // token not valid
            this.tokenError = true
          } else {
            // other error
            handleApiError(error)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async resetPassword(dto: PasswordResetDTO) {
      return api
        .resetPassword(dto)
        .then(({ data }) => {
          helpers.reportSuccess('success.resetPassword', [data])
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            customMessageKey: 'error.resetPassword'
          })
        })
    },
    async changePassword(dto: ChangePasswordDTO) {
      return api
        .changePassword(dto)
        .then(() => {
          this.tokenError = false
          helpers.reportSuccess('success.changePassword')
          router.replace('/login')
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            customMessageKey: 'error.changePassword'
          })
        })
    }
  }
})

export default usePasswordReset
