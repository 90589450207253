<template>
  <v-dialog v-model="dialog" :width="500">
    <template #activator="{ props }">
      <sf-text-button v-bind="props" small @click="trackClick">{{
        $t('serialNumbers.serialNumbers')
      }}</sf-text-button>
    </template>

    <v-card>
      <v-card-item>
        <v-card-title>
          <sf-heading>{{ $t('serialNumbers.serialNumbers') }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text class="pt-1 d-flex flex-column">
        <sf-text style="white-space: pre-line" v-html="serialsText" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <sf-text-button :disabled="!isSupported" @click="copyToClipboard">{{
          $t('serialNumbers.copyAll')
        }}</sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import helpers from '@/modules/message/helpers'
import { SerialNumber } from '@/modules/order/types'
import OrderEvent from '@/modules/tracking/events/order'
import { trackEvent } from '@/modules/tracking/useTracking'
import { useClipboard } from '@vueuse/core'
import { computed, defineComponent, PropType, ref } from 'vue'

export default defineComponent({
  name: 'OrderItemSerialsDialog',
  components: { SfTextButton, SfText, SfHeading },
  props: {
    serials: {
      type: Array as PropType<SerialNumber[]>,
      required: true
    }
  },
  setup(props) {
    const dialog = ref(false)
    const { copy, isSupported } = useClipboard()

    const serialsText = computed(() => {
      let text = ''
      props.serials.forEach((serial, index) => {
        if (index > 0) {
          text += '\n'
        }
        if (serial.type) {
          text += serial.type + ': '
        }
        text += serial.value
      })

      return text
    })

    const trackClick = () => {
      trackEvent(OrderEvent.OpenSerialNumbers)
    }

    const copyToClipboard = () => {
      trackEvent(OrderEvent.CopySerialNumbers)
      copy(serialsText.value).then(() => helpers.reportSuccess('serialNumbers.serialNumbersCopied'))
    }

    return {
      dialog,
      serialsText,
      isSupported,
      trackClick,
      copyToClipboard
    }
  }
})
</script>
