import { Event } from '@/modules/tracking/types'

export default class AddressEvent extends Event {
  static Created = new AddressEvent('Adresse erstellt')
  static Edited = new AddressEvent('Adresse bearbeitet')
  static Deleted = new AddressEvent('Adresse gelöscht')
  static Set = class {
    static DefaultBilling = new AddressEvent('Standard Rechnungsadresse gesetzt')
    static DefaultDelivery = new AddressEvent('Standard Lieferadresse gesetzt')
  }
  constructor(action: string, label?: string) {
    super('Adressen', action, label ?? '')
  }
}

export const addressEvents = (
  addressType: 'Rechnungsadresse' | 'Lieferadresse' | 'Angebotsadresse',
  Event: new (action: string, label?: string) => Event
): AddressChangeEvents => {
  return {
    Chosen: new Event(addressType + ' geändert', 'Andere ausgewählt'),
    Added: new Event(addressType + ' geändert', 'Neue erstellt'),
    Edited: new Event(addressType + ' geändert', 'Bestehende geändert'),
    Deleted: new Event(addressType + ' geändert', 'Bestehende gelöscht'),
    DefaultBillingChanged: new Event('Standard Rechnungsadresse gesetzt'),
    DefaultDeliveryChagned: new Event('Standard Lieferadresse gesetzt')
  }
}
export interface AddressChangeEvents {
  Chosen: Event
  Added: Event
  Edited: Event
  Deleted: Event
  DefaultBillingChanged: Event
  DefaultDeliveryChagned: Event
}
