<template>
  <v-dialog v-model="dialog" :width="500">
    <template #activator="{ props }">
      <sf-edit-button id="pw-edit-default-approvers" v-bind="props" />
    </template>
    <v-card>
      <v-card-item>
        <v-card-title>
          <sf-heading>{{ $t('orderLimits.defaultApprovers') }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text>
        <order-limit-approvers-select
          v-model="defaultOrderLimitPatch.approverIds"
          :approvers="approvers"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <sf-text-button color="grey" class="text-medium-emphasis" @click="closeDialog">
          {{ $t('cancel') }}
        </sf-text-button>
        <sf-text-button color="primary" @click="save">
          {{ $t('save') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import SfEditButton from '@/components/button/SfEditButton.vue'
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import { fitCropImage, getFullName } from '@/helpers'
import OrderLimitApproversSelect from '@/modules/orderLimits/components/OrderLimitApproversSelect.vue'
import useOrderLimits from '@/modules/orderLimits/useOrderLimits'
import MyCompanyEvent from '@/modules/tracking/events/MyCompany'
import { trackEvent } from '@/modules/tracking/useTracking'
import { ShortUser } from '@/modules/user/types'
import { defineComponent, PropType, ref, watch } from 'vue'

export default defineComponent({
  name: 'OrderLimitDialog',
  components: {
    OrderLimitApproversSelect,
    SfEditButton,
    SfTextButton,
    SfHeading
  },
  props: {
    defaultApprovers: {
      type: Array as PropType<ShortUser[]>,
      required: true
    },
    approvers: {
      type: Array as PropType<ShortUser[]>,
      required: true
    }
  },
  setup(props) {
    const dialog = ref(false)

    const data = () => {
      return {
        approverIds: props.defaultApprovers.map((approver) => approver.id)
      }
    }

    const defaultOrderLimitPatch = ref(data())

    // set correct data when dialog is reopened
    watch(dialog, (newValue) => {
      if (newValue === true) {
        defaultOrderLimitPatch.value = data()
      }
    })

    const remove = (item: ShortUser) => {
      const index = defaultOrderLimitPatch.value.approverIds.indexOf(item.id)
      if (index >= 0) defaultOrderLimitPatch.value.approverIds.splice(index, 1)
    }

    const save = () => {
      useOrderLimits().updateDefaultOrderLimit(defaultOrderLimitPatch.value)
      trackEvent(MyCompanyEvent.OrderLimit.Default.Assignees)
      dialog.value = false
    }

    const closeDialog = () => {
      dialog.value = false
    }

    return {
      dialog,
      fitCropImage,
      getFullName,
      defaultOrderLimitPatch,
      remove,
      save,
      closeDialog
    }
  }
})
</script>
