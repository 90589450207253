import { defineStore } from 'pinia'
import { handleApiError } from '@/handleApiError'
import { PunchoutForm } from '@/modules/punchout/types'
import api from '@/modules/punchout/api'

interface PunchoutState {
  punchoutForm: PunchoutForm
  loading: boolean
}

const initialState = (): PunchoutState => {
  return {
    punchoutForm: {
      payload: '',
      eprocurementUrl: '',
      target: '',
      items: []
    },
    loading: false
  }
}

const usePunchout = defineStore('punchout', {
  state: () => initialState(),
  actions: {
    async loadPunchoutCartForm(supplierCartId?: string) {
      this.loading = true
      return api
        .loadPunchoutCartForm(supplierCartId)
        .then(({ data }) => {
          this.punchoutForm = data
        })
        .catch(handleApiError)
        .finally(() => {
          this.loading = false
        })
    },
    async loadPunchoutQuoteForm(quoteId: string) {
      this.loading = true
      return api
        .loadPunchoutQuoteForm(quoteId)
        .then(({ data }) => {
          this.punchoutForm = data
        })
        .catch(handleApiError)
        .finally(() => {
          this.loading = false
        })
    }
  }
})

export default usePunchout
