<template>
  <v-list-group :value="searchTerm" title="">
    <template #activator="{ isOpen }">
      <v-list-item
        class="list-group-item"
        :value="searchTerm"
        :color="isSelected && colorSelected ? 'primary' : ''"
        :active="isSelected && colorSelected"
        :ripple="isSelectable"
        rounded
        @click="onClickedName($event, isOpen)"
      >
        <v-list-item-title class="text-wrap">
          {{ name }}
        </v-list-item-title>
        <template #prepend>
          <sf-icon-button density="compact" color="" @click="onClickedButton($event)">
            {{ isOpen ? mdiMenuDown : mdiMenuRight }}
          </sf-icon-button>
        </template>
        <template #append></template>
      </v-list-item>
    </template>
  </v-list-group>
</template>

<script lang="ts">
import SfIconButton from '@/components/button/SfIconButton.vue'
import useCategories from '@/modules/categories/useCategories'
import { storeToRefs } from 'pinia'
import { PropType, computed } from 'vue'
import { defineComponent } from 'vue'
import { mdiMenuDown, mdiMenuRight } from '@mdi/js'

export default defineComponent({
  name: 'CategoryGroup',
  components: { SfIconButton },
  props: {
    name: {
      type: String,
      required: true
    },
    searchTerm: {
      type: String,
      required: true
    },
    isSelectable: {
      type: Boolean,
      required: true
    },
    clickCallback: {
      type: Function as PropType<(event: MouseEvent | KeyboardEvent) => void>,
      required: true
    },
    colorSelected: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update'],
  setup: (props, { emit }) => {
    const { openCategories } = storeToRefs(useCategories())
    const selectCategory = (categorySearchTerm: string | undefined) => {
      if (categorySearchTerm) {
        emit('update', categorySearchTerm)
      }
    }

    const openCategoriesSeachTerm = computed(() =>
      openCategories.value?.map((category) => category.searchTerm)
    )

    const onClickedName = (event: MouseEvent | KeyboardEvent, isOpen: boolean) => {
      if (props.isSelectable) {
        selectCategory(props.searchTerm)
        if (!isOpen) {
          props.clickCallback(event)
        }
      } else {
        props.clickCallback(event)
      }
    }

    const onClickedButton = (event: MouseEvent | KeyboardEvent) => {
      event.stopImmediatePropagation()
      props.clickCallback(event)
    }

    const isSelected = computed(() => {
      return props.isSelectable && openCategoriesSeachTerm.value[0] === props.searchTerm
    })

    return {
      onClickedButton,
      onClickedName,
      isSelected,
      mdiMenuDown,
      mdiMenuRight
    }
  }
})
</script>
<style lang="scss" scoped>
.v-list-item.list-group-item {
  padding-inline-start: calc(var(--indent-padding) / 2) !important;
}
</style>
