import { ApiError, handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import api from '@/modules/collection/api'
import {
  CollectionsSortOrder,
  PagedCollections,
  ProductCollectionRelation,
  ShopCollection
} from '@/modules/collection/types'
import useCollection from '@/modules/collection/useCollection'
import helpers from '@/modules/message/helpers'

const PAGE_SIZE = 10

interface AddToCollectionState {
  excludingProduct: PagedCollections
  includingProduct: PagedCollections
  isInCollection: boolean
}

const initialState = (): AddToCollectionState => {
  return {
    excludingProduct: {
      collections: [],
      page: 0,
      pageCount: 0
    },
    includingProduct: {
      collections: [],
      page: 0,
      pageCount: 0
    },
    isInCollection: false
  }
}

const useAddToCollection = defineStore('addToCollection', {
  state: () => initialState(),
  actions: {
    async getFirstPage(
      searchTerm: string,
      productId: number,
      productState: ProductCollectionRelation
    ) {
      if (productState === 'including') {
        this.includingProduct.page = 1
      } else {
        this.excludingProduct.page = 1
      }

      return api
        .getCollections(
          1,
          PAGE_SIZE,
          CollectionsSortOrder.LAST_MODIFIED,
          `ARCNOTARCHIVED,${productState === 'including' ? 'PRI' : 'PRE'}${productId}`,
          searchTerm
        )
        .then(({ data }) => {
          if (productState === 'including') {
            this.includingProduct.collections = data.items
            this.includingProduct.pageCount = data.pageCount
          } else {
            this.excludingProduct.collections = data.items
            this.excludingProduct.pageCount = data.pageCount
          }
        })
        .catch(handleApiError)
    },
    async getNextPage(
      searchTerm: string,
      productId: number,
      productState: ProductCollectionRelation
    ) {
      if (
        (productState === 'including' &&
          this.includingProduct.page < this.includingProduct.pageCount) ||
        (productState === 'excluding' &&
          this.excludingProduct.page < this.excludingProduct.pageCount)
      ) {
        if (productState === 'including') {
          this.includingProduct.page++
        } else {
          this.excludingProduct.page++
        }
        return api
          .getCollections(
            productState === 'including' ? this.includingProduct.page : this.excludingProduct.page,
            PAGE_SIZE,
            CollectionsSortOrder.LAST_MODIFIED,
            `ARCNOTARCHIVED,${productState === 'including' ? 'PRI' : 'PRE'}${productId}`,
            searchTerm
          )
          .then(({ data }) => {
            if (productState === 'including') {
              this.includingProduct.collections = [
                ...this.includingProduct.collections,
                ...data.items
              ]
            } else {
              this.excludingProduct.collections = [
                ...this.excludingProduct.collections,
                ...data.items
              ]
            }
          })
          .catch(handleApiError)
      }
    },
    addToCollections(collections: ShopCollection[], productId: number, reload = false) {
      if (collections.length > 0) {
        this.isInCollection = true
        const promises: Promise<string>[] = []

        collections.forEach((collection) => {
          promises.push(
            api.addToCollection(collection.id, { productId }).then((data) => {
              helpers.reportSuccess(
                'success.addedToCollectionLink',
                [collection.name],
                `/collections/${collection.id}`
              )
              if (reload) {
                useCollection().getCollection(collection.id, true)
              }
              return Promise.resolve(data.data).catch((error: ApiError) => {
                handleApiError(error, {
                  appearance: 'NONE'
                })
                return Promise.reject(error)
              })
            })
          )
        })
        return Promise.allSettled(promises).finally(() => this.checkIsInCollection(productId))
      }
    },
    async addToCollection(collectionId: string, productId: number) {
      return api
        .addToCollection(collectionId, { productId })
        .then(() => {
          helpers.reportSuccess('success.addedToCollection')
          useCollection().getCollection(collectionId, true)
        })
        .catch((error) => {
          handleApiError(error, undefined, {
            errorCode: 409,
            logError: false,
            customMessageKey: 'collection.productAlreadyInCollection',
            showRefreshButton: false
          })
        })
    },
    async addToNewCollection(collectionName: string, productId: number) {
      this.isInCollection = true
      return api
        .createCollection({ name: collectionName })
        .then(({ data }) => {
          api
            .addToCollection(data, { productId })
            .then(() => {
              helpers.reportSuccess(
                'success.addedToCollectionLink',
                [collectionName],
                `/collections/${data}`
              )
            })
            .finally(() => this.checkIsInCollection(productId))
        })
        .catch(handleApiError)
    },
    async checkIsInCollection(productId: number) {
      return api
        .getCollections(
          1,
          1,
          CollectionsSortOrder.LAST_MODIFIED,
          `ARCNOTARCHIVED,${'PRI'}${productId}`
        )
        .then(({ data }) => {
          this.isInCollection = data.items.length > 0
        })
        .catch(handleApiError)
    }
  }
})

export default useAddToCollection
