<template>
  <div class="d-flex flex-row">
    <avatar-image :user="entry.user" :size="40" />
    <div class="d-flex flex-column ml-2">
      <sf-text>{{ entry.user.fullName }}:</sf-text>
      <sf-sub-heading v-html="entry.message" />
      <sf-secondary-text>{{ formatRelativeDate(entry.timestamp) }}</sf-secondary-text>
    </div>
  </div>
</template>

<script lang="ts">
import AvatarImage from '@/components/AvatarImage.vue'
import SfSecondaryText from '@/components/text/SfSecondaryText.vue'
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import useOwnUser from '@/modules/user/useOwnUser'
import { PropType, defineComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { QuoteActivity } from '@/modules/quote/types'
import { getFullName } from '@/helpers'
import { formatRelativeDate } from '@/utils/timeFormatHelpers'

export default defineComponent({
  name: 'QuoteActivityEntry',
  components: { AvatarImage, SfSubHeading, SfText, SfSecondaryText },
  props: {
    entry: {
      type: Object as PropType<QuoteActivity>,
      required: true
    }
  },
  emits: ['close', 'resetFilter'],
  setup() {
    const { ownUser } = storeToRefs(useOwnUser())

    return {
      ownUser,
      getFullName,
      formatRelativeDate
    }
  }
})
</script>
