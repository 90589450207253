import { handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import api from '@/modules/collection/api'
import { CollectionsSortOrder, ShopCollection } from '@/modules/collection/types'
import useCollectionsFilter from '@/modules/collection/useCollectionsFilter'
import router from '@/router'
import useCollection from '@/modules/collection/useCollection'
import helpers from '@/modules/message/helpers'

const PAGE_SIZE = 20

interface CollectionsState {
  collections: ShopCollection[]
  count: number
  pages: number
  loading: boolean
  creating: boolean
}

const initialState = (): CollectionsState => {
  return {
    collections: [],
    count: 0,
    pages: 0,
    loading: false,
    creating: false
  }
}

const REMOVE_COLLECTION = (state: CollectionsState, id: string) => {
  const index = state.collections.findIndex((item) => {
    return item.id === id
  })
  if (index >= 0) {
    state.collections.splice(index, 1)
  }
  useCollection().$reset()
}

const useCollections = defineStore('collections', {
  state: () => initialState(),
  actions: {
    async initCollections(page: number) {
      this.loading = true

      await useCollectionsFilter().applyDefaultFilters()
      this.getCollections(page)
    },
    async getCollections(page: number) {
      this.loading = true

      const sort = useCollectionsFilter().selection.sort
      const filter = useCollectionsFilter().getFilterTerm()
      useCollectionsFilter().getCollectionsFilters(filter)

      return api
        .getCollections(page, PAGE_SIZE, sort, filter)
        .then(({ data }) => {
          this.collections = data.items
          this.count = data.totalCount
          this.pages = data.pageCount
        })
        .catch(handleApiError)
        .finally(() => (this.loading = false))
    },
    async getCollectionPage(page: number) {
      this.loading = true

      return api
        .getCollections(page, PAGE_SIZE, CollectionsSortOrder.DATE_DESC)
        .then(({ data }) => {
          this.collections = this.collections.concat(data.items)
          this.count = data.totalCount
          this.pages = data.pageCount
        })
        .catch(handleApiError)
        .finally(() => (this.loading = false))
    },
    async createCollection(name: string) {
      this.creating = true

      return api
        .createCollection({ name })
        .then(({ data }) => {
          router.push({
            name: 'Collection',
            params: { id: data }
          })
        })
        .catch(handleApiError)
        .finally(() => (this.creating = false))
    },
    async removeCollection(id: string) {
      REMOVE_COLLECTION(this, id)

      router.push({
        name: 'Collections'
      })

      return api
        .deleteCollection(id)
        .then(() => {
          helpers.reportSuccess('collection.deletedMessage')
        })
        .catch(handleApiError)
    }
  }
})

export default useCollections
