<template>
  <v-card class="cartitem-row pa-2 mb-1">
    <v-row no-gutters>
      <v-col cols="12" md="">
        <v-row no-gutters>
          <v-col
            v-if="showDragHandle"
            ref="drag-handle"
            cols="auto"
            class="d-flex align-center justify-center mr-2"
            :class="
              loadingMoveInfo || !(cartItem.id in availableSupplierCarts)
                ? 'disable-move'
                : 'handle cursor-grab'
            "
          >
            <v-icon :icon="mdiDragVertical" />
          </v-col>
          <v-col cols="auto" class="d-flex align-center mr-2">
            <product-image :url="cartItem.productImageUrl" />
          </v-col>
          <v-col ref="names" cols="" class="d-flex flex-column justify-center mr-md-2 mb-2 mb-md-0">
            <sf-text v-if="!cartItem.productTitle">
              {{ $t('productNoLongerAvailable') }}
            </sf-text>
            <router-link
              v-else
              class="text-body-1 text-decoration-none text-primary"
              :to="{ name: 'Product', params: { id: cartItem.productId } }"
            >
              {{ cartItem.productTitle }}
            </router-link>
            <div class="d-flex align-center my-half">
              <sf-non-retail-chip
                v-if="cartItem.condition && cartItem.condition !== Condition.NEW"
                class="mr-1"
              />
              <product-identifiers
                :article-number="cartItem.articleNumber"
                :product-id="cartItem.productId"
              />
            </div>
            <subscription-period
              v-if="cartItem.price && cartItem.price.subscriptionModel"
              class="text-body-1 text-medium-emphasis"
              :subscription-model="cartItem.price.subscriptionModel"
            />
            <template
              v-if="
                $vuetify.display.mdAndUp && Object.keys(portalSettings.sellerComments).length <= 2
              "
            >
              <v-form ref="form" v-model="valid">
                <div class="d-flex flex-row align-center mt-2">
                  <cart-row-comment
                    style="max-width: 400px"
                    :comment="cartItem.supplierComment"
                    editable
                    :max-length="maxLength"
                    :comment-field="portalSettings.supplierComment.field1"
                    :is-seller-comment="false"
                    @comment-changed="updateSupplierComment"
                  />
                  <cart-row-comment
                    v-for="sellerComment in sellerCommentEntries"
                    :key="sellerComment[0]"
                    style="max-width: 400px"
                    class="ml-2"
                    :comment-field="sellerComment[1]"
                    :comment="cartItem.sellerComments[keyOfComments(sellerComment[0])]"
                    :field-index="keyOfComments(sellerComment[0])"
                    :max-length="DEFAULT_POSITION_COMMENT_LENGTH"
                    editable
                    @comment-changed="updateSellerComment"
                  />
                </div>
              </v-form>
            </template>
            <not-available-hint
              v-if="cartItem.stock && isProductUnavailable(cartItem.stock)"
              class="mt-1"
              :stock="cartItem.stock"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="auto" align-self="center">
        <v-row ref="numbers" no-gutters justify="end">
          <v-col cols="auto" class="d-flex flex-column justify-center align-center mr-md-2">
            <template v-if="showStepper">
              <quantity-stepper
                :quantity="cartItem.quantity"
                :stock="cartItem.stock ? cartItem.stock.count : 0"
                :packaging="cartItem.price ? cartItem.price.packagingModel : undefined"
                @change-quantity="changeQuantity"
              />
              <stock-status-label v-if="cartItem.stock" class="mt-1" :stock="cartItem.stock" />
            </template>

            <sf-text v-else class="ml-2 font-weight-bold"> {{ cartItem.quantity }}x </sf-text>
          </v-col>
          <v-col
            cols="auto"
            style="width: 120px"
            class="d-flex flex-column justify-center align-center align-md-end ml-3 ml-md-0"
          >
            <template v-if="cartItem.price">
              <sf-heading>
                {{ formatMoney(cartItem.price.resellPrice, true) }}
              </sf-heading>
              <billing-period
                v-if="cartItem.price.subscriptionModel"
                class="text-body-1 text-medium-emphasis"
                :subscription-model="cartItem.price.subscriptionModel"
              />
              <packaging-info
                v-if="cartItem.price.packagingModel"
                class="text-body-1 text-medium-emphasis"
                :packaging-model="cartItem.price.packagingModel"
              />
            </template>
          </v-col>

          <v-col
            v-if="showDeleteButton"
            cols="auto"
            class="d-flex justify-center align-center ml-md-2"
          >
            <sf-delete-button :loading="loading" @click="removeProduct(cartItem.id)" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <template
      v-if="$vuetify.display.smAndDown || Object.keys(portalSettings.sellerComments).length > 2"
    >
      <v-form ref="form" v-model="valid">
        <v-divider class="my-1" />
        <v-row no-gutters>
          <v-col>
            <v-row>
              <v-col>
                <div
                  class="d-flex align-center cursor-pointer"
                  @click="showComments = !showComments"
                >
                  <v-icon size="large">
                    {{ showComments ? mdiMenuUp : mdiMenuDown }}
                  </v-icon>
                  <sf-text v-if="!validateCommentsImmediately || valid">{{
                    $t('common.comments') + (requiredComment ? '*' : '')
                  }}</sf-text>
                  <sf-color-text v-else color="red">{{
                    $t('common.comments') + (requiredComment ? '*' : '')
                  }}</sf-color-text>
                </div>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-row v-show="showComments" dense>
                <v-col cols="12" sm="6" md="4">
                  <cart-row-comment
                    :comment-field="portalSettings.supplierComment.field1"
                    :max-length="maxLength"
                    editable
                    :is-seller-comment="false"
                    :comment="cartItem.supplierComment"
                    @comment-changed="updateSupplierComment"
                  />
                </v-col>
                <v-col
                  v-for="sellerComment in sellerCommentEntries"
                  :key="sellerComment[0]"
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <cart-row-comment
                    :comment-field="sellerComment[1]"
                    :field-index="keyOfComments(sellerComment[0])"
                    :comment="cartItem.sellerComments[keyOfComments(sellerComment[0])]"
                    :max-length="DEFAULT_POSITION_COMMENT_LENGTH"
                    editable
                    @comment-changed="updateSellerComment"
                  />
                </v-col>
              </v-row>
            </v-expand-transition>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </v-card>
</template>

<script lang="ts">
import BillingPeriod from '@/components/BillingPeriod.vue'
import PackagingInfo from '@/components/PackagingInfo.vue'
import ProductImage from '@/components/ProductImage.vue'
import QuantityStepper from '@/components/QuantityStepper.vue'
import StockStatusLabel from '@/components/StockStatusLabel.vue'
import SubscriptionPeriod from '@/components/SubscriptionPeriod.vue'
import SfDeleteButton from '@/components/button/SfDeleteButton.vue'
import SfNonRetailChip from '@/components/chips/SfNonRetailChip.vue'
import SfColorText from '@/components/text/SfColorText.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import CartRowComment from '@/modules/cart/components/CartRowComment.vue'
import NotAvailableHint from '@/modules/cart/components/NotAvailableHint.vue'
import { isProductUnavailable } from '@/modules/cart/helpers'
import { CartItem, DEFAULT_POSITION_COMMENT_LENGTH } from '@/modules/cart/types'
import useCartItemMove from '@/modules/cart/useCartItemMove'
import useCheckout from '@/modules/order/useCheckout'
import { CommentField, SellerComments } from '@/modules/portalSettings/types'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import ProductIdentifiers from '@/modules/product/components/ProductIdentifiers.vue'
import { formatMoney, getStatusColor } from '@/modules/product/helpers'
import { Condition, StockStatus } from '@/modules/product/types'
import { VForm } from '@/types'
import { mdiDragVertical, mdiMenuDown, mdiMenuUp } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { PropType, computed, defineComponent, onMounted, ref, watch } from 'vue'

export default defineComponent({
  name: 'CartRow',
  components: {
    BillingPeriod,
    CartRowComment,
    NotAvailableHint,
    PackagingInfo,
    ProductIdentifiers,
    ProductImage,
    QuantityStepper,
    SfColorText,
    SfDeleteButton,
    SfHeading,
    SfNonRetailChip,
    SfText,
    StockStatusLabel,
    SubscriptionPeriod
  },
  props: {
    cartItem: {
      type: Object as PropType<CartItem>,
      required: true
    },
    maxLength: {
      type: Number,
      required: true
    },
    showDeleteButton: {
      type: Boolean,
      default: false
    },
    showStepper: {
      type: Boolean,
      default: true
    },
    showDragHandle: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    validateCommentsImmediately: {
      type: Boolean,
      default: false
    }
  },
  emits: ['changeQuantity', 'remove', 'updateSupplierComment', 'updateSellerComment'],
  setup(props, { emit }) {
    const showComments = ref(false)
    const valid = ref(false)
    const form = ref<VForm>()
    if (props.validateCommentsImmediately) {
      onMounted(() => form.value?.validate())
    }

    const { portalSettings } = storeToRefs(usePortalSettings())
    const { availableSupplierCarts, loading: loadingMoveInfo } = storeToRefs(useCartItemMove())

    const updateSupplierComment = (comment: string) => {
      emit('updateSupplierComment', { id: props.cartItem.id, comment })
    }

    const updateSellerComment = (comment: string, fieldIndex: keyof SellerComments) => {
      emit('updateSellerComment', { id: props.cartItem.id, comment, fieldIndex })
    }

    const removeProduct = (id: string) => {
      emit('remove', id)
    }

    const changeQuantity = (quantity: number) => {
      emit('changeQuantity', {
        id: props.cartItem.id,
        quantity: quantity
      })
    }

    const requiredComment = computed(() => {
      if (portalSettings.value.supplierComment.field1.required) {
        return true
      } else {
        return Object.values(portalSettings.value.sellerComments).some(
          (sellerComment) => sellerComment.required
        )
      }
    })

    const sellerCommentEntries = computed(() => {
      return Object.entries<CommentField>(portalSettings.value.sellerComments)
    })
    const keyOfComments = (key: string) => key as keyof SellerComments

    watch(valid, (newValue) => {
      useCheckout().addCommentValid(props.cartItem.id, newValue)
    })

    return {
      availableSupplierCarts,
      changeQuantity,
      Condition,
      DEFAULT_POSITION_COMMENT_LENGTH,
      form,
      formatMoney,
      getStatusColor,
      isProductUnavailable,
      keyOfComments,
      loadingMoveInfo,
      portalSettings,
      removeProduct,
      requiredComment,
      sellerCommentEntries,
      showComments,
      StockStatus,
      updateSellerComment,
      updateSupplierComment,
      valid,
      mdiDragVertical,
      mdiMenuUp,
      mdiMenuDown
    }
  }
})
</script>
