<template>
  <div v-if="branding">
    <sf-alert-success v-if="quoteStatus === QuoteStatus.ACCEPTED" class="mx-auto" max-width="500px">
      {{ $t('quoteStatus.' + quoteStatus) }}
    </sf-alert-success>
    <login-wrapper
      :branding="branding"
      :title="branding.isQuotesPortal ? $t('loginQuotesPortal') : $t('loginPortal')"
      :loading="authState.loading"
    >
      <login-form
        class="mt-2"
        :branding="branding"
        :login-error="authState.loginError"
        @login="login"
        @reset="resetPassword"
      />
    </login-wrapper>
  </div>
</template>

<script lang="ts">
import SfAlertSuccess from '@/components/alerts/SfAlertSuccess.vue'
import LoginForm from '@/modules/authentication/components/LoginForm.vue'
import LoginWrapper from '@/modules/authentication/components/LoginWrapper.vue'
import { LoginError } from '@/modules/authentication/types'
import useAuthentication from '@/modules/authentication/useAuthentication'
import usePasswordReset from '@/modules/authentication/usePasswordReset'
import useBranding from '@/modules/portalSettings/useBranding'
import { QuoteStatus } from '@/modules/quote/types'
import { useStateManager } from '@/store'
import { useTitle } from '@/helpers'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'LoginPage',
  components: { LoginForm, LoginWrapper, SfAlertSuccess },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('windowTitle.Login')}`))
    useStateManager().reset(false)
    const currentRoute = useRoute()
    const authError = currentRoute.query['auth_error']
    const quoteStatus = currentRoute.params.quoteStatus
    if (authError === '0') {
      useAuthentication().setLoginError(LoginError.UNKNOWN_ERROR)
    } else if (authError === '1') {
      useAuthentication().setLoginError(LoginError.USER_DEACTIVATED)
    }

    const authState = useAuthentication().state
    const login = useAuthentication().login
    const { branding } = useBranding()

    const resetPassword = (mail: string) => {
      usePasswordReset().resetPassword({
        mail: mail
      })
    }

    return {
      branding,
      authState,
      login,
      resetPassword,
      QuoteStatus,
      quoteStatus
    }
  }
})
</script>
