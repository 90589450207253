import { ApiError, handleApiError } from '@/handleApiError'
import * as Sentry from '@sentry/vue'
import { defineStore } from 'pinia'
import api from '@/modules/user/api'
import { ContactTitle, GuestUser } from '@/modules/user/types'

interface GuestUserState {
  guestUser: GuestUser
  loading: boolean
}

const initialState = (): GuestUserState => {
  return {
    guestUser: {
      title: ContactTitle.Unspecified,
      firstName: '',
      lastName: '',
      email: '',
      isRegistred: false
    },
    loading: false
  }
}

const useGuestUser = defineStore('guestUser', {
  state: () => initialState(),
  actions: {
    async initGuestUserSession() {
      return api
        .getGuestUser()
        .then(({ data }) => {
          this.guestUser = data
          Sentry.getCurrentScope().setUser({
            email: data.email
          })
        })
        .catch((error: ApiError) => {
          handleApiError(error, undefined, {
            errorCode: 404,
            logError: false,
            appearance: 'NONE'
          })
        })
    }
  }
})

export default useGuestUser
