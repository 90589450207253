<template>
  <div class="d-flex flex-row my-1">
    <div v-for="assignee in assignees" :key="assignee.id" class="d-flex align-center mr-2">
      <avatar-image class="mr-1" :size="40" :user="assignee" />
      <sf-text>{{ getFullName(assignee) }}</sf-text>
    </div>
  </div>
</template>

<script lang="ts">
import AvatarImage from '@/components/AvatarImage.vue'
import SfText from '@/components/text/SfText.vue'
import { getFullName } from '@/helpers'
import { ShortUser } from '@/modules/user/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'ApprovalRequestInfoAssignees',
  components: {
    SfText,
    AvatarImage
  },
  props: {
    assignees: {
      type: Array as PropType<ShortUser[]>,
      required: true
    }
  },
  setup() {
    return {
      getFullName
    }
  }
})
</script>
