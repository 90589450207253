<template>
  <div>
    <info-bar v-if="isRedslave || isReadonly" />

    <v-app-bar color="white">
      <v-row justify="center" no-gutters>
        <v-col cols="12" xxl="10" class="pa-xxl-0 px-1">
          <v-row no-gutters>
            <v-col cols="auto" class="d-flex justify-start align-center">
              <router-link
                v-if="branding"
                :to="{ name: 'StartPage' }"
                class="text-decoration-none"
                @click="trackNavigationHome"
              >
                <branding-logo :branding="branding" />
              </router-link>
            </v-col>
            <v-spacer />
            <v-col cols="auto" class="d-flex flex-row align-center justify-end">
              <toolbar-user
                :branding="branding"
                :is-error="error.code == 403 ? true : false"
                :user-id="userId"
                :has-logout="!isGuest"
                :full-name="userFullName"
                :avatar-url="userAvatarUrl"
                :menu="isReadonly ? [] : menu"
                @logout="logout"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-row justify="center" no-gutters>
          <v-col cols="12" xxl="10">
            <error-component
              v-if="error.code && (!error.customHandler || mustHandleError)"
              :message="error.message"
              :code="error.code"
            />
            <router-view v-else />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script lang="ts">
import BrandingLogo from '@/components/BrandingLogo.vue'
import InfoBar from '@/components/info-bar/InfoBar.vue'
import { guestToolbarUserMenu, toolbarUserMenu } from '@/components/navigation/menu'
import ToolbarUser from '@/components/toolbar/ToolbarUser.vue'
import { getFullName, isGuest } from '@/helpers'
import useAuthentication from '@/modules/authentication/useAuthentication'
import ErrorComponent from '@/modules/error/components/ErrorComponent.vue'
import { customErrorHandling } from '@/modules/error/helpers'
import useBranding from '@/modules/portalSettings/useBranding'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'
import ApplicationHeaderEvent from '@/modules/tracking/events/applicationHeader'
import { trackEvent } from '@/modules/tracking/useTracking'
import { GuestUser, User } from '@/modules/user/types'
import useGuestUser from '@/modules/user/useGuestUser'
import useOwnUser from '@/modules/user/useOwnUser'
import vuetify from '@/plugins/vuetify'
import { storeToRefs } from 'pinia'
import { Ref, computed, defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'QuotesPortalLayout',
  components: {
    BrandingLogo,
    ErrorComponent,
    InfoBar,
    ToolbarUser
  },
  setup() {
    if (!isGuest.value) {
      usePortalSettings().getPortalSettings()
    }

    const drawer = ref(!vuetify.display.mobile.value)
    const currentRoute = useRoute()
    const entries = ref(false)
    const { branding } = useBranding()
    let user: Ref<User | GuestUser>

    if (isGuest.value) {
      useGuestUser().initGuestUserSession()
      const { guestUser } = storeToRefs(useGuestUser())
      user = guestUser
    } else {
      useOwnUser().initUserSession()
      const { ownUser } = storeToRefs(useOwnUser())
      user = ownUser
    }

    const userId = computed(() => (user.value as User).id || undefined)
    const userFullName = computed(() => getFullName(user.value))
    const menu = computed(() =>
      isGuest.value ? guestToolbarUserMenu((user.value as GuestUser).isRegistred) : toolbarUserMenu
    )
    const userAvatarUrl = computed(() =>
      isGuest.value ? undefined : (user.value as User).avatarUrl
    )
    const isRedslave = computed(() => useAuthentication().state.value.isRedslave)
    const isReadonly = computed(() => useAuthentication().state.value.isReadonly)
    const { error, mustHandleError } = customErrorHandling()

    const logout = () => {
      useAuthentication().logout()
    }

    const trackNavigationHome = () => {
      trackEvent(ApplicationHeaderEvent.Logo)
    }

    return {
      branding,
      currentRoute,
      drawer,
      entries,
      error,
      getFullName,
      userFullName,
      isRedslave,
      isReadonly,
      isGuest,
      menu,
      logout,
      userId,
      userAvatarUrl,
      trackNavigationHome,
      mustHandleError
    }
  }
})
</script>
