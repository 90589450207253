import { ApiError, handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import api from '@/modules/promotionalProducts/api'
import { PromotionalProduct } from '@/modules/promotionalProducts/types'

interface PromotionalProductsState {
  promotionalProducts: PromotionalProduct[]
  loading: boolean
}

const initialState = (): PromotionalProductsState => {
  return {
    promotionalProducts: [],
    loading: false
  }
}

const usePromotionalProducts = defineStore('promotionalProducts', {
  state: () => initialState(),
  actions: {
    async loadPromotionalProducts() {
      this.loading = true

      return api
        .getPromotionalProducts()
        .then(({ data }) => {
          this.promotionalProducts = data
        })
        .catch((error: ApiError) => {
          handleApiError(error, undefined, {
            errorCode: 404,
            appearance: 'NONE',
            logError: false
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
})

export default usePromotionalProducts
