import { FilterIdentifier, FilterSelection, FilterValue } from '@/components/filter/types'
import { defineStore } from 'pinia'
import api from '@/modules/quote/api'
import { handleApiError } from '@/handleApiError'
import {
  getDefaultFilters,
  getSelectionForFilter,
  removeItemFromFilterSelection,
  updateFilterSelection
} from '@/components/filter/helpers'
import { DEFAULT_FILTER_TERM_QUOTES, QuoteSort, QuoteSorts } from '@/modules/quote/types'

interface QuoteFilterState {
  filters: FilterIdentifier[]
  selection: FilterSelection<QuoteSort>
  loading: boolean
  initialized: boolean
}

const initSelection = (): FilterSelection<QuoteSort> => {
  return {
    sort: QuoteSorts.DATE_DESC,
    filters: []
  }
}

const initialState = (): QuoteFilterState => {
  return {
    filters: [],
    selection: initSelection(),
    loading: false,
    initialized: false
  }
}

const useCollectionsFilter = defineStore('quotesFilter', {
  state: () => initialState(),
  actions: {
    async resetFilter() {
      await this.applyDefaultFilters()
    },
    getActiveFilters() {
      return this.selection.filters.filter((filter) => filter.values.length > 0)
    },
    getFilterTerm(): string | undefined {
      const filterTerm: string[] = []
      const filterValues = this.getActiveFilters().flatMap((filter) => filter.values)
      filterValues.forEach((value) => filterTerm.push(value.term))
      return filterTerm.length > 0 ? filterTerm.join(',') : undefined
    },
    async getQuotesFilters(keyword?: string) {
      this.loading = true
      const filters = this.getFilterTerm()

      return api
        .getQuoteFilters(filters, keyword)
        .then(({ data }) => {
          this.filters = data
        })
        .catch(handleApiError)
        .finally(() => {
          this.loading = false
        })
    },
    async applyDefaultFilters() {
      this.selection = initSelection()
      await this.getQuotesFilters()
      this.selection.filters = getDefaultFilters(this.filters, DEFAULT_FILTER_TERM_QUOTES)
      this.initialized = true
    },
    getSelectionForFilter(name: string) {
      return getSelectionForFilter(this.selection.filters, name)
    },
    updateFilterSelection(name: string, items: FilterValue[] | undefined) {
      updateFilterSelection(this.selection.filters, name, items)
    },
    removeItemFromFilterSelection(name: string, item: FilterValue) {
      removeItemFromFilterSelection(this.selection.filters, name, item)
    }
  }
})

export default useCollectionsFilter
