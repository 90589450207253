import { Event } from '@/modules/tracking/types'
import AddToCartButtonEvents from '@/modules/tracking/events/helper/addToCartButton'

export default class ProductPageEvent extends Event {
  static PageView = (productTitle: string): ProductPageEvent =>
    new ProductPageEvent('Seite angesehen', productTitle)
  static Comparison = class {
    static AddToComparison = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Zu Vergleich hinzugefügt').withProduct(productId)
    static RemoveFromComparison = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Vom Vergleich entfernt').withProduct(productId)
  }

  static Datasheet = class {
    static Html = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Datenblatt aufgerufen', 'HTML').withProduct(productId)
    static Pdf = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Datenblatt aufgerufen', 'PDF').withProduct(productId)
    static Manufacturer = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Datenblatt aufgerufen', 'Hersteller').withProduct(productId)
  }
  static CreateCollection = (productId: number): ProductPageEvent =>
    new ProductPageEvent('Sammlung Erstellt').withProduct(productId)

  static AddToCart = class {
    static Header: AddToCartButtonEvents = {
      addToCart: new ProductPageEvent('In den Warenkorb gelegt', 'Über Produktheader'),
      request: new ProductPageEvent('Produkt angefragt', 'Über Produktheader')
    }
    static Source: AddToCartButtonEvents = {
      addToCart: new ProductPageEvent('In den Warenkorb gelegt', 'Über Bezugsquelle'),
      request: new ProductPageEvent('Produkt angefragt', 'Über Bezugsquelle')
    }
    static Accessory: AddToCartButtonEvents = {
      addToCart: new ProductPageEvent('Accessoire in den Warenkorb gelegt'),
      request: new ProductPageEvent('Accessoire angefragt')
    }
  }
  static AddProductToCollection = (productId: number): ProductPageEvent =>
    new ProductPageEvent('Produkt zu Sammlung hinzugefügt').withProduct(productId)
  static ProductType = (productId: number): ProductPageEvent =>
    new ProductPageEvent('Produkttyp aufgerufen').withProduct(productId)

  static AddProductToExistingQuote = (productId: number): ProductPageEvent =>
    new ProductPageEvent('Produkt zur Anfrage hinzugefügt', 'Über Produktheader').withProduct(
      productId
    )
  static AddProductToNewQuote = (productId: number): ProductPageEvent =>
    new ProductPageEvent('Anfrage erstellt', 'Über Produktheader').withProduct(productId)

  static Tabs = class {
    static PartsList = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Tab angeklickt', 'Bundle Stückliste').withProduct(productId)
    static Sources = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Tab angeklickt', 'Bezugsquellen').withProduct(productId)
    static TechnicalData = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Tab angeklickt', 'Technische Daten').withProduct(productId)
    static Accessories = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Tab angeklickt', 'Accessories').withProduct(productId)
    static Description = (productId: number): ProductPageEvent =>
      new ProductPageEvent('Tab angeklickt', 'Beschreibung').withProduct(productId)
  }
  constructor(action: string, label?: string) {
    super('Produktseite', action, label ?? '')
  }
}
