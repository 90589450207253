import { SortDirection, ViewToggle } from '@/types'
import { ComputedRef, computed } from 'vue'
import { ActivityView, Discount, Quote, QuoteSorts, QuoteStatus } from '@/modules/quote/types'
import useQuotes from '@/modules/quote/useQuotes'
import useQuotesFilter from '@/modules/quote/useQuotesFilter'
import i18n from '@/plugins/i18n'
import { formatMoney } from '@/modules/product/helpers'
import { mdiHistory, mdiMessageText } from '@mdi/js'

const formatDiscount = (discount: Discount): string => {
  if (discount.percentual) {
    return i18n.global.t('discount', [i18n.global.n(discount.value.value) + '%'])
  } else {
    return i18n.global.t('discount', [formatMoney(discount.value, false, true)])
  }
}

const getQuoteStatusColor = (status: QuoteStatus): string => {
  switch (status) {
    case QuoteStatus.ACCEPTED:
      return 'green'
    case QuoteStatus.DRAFT:
    case QuoteStatus.REQUESTED:
      return 'grey'
    case QuoteStatus.NEGOTIATION:
      return 'blue'
    case QuoteStatus.REJECTED:
    default:
      return 'red'
  }
}

const useQuoteSortToggle = (): {
  direction: ComputedRef<SortDirection>
  toggleSort: (page: number, keyword: string) => void
} => {
  const sort = computed({
    get: () => useQuotesFilter().selection.sort,
    set(sort) {
      useQuotesFilter().selection.sort = sort
    }
  })

  const toggleSort = (page: number, keyword: string) => {
    if (sort.value == QuoteSorts.DATE_ASC) {
      sort.value = QuoteSorts.DATE_DESC
    } else if (sort.value == QuoteSorts.DATE_DESC) {
      sort.value = QuoteSorts.DATE_ASC
    } else {
      sort.value = QuoteSorts.DATE_ASC
    }
    useQuotes().getQuotes(page, keyword)
  }

  const direction = computed(() => {
    if (sort.value == QuoteSorts.DATE_ASC) {
      return SortDirection.ASC
    } else if (sort.value == QuoteSorts.DATE_DESC) {
      return SortDirection.DESC
    } else {
      return SortDirection.NONE
    }
  })

  return { direction, toggleSort }
}

const isQuoteInFinalStage = (quote: Quote): boolean => {
  return quote.quoteStatus === QuoteStatus.ACCEPTED || quote.quoteStatus === QuoteStatus.REJECTED
}

const getActivityViews = (): ViewToggle<ActivityView>[] => [
  {
    name: ActivityView.CHAT,
    icon: mdiMessageText,
    restriction: () => true,
    showOnMobile: true
  },
  {
    name: ActivityView.HISTORY,
    icon: mdiHistory,
    restriction: () => true,
    showOnMobile: true
  }
]

export {
  getActivityViews,
  getQuoteStatusColor,
  isQuoteInFinalStage,
  useQuoteSortToggle,
  formatDiscount
}
