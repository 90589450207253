<template>
  <v-card>
    <v-card-item>
      <v-card-title>
        <sf-heading>{{ $t('order.orderNumbers') }}</sf-heading>
      </v-card-title>
    </v-card-item>
    <v-card-text>
      <v-row dense justify="center">
        <v-col :md="showCustomerOrderId ? 4 : 6" cols="12" sm="6">
          <v-text-field
            ref="customer-number"
            color="primary"
            :label="$t('clientNumber')"
            :model-value="customerNumber"
            hide-details
            variant="outlined"
            readonly
            autocomplete="off"
          />
        </v-col>
        <v-col :md="showCustomerOrderId ? 4 : 6" cols="12" sm="6">
          <v-text-field
            v-if="showOrderNumber"
            ref="order-number"
            v-model="orderNumberValue"
            v-debounce="onOrderNumberChanged"
            color="primary"
            :label="$t('order.orderNumber') + (readOnly || !isCustomOrderNumberEnabled ? '' : ' *')"
            :readonly="readOnly || !isCustomOrderNumberEnabled"
            :rules="[requiredRule]"
            :error-messages="orderNumberError"
            variant="outlined"
            autocomplete="off"
            :maxlength="ORDER_NUMBER_MAX_LENGTH"
            :hint="
              orderNumberValue.length == ORDER_NUMBER_MAX_LENGTH
                ? $t('maxLength', [ORDER_NUMBER_MAX_LENGTH])
                : ''
            "
            persistent-hint
          />
          <v-text-field
            v-else
            class="input-italic"
            :model-value="$t('order.orderNumberGeneratedAfterOrder')"
            color="primary"
            :label="$t('order.orderNumber')"
            readonly
            variant="outlined"
            autocomplete="off"
          />
        </v-col>
        <v-col v-if="showCustomerOrderId" cols="12" md="4" sm="6">
          <v-text-field
            ref="customer-order-id"
            v-model="customerOrderIdValue"
            v-debounce="onCustomerOrderIdChanged"
            color="primary"
            :label="$t('customerOrderId') + (customerOrderIdRequired ? ' *' : '')"
            :readonly="readOnly"
            :rules="customerOrderIdRequired ? [requiredRule] : []"
            variant="outlined"
            autocomplete="off"
            :maxlength="ORDER_NUMBER_MAX_LENGTH"
            :hint="
              customerOrderIdValue.length == ORDER_NUMBER_MAX_LENGTH
                ? $t('maxLength', [ORDER_NUMBER_MAX_LENGTH])
                : ''
            "
            persistent-hint
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import SfHeading from '@/components/text/SfHeading.vue'
import useCheckout from '@/modules/order/useCheckout'
import { OrderNumberEvents } from '@/modules/tracking/events/checkout'
import { trackEvent } from '@/modules/tracking/useTracking'
import { requiredRule } from '@/rules'
import { storeToRefs } from 'pinia'
import { defineComponent, PropType, ref, watch } from 'vue'
import { ORDER_NUMBER_MAX_LENGTH } from '@/modules/order/types'

export default defineComponent({
  name: 'OrderNumbers',
  components: { SfHeading },
  props: {
    customerNumber: {
      type: String,
      required: true
    },
    orderNumber: {
      type: String,
      default: ''
    },
    customerOrderId: {
      type: String,
      default: ''
    },
    showCustomerOrderId: {
      type: Boolean,
      default: false
    },
    customerOrderIdRequired: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    showOrderNumber: {
      type: Boolean,
      default: true
    },
    isCustomOrderNumberEnabled: {
      type: Boolean,
      required: true
    },
    events: {
      type: Object as PropType<OrderNumberEvents>,
      default: undefined
    }
  },
  emits: ['orderNumberChanged', 'customerOrderIdChanged'],
  setup(props, { emit }) {
    const { orderNumberError } = storeToRefs(useCheckout())

    const orderNumberValue = ref(props.orderNumber)
    const customerOrderIdValue = ref(props.customerOrderId)

    const onOrderNumberChanged = () => {
      if (!props.readOnly && props.isCustomOrderNumberEnabled) {
        useCheckout()
          .validateOrderNumber(orderNumberValue.value)
          .then(() => {
            if (!orderNumberError.value) {
              if (props.events) {
                trackEvent(props.events.OrderNumber)
              }

              emit('orderNumberChanged', orderNumberValue.value)
            }
          })
      }
    }

    const onCustomerOrderIdChanged = () => {
      if (!props.readOnly) {
        if (props.events) {
          trackEvent(props.events.CustomerOrderNumber)
        }

        emit('customerOrderIdChanged', customerOrderIdValue.value)
      }
    }

    watch(
      () => props.orderNumber,
      (newValue) => {
        orderNumberValue.value = newValue
      }
    )

    return {
      orderNumberValue,
      customerOrderIdValue,
      requiredRule,
      orderNumberError,
      onOrderNumberChanged,
      onCustomerOrderIdChanged,
      ORDER_NUMBER_MAX_LENGTH
    }
  }
})
</script>

<style lang="scss" scoped>
.input-italic {
  &:deep(input) {
    font-style: italic;
  }
}
</style>
