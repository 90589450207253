enum Step {
  CONTACT = 'contact',
  COMPANY = 'company',
  PASSWORD = 'password',
  REQUESTED = 'requested'
}

type LoginCredentials = {
  mail: string
  password: string
}

type AuthTokens = {
  refreshToken: string
  accessToken: string
}

enum LoginError {
  WRONG_CREDENTIALS = 'WRONG_CREDENTIALS',
  USER_DEACTIVATED = 'USER_DEACTIVATED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  NONE = 'NONE'
}

type Token = {
  value: string
}

type PasswordResetDTO = {
  mail: string
}

type ChangePasswordDTO = {
  token: Token
  password: string
}

type RegistrationDTO = {
  token: Token
  firstName: string
  lastName: string
  password: string
  contactTitle: string
  termsConfirmation: boolean
  customTermsConfirmation: boolean
  alpha2language: string
}

type SelfRegistrationDTO = {
  person: ContactDTO
  password: string
  company?: CompanyDTO
  termsConfirmation: boolean
  customTermsConfirmation: boolean
}

type ContactDTO = {
  email: string
  firstName: string
  lastName: string
  contactTitle: string
  alpha2language: string
}

type CompanyDTO = {
  name: string
  street: string
  zipCode: string
  city: string
  country: string
  phone: string
}

export {
  LoginCredentials,
  Token,
  AuthTokens,
  PasswordResetDTO,
  ChangePasswordDTO,
  RegistrationDTO,
  LoginError,
  ContactDTO,
  CompanyDTO,
  SelfRegistrationDTO,
  Step
}
