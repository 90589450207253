import {
  CartOptimizationResult,
  OptimizationStrategy,
  OptimizationType
} from '@/modules/cart/types'
import api from '@/modules/cart/api'
import { ApiError, handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import useCart from '@/modules/cart/useCart'

interface CartOptimizationsState {
  availableOptimizations: OptimizationType[]
  cartOptimizationResults: CartOptimizationResult[]
  loading: boolean
  calculating: boolean
  error: 'UNKNOWN' | 'MAX_ITEMS' | undefined
}

const initialState = (): CartOptimizationsState => {
  return {
    availableOptimizations: [],
    cartOptimizationResults: [],
    loading: false,
    calculating: false,
    error: undefined
  }
}

const useCartOptimizations = defineStore('cartOptimizations', {
  state: () => initialState(),
  actions: {
    async loadAvailableOptimizations() {
      this.loading = true

      return api
        .getCartOptimizations()
        .then(({ data }) => {
          this.availableOptimizations = data
        })
        .catch(() => {
          this.error = 'UNKNOWN'
        })
        .finally(() => {
          this.loading = false
        })
    },
    async calculateOptimizations() {
      this.calculating = true

      return api
        .calculateCartOptimizations()
        .then(({ data }) => {
          this.cartOptimizationResults = data
          this.error = undefined
        })
        .catch((error: ApiError) => {
          if (error.code == 413) {
            this.error = 'MAX_ITEMS'
          } else {
            this.error = 'UNKNOWN'
          }
        })
        .finally(() => {
          this.calculating = false
        })
    },
    async optimizeCart(type: OptimizationStrategy) {
      this.calculating = true

      return api
        .optimizeCart(type)
        .then(() => {
          useCart().loadCart(true)
          this.calculateOptimizations()
        })
        .catch((error: ApiError) => {
          if (error.code == 413) {
            handleApiError(error, {
              customMessageKey: 'cart.optimizationUnavailable'
            })
          } else {
            handleApiError(error, {
              customMessageKey: 'cart.optimizationFailed'
            })
          }
        })
    }
  }
})

export default useCartOptimizations
