<template>
  <instant-product-search-input @product-selected="addProductToCollection" />
</template>

<script lang="ts">
import { DetailedShopCollection, ShopCollection } from '@/modules/collection/types'
import useAddToCollection from '@/modules/collection/useAddToCollection'
import InstantProductSearchInput from '@/modules/search/components/InstantProductSearchInput.vue'
import CollectionEvent from '@/modules/tracking/events/collection'
import { trackEvent } from '@/modules/tracking/useTracking'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'CollectionAddProductInput',
  components: { InstantProductSearchInput },
  props: {
    collection: {
      type: Object as PropType<ShopCollection | DetailedShopCollection | undefined>,
      required: true
    }
  },
  setup(props) {
    const addProductToCollection = (productId: number) => {
      useAddToCollection().addToCollection(props.collection!.id, productId)
      trackEvent(CollectionEvent.AddProduct)
    }

    return {
      addProductToCollection
    }
  }
})
</script>
