<template>
  <v-card height="100%">
    <v-card-text>
      <quote-address :address="orderAddress" />
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import { OrderAddress } from '@/modules/address/types'
import QuoteAddress from '@/modules/quote/components/QuoteAddress.vue'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'QuoteAddressCard',
  components: { QuoteAddress },
  props: {
    orderAddress: {
      type: Object as PropType<OrderAddress>,
      required: true
    }
  }
})
</script>
