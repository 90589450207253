import {
  AccessoryGroup,
  Product,
  RequestEmailResponse,
  ProductFilterResponse,
  ProductPriceUpdate,
  RuntimeRequest,
  MediumProduct,
  BundleItem,
  ProductSearchOptions
} from '@/modules/product/types'
import { get, post, Request, Response } from '@/api'
import { PageableResponse } from '@/types'
import { PAGE_SIZE } from '@/types'

const buildFilterTerm = (filters?: string[], category?: string): string | undefined => {
  const filterSet = new Set<string>()
  if (category) {
    filterSet.add(category)
  }
  if (filters) {
    for (const filter of filters) {
      filterSet.add(filter)
    }
  }
  return Array.from(filterSet).join(',') || undefined
}

export default {
  async loadComparableProducts(productIds: number[]): Promise<Response<Product[]>> {
    return get<Product[]>('/product-comparison', {
      params: {
        productIds: productIds.join(',')
      }
    })
  },
  loadProduct: (id: number): Promise<Response<Product>> => {
    return get<Product>('/products/' + id)
  },
  searchProducts: (
    options: ProductSearchOptions
  ): Promise<Response<PageableResponse<MediumProduct>>> => {
    const config: Request = {
      params: {
        keyword: options.keyword,
        quick: options.instantSearch,
        filters: buildFilterTerm(options.filters, options.category),
        size: options.size ?? PAGE_SIZE,
        sort: options.sort,
        page: options.page !== undefined ? options.page - 1 : undefined
      }
    }
    return get<PageableResponse<MediumProduct>>('/products', config)
  },
  getFilters: (options: ProductSearchOptions): Promise<Response<ProductFilterResponse>> => {
    const config: Request = {
      params: {
        keyword: options.keyword,
        quick: options.instantSearch,
        filters: buildFilterTerm(options.filters, options.category),
        size: options.size ?? PAGE_SIZE,
        sort: options.sort,
        page: options.page !== undefined ? options.page - 1 : undefined
      }
    }
    return get<ProductFilterResponse>('/products/filters', config)
  },
  loadAccessories: (id: number): Promise<Response<AccessoryGroup[]>> => {
    return get<AccessoryGroup[]>('/products/accessories/' + id)
  },
  loadBundleItems: (id: number): Promise<Response<BundleItem[]>> => {
    return get<BundleItem[]>('/products/bundle-items/' + id)
  },
  loadRealtimePrices: (request: RuntimeRequest): Promise<Response<ProductPriceUpdate>> => {
    return post<ProductPriceUpdate, RuntimeRequest>('/runtime/product', request)
  },
  getProductRequest: (id: number): Promise<Response<RequestEmailResponse>> => {
    return get<RequestEmailResponse>('/emails/request-product/' + id.toString())
  },
  sendProductRequest: (id: number): Promise<Response<string>> => {
    return post<string, unknown>('/emails/request-product/' + id.toString(), '')
  },
  loadDatasheet: (id: number, type: string): Promise<Response<Blob>> => {
    return get<Blob>('/products/' + id + '/datasheet/' + type, {
      responseType: 'blob'
    })
  }
}
