import { handleApiError } from '@/handleApiError'
import api from '@/modules/address/api'
import { Address, AddressType, AddressValidation } from '@/modules/address/types'
import { defineStore } from 'pinia'

type AddressValidationState = {
  [key in AddressType]: AddressValidation
}

const initialState = (): AddressValidationState => {
  return {
    delivery: {
      valid: true,
      error: undefined
    },
    billing: {
      valid: true,
      error: undefined
    }
  }
}

const useAddressValidation = defineStore('addressValidation', {
  state: () => initialState(),
  actions: {
    async validateAddress(address: Address | undefined, addressType: AddressType) {
      return api
        .validateCheckoutAddress(addressType, address)
        .then(({ data }) => {
          this[addressType].valid = data.valid
          this[addressType].error = data.error
        })
        .catch(handleApiError)
    }
  }
})

export default useAddressValidation
