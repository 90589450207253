import { FilterIdentifier } from '@/components/filter/types'
import { ApiError, handleApiError } from '@/handleApiError'
import api from '@/modules/order/api'
import { Order, OrderSortOrder } from '@/modules/order/types'
import useOrderFilter from '@/modules/order/useOrderFilter'
import { PageableResponse } from '@/types'
import { AxiosResponse } from 'axios'
import { defineStore } from 'pinia'

const PAGE_SIZE = 20

interface OrdersState {
  orders: Order[]
  count: number
  pages: number
  loading: boolean
  newApprovableOrders: boolean
}

const initialState = (): OrdersState => {
  return {
    orders: [],
    count: 0,
    pages: 0,
    loading: false,
    newApprovableOrders: false
  }
}

const useOrders = defineStore('orders', {
  state: () => initialState(),
  getters: {
    hasApprovableOrders: (state) => {
      return () => state.newApprovableOrders
    }
  },
  actions: {
    async initOrders(page: number, approvables: boolean, keyword?: string) {
      this.loading = true

      await useOrderFilter().applyDefaultFilters(approvables)
      this.getOrders(page, approvables, keyword)
    },
    async checkApprovableOrders() {
      return api.getApprovableOrders(1, 1, OrderSortOrder.CREATED_DESC).then(({ data }) => {
        this.newApprovableOrders = data.totalCount > 0
      })
    },
    async getOrders(page: number, approvables: boolean, keyword?: string) {
      this.loading = true

      let getOrderPromise: (
        page: number,
        size: number,
        sort: OrderSortOrder,
        filter?: FilterIdentifier[],
        keyword?: string | undefined
      ) => Promise<AxiosResponse<PageableResponse<Order>>>

      const sort = useOrderFilter().selection.sort
      const filter = useOrderFilter().selection.filters
      useOrderFilter().getOrderFilters(filter, approvables, keyword)

      if (approvables) {
        getOrderPromise = api.getApprovableOrders
      } else {
        getOrderPromise = api.getOrders
      }

      return getOrderPromise(page, PAGE_SIZE, sort, filter, keyword)
        .then(({ data }) => {
          this.orders = data.items
          this.count = data.totalCount
          this.pages = data.pageCount
          if (approvables) {
            this.newApprovableOrders = data.totalCount > 0
          }
        })
        .catch((error: ApiError) => {
          handleApiError(
            error,
            {
              appearance: 'CUSTOM',
              customHandler: 'OrdersList'
            },
            {
              errorCode: 404,
              customMessageKey: 'error.ordersNotFound'
            }
          )
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
})

export default useOrders
