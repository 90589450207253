import { Address } from '@/modules/address/types'
import { ContactData } from '@/modules/contactData/types'
import { AddressSelection, OrderSumInformation } from '@/modules/order/types'
import { Money, SimplePrice } from '@/modules/product/types'
import { ContactUser, ShortUser } from '@/modules/user/types'
import { Language } from '@/types'
import { Timestamped } from '@/utils/timestampUtils'

type Quote = Timestamped & {
  id: string
  name: string
  quoteStatus: QuoteStatus
  quote?: FullQuote
  orderId?: string
}

type QuoteDTO = {
  id: string
  name: string
  quoteStatus: QuoteStatus
  request?: QuoteRequest
  quote?: FullQuote
  timestamp: string
  orderId?: string
}

type QuoteDocument = {
  date: string
  documentNumber?: string
  downloadLink?: string
}

type QuoteRequestDraft = {
  id: string
  name: string
  hasProduct: boolean
}

type QuoteRequest = {
  id: string
  author: ShortUser
  createdDate: string
  items: QuoteItem[]
  comment?: string
  sum: Money
}

type QuoteRequestPostDTO = {
  name: string
  items: QuoteRequestItemPostDTO[]
}

type RequestItemUpdate = {
  quantity?: number
  comment?: string
}

type QuoteRequestItemPostDTO = {
  productId?: number
  label?: string
  quantity?: number
}

type ShortQuote = {
  id: string
  name: string
  quoteNumber: string
  itemCount: number
  assignee?: ShortUser
  createdDate: string
  sum: Money
  quoteStatus: QuoteStatus
  alreadyViewed: boolean
  buyerArchived: boolean
  isGrossQuote: boolean
  quoteVersion: string
}

type FullQuote = {
  id: string
  name: string
  quoteNumber: string
  itemCount: number
  headerText?: string
  footerText?: string
  sellerUser?: ContactUser
  buyerAddress: Address
  buyerAddressSelection?: AddressSelection
  buyerUser?: ContactUser
  buyerCustomerNumber?: string
  createdDate: string
  validDate?: string
  items: QuoteItem[]
  sumInformation: OrderSumInformation
  saving?: number
  rejectionInformation?: RejectionInformation
  isArchived: boolean
  isGrossQuote: boolean
  alpha2language: Language
  quoteVersion: string
}

type RejectionInformation = {
  date: string
  rejectedByUser: ShortUser
  rejectionComment?: string
}

enum QuoteStatus {
  DRAFT = 'DRAFT',
  NEGOTIATION = 'NEGOTIATION',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED'
}

type QuoteItem = {
  id: string
  productId?: number
  quantity: number
  productTitle: string
  productImageUrls: string[]
  price?: SimplePrice
  discount?: Discount
  totalPrice?: Money
  vatPercent?: number
  isOptional: boolean
  isQuantityEditCustomer: boolean
  isPickedOptional: boolean
  hasDatasheet: boolean
}

type QuoteRequestPatch = {
  name?: string
  comment?: string
}

type QuoteItemUpdate = {
  quantity?: number
  isPickedOptional?: boolean
  timestamp: string
}

type QuoteUpdate = {
  buyerContact?: ContactData
  buyerAddress?: Address
  buyerAddressId?: string
  timestamp: string
}

type QuoteId = {
  id: string
}

type QuoteNewCount = {
  count: number
}

type Discount = {
  value: Money
  percentual: boolean
}

const QuoteSorts = {
  DATE_ASC: 'DATE_ASC',
  DATE_DESC: 'DATE_DESC',
  LAST_MODIFIED: 'LAST_MODIFIED'
} as const
type QuoteSort = (typeof QuoteSorts)[keyof typeof QuoteSorts]

const DEFAULT_FILTER_TERM_QUOTES = 'BARNOTARCHIVED'

enum ActivityView {
  CHAT = 'CHAT',
  HISTORY = 'HISTORY'
}

type QuoteActivity = {
  id: string
  type: QuoteActivityType
  user: QuoteActivityUser
  message: string
  timestamp: string
}

type QuoteActivityUser = {
  avatarUrl?: string
  fullName: string
}

enum QuoteActivityType {
  CHAT = 'CHAT',
  HISTORY = 'HISTORY'
}

type CommentDTO = {
  value: string
}

export {
  ActivityView,
  CommentDTO,
  DEFAULT_FILTER_TERM_QUOTES,
  Discount,
  FullQuote,
  Quote,
  QuoteActivity,
  QuoteActivityType,
  QuoteActivityUser,
  QuoteDTO,
  QuoteDocument,
  QuoteId,
  QuoteItem,
  QuoteItemUpdate,
  QuoteRequestDraft,
  QuoteNewCount,
  QuoteRequest,
  QuoteRequestItemPostDTO,
  QuoteRequestPatch,
  QuoteRequestPostDTO,
  QuoteSort,
  QuoteSorts,
  QuoteStatus,
  QuoteUpdate,
  RejectionInformation,
  RequestItemUpdate,
  ShortQuote
}
