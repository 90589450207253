import { ContactData } from '@/modules/contactData/types'

const createEmptyContactData = (): ContactData => {
  return {
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  }
}

export { createEmptyContactData }
