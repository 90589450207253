import { Event } from '@/modules/tracking/types'
import { ProductClass, BaseModelResource } from '@/modules/ctoFinder/types'
import AddToCartButtonEvents from '@/modules/tracking/events/helper/addToCartButton'

export default class CtoEvent extends Event {
  static Choose = class {
    static Class = (productClass: ProductClass): CtoEvent => {
      return new CtoEvent('Produktklasse ausgewählt', productClass.productClassDescription)
    }

    static BaseModel = (baseModel: BaseModelResource): CtoEvent => {
      return new CtoEvent('Basismodell ausgewählt', baseModel.baseModel.description)
    }
  }
  static AddToCart: AddToCartButtonEvents = {
    addToCart: new CtoEvent('Zum Warenkorb hinzugefügt'),
    request: new CtoEvent('Produkt angefragt')
  }

  protected constructor(action: string, label?: string) {
    super('Apple CTO', action, label ?? '')
  }
}
