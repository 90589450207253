<template>
  <div>
    <expandable-group
      v-for="group in accessoriesGrouped"
      :key="group.name"
      :title="group.name"
      class="accessory-group"
    >
      <div v-for="(products, name) in group.types" :key="name + '-title'">
        <product-accessories-category class="mb-2" :name="name" :products="products" />
      </div>
    </expandable-group>
  </div>
</template>

<script lang="ts">
import { AccessoryGroup, MediumProduct } from '@/modules/product/types'
import ProductPageEvent from '@/modules/tracking/events/productPage'
import { computed, defineComponent, PropType } from 'vue'
import ExpandableGroup from '@/components/ExpandableGroup.vue'
import ProductAccessoriesCategory from '@/modules/product/components/ProductAccessoriesCategory.vue'

export default defineComponent({
  name: 'ProductAccessories',
  components: { ProductAccessoriesCategory, ExpandableGroup },
  props: {
    accessories: {
      type: Array as PropType<AccessoryGroup[]>,
      required: true
    }
  },
  setup(props) {
    const mapAccessoryGroup = (group: AccessoryGroup) => {
      const types: Record<string, MediumProduct[]> = {}

      for (const product of group.products) {
        const typeName = product.productType.name
        if (!types[typeName]) {
          types[typeName] = []
        }
        types[typeName].push(product)
      }

      return { name: group.groupName, types }
    }

    const accessoriesGrouped = computed(() => props.accessories.map(mapAccessoryGroup))

    return {
      accessoriesGrouped,
      accessoryEvent: ProductPageEvent.AddToCart.Accessory
    }
  }
})
</script>
