import { ApiError, handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import api from '@/modules/quote/api'
import { ShortQuote } from '@/modules/quote/types'
import useQuotesFilter from '@/modules/quote/useQuotesFilter'
import { PAGE_SIZE } from '@/types'

interface QuotesState {
  quotes: ShortQuote[]
  count: number
  pages: number
  loading: boolean
  newCount: number
}

const initialState = (): QuotesState => {
  return {
    quotes: [],
    count: 0,
    pages: 0,
    loading: false,
    newCount: 0
  }
}

const useQuotes = defineStore('quotes', {
  state: () => initialState(),
  getters: {
    getNewCount: (state) => () => {
      return state.newCount
    }
  },
  actions: {
    async initQuotes(page: number, keyword?: string) {
      this.loading = true

      await useQuotesFilter().applyDefaultFilters()
      this.getQuotes(page, keyword)
    },
    async getQuotes(page: number, keyword?: string) {
      this.loading = true

      const filter = useQuotesFilter().getFilterTerm()
      const sort = useQuotesFilter().selection.sort
      useQuotesFilter().getQuotesFilters(keyword)

      return api
        .getQuotes(page, PAGE_SIZE, sort, filter, keyword)
        .then(({ data }) => {
          this.quotes = data.items
          this.count = data.totalCount
          this.pages = data.pageCount
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            appearance: 'CUSTOM',
            customHandler: 'Quotes',
            customMessageKey: 'error.quotesNotFound'
          })
        })
        .finally(() => {
          this.loading = false
          this.getNewQuotesCount()
        })
    },
    async getNewQuotesCount() {
      return api
        .countNewQuotes()
        .then(({ data }) => (this.newCount = data.count))
        .catch(handleApiError)
    }
  }
})

export default useQuotes
