import { handleApiError } from '@/handleApiError'
import api from '@/modules/portalSettings/api'
import { OrderingConditions } from '@/modules/portalSettings/types'
import { defineStore } from 'pinia'
import { DEFAULT_POSITION_COMMENT_LENGTH } from '@/modules/cart/types'

export interface OrderingConditionsState {
  orderingConditions: OrderingConditions
  loading: boolean
}

const initialState = (): OrderingConditionsState => {
  return {
    orderingConditions: {
      showCustomerOrderId: false,
      customerOrderIdRequired: false,
      isPartialShipmentEnabled: false,
      isSofortPaymentEnabled: false,
      maxCommentLength: DEFAULT_POSITION_COMMENT_LENGTH
    },
    loading: false
  }
}

const useOrderingConditions = defineStore('orderingConditions', {
  state: () => initialState(),
  actions: {
    async getOrderingConditions() {
      this.loading = true

      return api
        .getOrderingConditions()
        .then(({ data }) => {
          this.orderingConditions = data
        })
        .catch(handleApiError)
        .finally(() => {
          this.loading = false
        })
    }
  }
})

export default useOrderingConditions
