import * as i18nIsoCountries from 'i18n-iso-countries'
import countries_de from 'i18n-iso-countries/langs/de.json' assert { type: 'json' }
import countries_en from 'i18n-iso-countries/langs/en.json' assert { type: 'json' }
import countries_fr from 'i18n-iso-countries/langs/fr.json' assert { type: 'json' }
import countries_it from 'i18n-iso-countries/langs/it.json' assert { type: 'json' }
import countries_pl from 'i18n-iso-countries/langs/pl.json' assert { type: 'json' }
import countries_nl from 'i18n-iso-countries/langs/nl.json' assert { type: 'json' }
import countries_es from 'i18n-iso-countries/langs/es.json' assert { type: 'json' }
import { Address } from '@/modules/address/types'
import { isQuotesPortal } from '@/helpers'

i18nIsoCountries.registerLocale(countries_de)
i18nIsoCountries.registerLocale(countries_en)
i18nIsoCountries.registerLocale(countries_fr)
i18nIsoCountries.registerLocale(countries_it)
i18nIsoCountries.registerLocale(countries_pl)
i18nIsoCountries.registerLocale(countries_nl)
i18nIsoCountries.registerLocale(countries_es)

const createEmptyAddress = (): Address => {
  return {
    companyName: '',
    addition1: '',
    addition2: '',
    street: '',
    zipCode: '',
    city: '',
    postBox: '',
    iso3country: '',
    phone: '',
    fax: '',
    email: '',
    clientNumber: '',
    url: '',
    isDefaultBillingAddress: false,
    isDefaultDeliveryAddress: false,
    canEdit: true
  }
}

const showAddressDefaultChips = (address: Address): boolean => {
  return (
    address.isPrimaryAddress ||
    (address.isDefaultBillingAddress && !isQuotesPortal.value) ||
    (address.isDefaultDeliveryAddress && !isQuotesPortal.value)
  )
}

const getCountryName = (countryCode: string, locale: string): string | undefined => {
  return i18nIsoCountries.getName(countryCode, locale, {
    select: 'official'
  })
}

const getCountries = (locale: string): Array<{ value: string | undefined; label: string }> => {
  const list = i18nIsoCountries.getNames(locale.slice(0, 2), {
    select: 'official'
  })
  return Object.keys(list).map((key) => ({
    value: i18nIsoCountries.alpha2ToAlpha3(key),
    label: list[key]
  }))
}

export { createEmptyAddress, getCountries, getCountryName, showAddressDefaultChips }
