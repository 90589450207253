<template>
  <div class="d-flex flex-row align-center justify-end justify-sm-start flex-wrap">
    <span
      :class="
        (loadingRealtimePrices ? 'text-grey' : 'text-' + getStatusColor(stock.status)) +
        ' font-weight-medium'
      "
    >
      {{
        (showStockCount && stock.count ? stock.count + ' ' : '') + $t('stockStatus.' + stock.status)
      }}</span
    >
    <template v-if="stock.deliveryDate">
      <span class="ml-2 mr-1">{{ stock.deliveryDate }}</span>
      <v-tooltip
        v-model="showTooltip"
        location="bottom"
        open-on-click
        :open-on-hover="false"
        class="text-center"
        @click:outside="showTooltip = false"
      >
        <template #activator="{ props }">
          <v-icon
            class="cursor-pointer"
            size="small"
            v-bind="props"
            :icon="mdiInformationOutline"
          />
        </template>
        <template #default>
          {{ $t('productStockInfo.onStock', [stock.count]) }},
          <template v-if="stock.incomingAmount">
            {{ $t('productStockInfo.onSupply', [stock.incomingAmount]) }},
          </template>
          {{ $t('productStockInfo.nextDeliveryB2B', [stock.deliveryDate]) }}
        </template>
      </v-tooltip>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue'
import { SimpleStock } from '@/modules/product/types'
import { getStatusColor } from '@/modules/product/helpers'
import { mdiInformationOutline } from '@mdi/js'

export default defineComponent({
  name: 'ProductSourceStock',
  props: {
    stock: {
      type: Object as PropType<SimpleStock>,
      required: true
    },
    loadingRealtimePrices: {
      type: Boolean,
      required: true
    },
    showStockCount: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const showTooltip = ref(false)

    return {
      showTooltip,
      getStatusColor,
      mdiInformationOutline
    }
  }
})
</script>
