<template>
  <login-wrapper
    v-if="branding"
    :branding="branding"
    :title="$t('registration')"
    :loading="loading"
  >
    <registration-form
      class="mt-2"
      :branding="branding"
      :sending="sending"
      :token-error="tokenError"
      @finish-registration="finishRegistration"
    />
  </login-wrapper>
</template>

<script lang="ts">
import LoginWrapper from '@/modules/authentication/components/LoginWrapper.vue'
import RegistrationForm from '@/modules/authentication/components/RegistrationForm.vue'
import { RegistrationDTO } from '@/modules/authentication/types'
import useRegistration from '@/modules/authentication/useRegistration'
import useBranding from '@/modules/portalSettings/useBranding'
import { useStateManager } from '@/store'
import { useTitle } from '@/helpers'
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'RegistrationPage',
  components: { RegistrationForm, LoginWrapper },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('windowTitle.Registration')}`))
    useStateManager().reset()
    const token = useRoute().params.token as string
    useRegistration().checkRegisterToken({ value: token })

    const { branding } = useBranding()
    const { tokenError, sending, loading } = storeToRefs(useRegistration())

    const finishRegistration = (dto: RegistrationDTO) => {
      dto.token.value = token
      useRegistration().finishRegistration(dto)
    }

    return {
      branding,
      tokenError,
      sending,
      loading,
      finishRegistration
    }
  },
  methods: {}
})
</script>
