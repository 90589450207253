<template>
  <v-col cols="12">
    <v-data-table
      :headers="tableHeaders"
      :mobile="$vuetify.display.xs"
      :items="sources"
      :items-per-page="-1"
      :row-props="{ class: 'source-row' }"
      :group-by="[{ key: 'stock.status' }]"
      :sort-by="[
        { key: 'stock', order: 'asc' },
        { key: 'price.resellPrice', order: 'asc' }
      ]"
      :custom-key-sort="{ stock: statusCompare }"
      :density="$vuetify.display.xs ? 'compact' : 'default'"
    >
      <template #bottom></template>
      <template #headers></template>

      <template #[`group-header`]="{ item, isGroupOpen, toggleGroup }">
        <tr
          :ref="
            () => {
              if (!isGroupOpen(item)) toggleGroup(item)
            }
          "
          class="bg-grey-lighten-4"
        >
          <td :colspan="tableHeaders.length + 1">
            <sf-heading>{{ $t('stockStatus.' + item.value) }}</sf-heading>
          </td>
        </tr>
      </template>

      <template #[`item.stock`]="{ item }">
        <div class="d-flex flex-column my-1">
          <sf-secondary-text>{{ item.supplierName }}</sf-secondary-text>
          <sf-secondary-text v-if="item.supplierPid" class="text-medium-emphasis">
            {{ item.supplierPid }}
          </sf-secondary-text>
          <product-source-stock
            v-if="item.stock"
            :stock="item.stock"
            :loading-realtime-prices="false"
            :show-stock-count="true"
          />
        </div>
      </template>
      <template v-if="hasPurchasePrice" #[`item.price.purchasePrice`]="{ item }">
        <div v-if="item.price" class="d-flex flex-column">
          <sf-secondary-text v-if="$vuetify.display.smAndUp">
            {{ $t('price.pricePurchase') }}
          </sf-secondary-text>
          <sf-secondary-text>
            {{ formatMoney(item.price.purchasePrice) }}
          </sf-secondary-text>
        </div>
      </template>

      <template #[`item.price.resellPrice`]="{ item }">
        <div v-if="item.price" class="d-flex flex-column">
          <sf-secondary-text v-if="$vuetify.display.smAndUp">{{
            $t('price.price')
          }}</sf-secondary-text>
          <span class="text-body-2 text-primary">
            {{ formatMoney(item.price.resellPrice, true) }}
          </span>
          <sf-secondary-text
            v-if="
              item.price &&
              item.price.subscriptionModel &&
              item.price.subscriptionModel.billingPeriod &&
              item.price.subscriptionModel.billingPeriodUnit
            "
            class="text-medium-emphasis"
          >
            {{
              $t('price.priceBillingPeriodShort', [
                formatDurationShort(
                  item.price.subscriptionModel.billingPeriod,
                  item.price.subscriptionModel.billingPeriodUnit
                )
              ])
            }}
          </sf-secondary-text>
          <packaging-info
            v-if="item.price && item.price.packagingModel"
            class="text-body-2 text-medium-emphasis"
            :packaging-model="item.price.packagingModel"
          />
        </div>
      </template>

      <template #[`item.addToCart`]="{ item }">
        <cart-button
          v-if="item.price"
          :is-icon="true"
          :is-request="item.price.resellPrice.value <= 0"
          :product-id="productId"
          :quantity="1"
          :supplier-row-id="item.id"
          :on-click-event="addToCartEvent"
        />
      </template>
    </v-data-table>
  </v-col>
</template>

<script lang="ts">
import PackagingInfo from '@/components/PackagingInfo.vue'
import CartButton from '@/components/button/CartButton.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfSecondaryText from '@/components/text/SfSecondaryText.vue'
import { moneyCompare, statusCompare } from '@/helpers'
import ProductSourceStock from '@/modules/product/components/ProductSourceStock.vue'
import { formatMoney, getStatusColor } from '@/modules/product/helpers'
import { Money, SupplierRow } from '@/modules/product/types'
import ProductSearchEvent from '@/modules/tracking/events/search'
import { DataTableHeader } from '@/types'
import { formatDurationShort } from '@/utils/timeFormatHelpers'
import { PropType, computed, defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'ProductsListDetailSources',
  components: {
    CartButton,
    PackagingInfo,
    ProductSourceStock,
    SfSecondaryText,
    SfHeading
  },
  props: {
    sources: {
      type: Array as PropType<SupplierRow[]>,
      required: true
    },
    productId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const { t } = useI18n()

    const hasPurchasePrice = computed(() =>
      props.sources.some((source) => source.price && source.price.purchasePrice)
    )

    const tableHeaders: DataTableHeader<Money>[] = [
      {
        title: '',
        key: 'data-table-group',
        width: '0px',
        align: 'end',
        sortable: false,
        headerProps: {
          class: 'pa-0'
        },
        cellProps: {
          class: 'pa-0'
        }
      },
      {
        title: t('supplier').toString(),
        value: 'stock',
        align: 'start',
        sortable: false
      },
      {
        title: t('price.price').toString(),
        value: 'price.resellPrice',
        align: 'end',
        sortable: true,
        sort: moneyCompare
      },
      {
        title: '',
        value: 'addToCart',
        align: 'end',
        sortable: false
      }
    ]

    if (hasPurchasePrice.value) {
      tableHeaders.splice(2, 0, {
        title: t('price.pricePurchase').toString(),
        value: 'price.purchasePrice',
        align: 'end',
        sortable: false
      })
    }

    return {
      hasPurchasePrice,
      tableHeaders,
      addToCartEvent: ProductSearchEvent.AddToCart.Detail,
      formatMoney,
      statusCompare,
      getStatusColor,
      formatDurationShort
    }
  }
})
</script>
