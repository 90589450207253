import { Event } from '@/modules/tracking/types'

export default class CartEvent extends Event {
  constructor(action: string, label?: string) {
    super('Warenkorb', action, label ?? '')
  }

  static PositionMoved = new CartEvent('Position anderem Lieferanten zugeordnet')
  static RemovePosition = new CartEvent('Position entfernt')

  static emptyCart = new CartEvent('Warenkorb entleert')

  static createQuoteRequest = new CartEvent('Anfrage aus Warenkorb erstellt')
  static Comment = class {
    static ToDistributor = new CartEvent(
      'Positionsbasierter Kommentar hinzugefügt',
      'Kommentar an Lieferanten/Distributoren'
    )
    static ToPortalOwner = new CartEvent(
      'Positionsbasierter Kommentar hinzugefügt',
      'Kommentar an den Portalbetreiber/Verkäufer'
    )
    static OnOrder = new CartEvent('Bestellkommentar hinzugefügt')
  }

  static Optimization = class {
    static MinPrice = new CartEvent('Optimierung nach Bester Preis')
    static MinimalSuppliers = new CartEvent('Optimierung nach minimalen Quellen')
    static Manual = new CartEvent('Manuelle Optimierung')
    static Additional = new CartEvent('zusätzliche Optimierung')
  }
}
