<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :width="500"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <template #activator="slotData">
      <slot name="activator" v-bind="slotData" />
    </template>
    <v-card>
      <v-card-item>
        <v-card-title>
          <sf-heading>{{ $t('chooseAddress') }}</sf-heading>
        </v-card-title>
      </v-card-item>
      <v-card-text>
        <search-bar
          :value="searchKeyword"
          small
          always-expanded
          @search="search"
          @clear="resetSearch"
        />
        <v-divider class="my-1" />
        <address-select-hint v-if="showSelectHint" />
        <v-radio-group :model-value="model" @update:model-value="model = $event || undefined">
          <div v-for="(address, index) in displayedAddresses" :key="address.id">
            <v-radio :value="address" color="primary">
              <template #label>
                <order-address :address="address" />
              </template>
            </v-radio>
            <v-divider v-if="index != displayedAddresses.length - 1" class="my-2" />
          </div>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <sf-text-button color="grey" class="text-medium-emphasis" @click="closeDialog">
          {{ $t('cancel') }}
        </sf-text-button>
        <sf-text-button ref="save-button" @click="selectAddress">
          {{ $t('saveAddress') }}
        </sf-text-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import SfTextButton from '@/components/button/SfTextButton.vue'
import SearchBar from '@/components/SearchBar.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import AddressSelectHint from '@/modules/address/components/AddressSelectHint.vue'
import { Address } from '@/modules/address/types'
import useAddress from '@/modules/address/useAddress'
import OrderAddress from '@/modules/order/components/OrderAddress.vue'
import { storeToRefs } from 'pinia'
import { PropType, ref, watch } from 'vue'

const props = defineProps({
  addresses: {
    type: Array as PropType<Address[]>,
    required: true
  },
  showSelectHint: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['select', 'cancel'])

const model = defineModel<Address | undefined>()

const dialog = ref(false)

const { addressesFiltered } = storeToRefs(useAddress())
const displayedAddresses = ref(props.addresses)

const selectAddress = () => {
  emit('select')
  resetSearch()
  searchKeyword.value = ''
}
const closeDialog = () => {
  emit('cancel')
  resetSearch()
  searchKeyword.value = ''
  dialog.value = false
}

watch(
  () => props.addresses,
  (newValue) => {
    if (searchKeyword.value != null) {
      search(searchKeyword.value)
    } else {
      displayedAddresses.value = newValue
    }
  }
)

const searchKeyword = ref('')

const search = (keyword: string) => {
  useAddress().searchAddresses(keyword, props.addresses)
  searchKeyword.value = keyword
  displayedAddresses.value = addressesFiltered.value
}
const resetSearch = () => {
  useAddress().resetSearch()
  searchKeyword.value = ''
  displayedAddresses.value = props.addresses
}
</script>

<style scoped lang="scss">
:deep() {
  .v-label {
    opacity: 1;
  }
}
</style>
