<template>
  <v-card variant="outlined" color="grey-lighten-2" class="pa-2 mt-2">
    <v-row dense>
      <v-col cols="12" sm="5" xl="4" align-self="center">
        <v-img
          aspect-ratio="1"
          max-width="100%"
          :lazy-src="emptyPlaceholderImage"
          :src="
            modelConfiguration.baseModel.baseModelPicUrl
              ? fitImage(modelConfiguration.baseModel.baseModelPicUrl, 500, 500)
              : placeholderImage
          "
          :srcset="
            modelConfiguration.baseModel.baseModelPicUrl
              ? getSrcset(modelConfiguration.baseModel.baseModelPicUrl, 500, 500)
              : placeholderImage
          "
        />
      </v-col>
      <v-col cols="12" sm="7" xl="8">
        <sf-sub-heading>
          {{ modelConfiguration.baseModel.description }}
        </sf-sub-heading>
        <sf-text>
          <ul class="pl-4 mt-1">
            <li v-for="modelComponent in selectedModelComponents" :key="modelComponent.componentId">
              {{ modelComponent.description }}
            </li>
          </ul>
        </sf-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { emptyPlaceholderImage, fitImage, getSrcset, placeholderImage } from '@/helpers'
import { defineComponent, PropType } from 'vue'
import { ModelComponent, ModelConfigurationResource } from '@/modules/ctoFinder/types'

export default defineComponent({
  name: 'SelectedModelConfiguration',
  components: {
    SfSubHeading,
    SfText
  },
  props: {
    modelConfiguration: {
      type: Object as PropType<ModelConfigurationResource>,
      required: true
    },
    selectedModelComponents: {
      type: Array as PropType<ModelComponent[]>,
      required: true
    }
  },
  setup() {
    return {
      fitImage,
      getSrcset,
      placeholderImage,
      emptyPlaceholderImage
    }
  }
})
</script>
