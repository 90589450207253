import { VAutoCompleteGroupDivider } from '@/types'
import { OrderSearchModel } from '@/modules/order/types'
import { MediumProduct } from '@/modules/product/types'

type InstantSearchTitle = { type: InstantSearchType; key: string }

enum InstantSearchType {
  PRODUCT = 'PRODUCT',
  ORDER = 'ORDER'
}

type InstantSearchItem =
  | MediumProduct
  | OrderSearchModel
  | VAutoCompleteGroupDivider
  | InstantSearchTitle

export { InstantSearchItem, InstantSearchTitle, InstantSearchType }
