<template>
  <div>
    <list-header hide-show-categories-button>
      <template #viewToggle>
        <view-toggle
          :views="getActivityViews()"
          :current-view="currentView"
          :toggle-event="isGuest ? GuestQuoteEvent.ToggleView : QuoteEvent.ToggleView"
          @changed="changeView"
        />
      </template>
      <template v-if="!$vuetify.display.mobile" #filter>
        <v-icon :icon="mdiClose" @click="$emit('close')" />
      </template>
    </list-header>
    <sf-text-field
      v-if="currentView == ActivityView.CHAT"
      v-model="textInput"
      :placeholder="$t('writeSomething')"
      :append-icon="mdiSend"
      hide-details
      :disabled="isReadonly"
      class="mt-1"
      autocomplete="off"
      @keydown.enter="addEntry"
      @click:append="addEntry"
    />
    <div
      ref="filterContainer"
      class="d-flex flex-column overflow-y-auto scrollbar mt-2 pr-1"
      :style="'height: calc(100vh - ' + offset + 'px)'"
    >
      <div class="d-flex flex-column">
        <template v-if="currentView == ActivityView.CHAT">
          <template v-if="loading">
            <v-skeleton-loader class="loading mb-1" type="list-item-avatar-two-line" />
            <v-skeleton-loader class="loading mb-1" type="list-item-avatar-two-line" />
            <v-skeleton-loader class="loading" type="list-item-avatar-two-line" />
          </template>
          <template v-else-if="chatEntries.length > 0">
            <quote-activity-chat-entry
              v-for="entry in chatEntries"
              :key="entry.id"
              :entry="entry"
              class="my-1 pw-quote-activity-chat-entry"
            />
          </template>
          <template v-else>
            <sf-heading class="text-center mt-2">
              {{ $t('noEntries') }}
            </sf-heading>
          </template>
        </template>
        <template v-if="currentView == ActivityView.HISTORY">
          <template v-if="historyEntries.length > 0">
            <quote-activity-history-entry
              v-for="entry in historyEntries"
              :key="entry.id"
              :entry="entry"
              class="my-1 pw-quote-activity-history-entry"
            />
          </template>
          <template v-else>
            <sf-heading class="text-center mt-2">
              {{ $t('noEntries') }}
            </sf-heading>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ViewToggle from '@/components/ViewToggle.vue'
import SfTextField from '@/components/input/SfTextField.vue'
import ListHeader from '@/components/list/ListHeader.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import { isGuest } from '@/helpers'
import useAuthentication from '@/modules/authentication/useAuthentication'
import QuoteActivityChatEntry from '@/modules/quote/components/QuoteActivityChatEntry.vue'
import QuoteActivityHistoryEntry from '@/modules/quote/components/QuoteActivityHistoryEntry.vue'
import { getActivityViews } from '@/modules/quote/helpers'
import { ActivityView, QuoteActivityType } from '@/modules/quote/types'
import useQuoteActivity from '@/modules/quote/useQuoteActivity'
import QuoteEvent, { GuestQuoteEvent } from '@/modules/tracking/events/quote'
import { trackEvent } from '@/modules/tracking/useTracking'
import { containerHeight } from '@/utils/containerHeightHelper'
import { mdiClose, mdiSend } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { ComponentPublicInstance, computed, defineComponent, onBeforeUnmount, ref } from 'vue'

export default defineComponent({
  name: 'QuoteActivity',
  components: {
    ListHeader,
    ViewToggle,
    SfTextField,
    QuoteActivityChatEntry,
    QuoteActivityHistoryEntry,
    SfHeading
  },
  props: {
    quoteId: {
      type: String,
      required: true
    }
  },
  emits: ['close', 'resetFilter'],
  setup(props) {
    useQuoteActivity().getActivityEntries(props.quoteId)
    onBeforeUnmount(() => useQuoteActivity().closeActivitiesEntriesUpdateStream())

    const filterContainer = ref<ComponentPublicInstance<HTMLElement>>()
    const { offset } = containerHeight(filterContainer, 24)
    const currentView = ref(ActivityView.CHAT)
    const { loading, activityEntries } = storeToRefs(useQuoteActivity())
    const textInput = ref('')
    const isReadonly = computed(() => useAuthentication().state.value.isReadonly)

    const chatEntries = computed(() =>
      activityEntries.value.filter((entry) => entry.type === QuoteActivityType.CHAT)
    )

    const historyEntries = computed(() =>
      activityEntries.value.filter((entry) => entry.type === QuoteActivityType.HISTORY)
    )

    const changeView = (view: ActivityView) => {
      currentView.value = view
    }

    const addEntry = () => {
      if (textInput.value) {
        useQuoteActivity().addActivityEntry(props.quoteId, textInput.value)
        if (isGuest.value) {
          trackEvent(GuestQuoteEvent.AddMessage)
        } else {
          trackEvent(QuoteEvent.AddMessage)
        }
        textInput.value = ''
      }
    }

    return {
      currentView,
      filterContainer,
      offset,
      ActivityView,
      loading,
      chatEntries,
      historyEntries,
      textInput,
      changeView,
      getActivityViews,
      addEntry,
      QuoteActivityType,
      QuoteEvent,
      GuestQuoteEvent,
      isGuest,
      isReadonly,
      mdiClose,
      mdiSend
    }
  }
})
</script>

<style scoped>
.loading :deep(.v-skeleton-loader__list-item-avatar-two-line) {
  background: #ffffff00 !important;
}
</style>
