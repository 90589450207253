import { del, get, patch, post, Request, Response } from '@/api'
import { FilterIdentifier } from '@/components/filter/types'
import { CollectionPatch } from '@/modules/collection/types'
import { OrderResponse } from '@/modules/order/types'
import {
  CommentDTO,
  QuoteActivity,
  QuoteDTO,
  QuoteId,
  QuoteItemUpdate,
  QuoteNewCount,
  QuoteRequest,
  QuoteRequestDraft,
  QuoteRequestItemPostDTO,
  QuoteRequestPatch,
  QuoteRequestPostDTO,
  QuoteSort,
  QuoteUpdate,
  RequestItemUpdate,
  ShortQuote
} from '@/modules/quote/types'
import { PageableResponse, RejectionDTO } from '@/types'

export default {
  getQuotes: (
    page: number,
    size: number,
    sort?: QuoteSort,
    filter?: string,
    keyword?: string
  ): Promise<Response<PageableResponse<ShortQuote>>> => {
    const config: Request = {
      params: {
        page: page - 1,
        size,
        sort,
        filter,
        keyword
      }
    }
    return get<PageableResponse<ShortQuote>>('/quotes', config)
  },
  getQuote: (id: string, isGuest = false): Promise<Response<QuoteDTO>> => {
    return get<QuoteDTO>((isGuest ? '/guest' : '') + '/quotes/' + id)
  },
  getQuoteUpdateStreamPath: (id: string, isGuest = false): string => {
    return (isGuest ? '/guest' : '') + '/quotes-sse/' + id
  },
  printQuote: (id: string, isGuest = false): Promise<Response<Blob>> => {
    return get<Blob>((isGuest ? '/guest' : '') + '/quotes/' + id + '/print', {
      responseType: 'blob'
    })
  },
  rejectQuote: (id: string, comment: RejectionDTO, isGuest = false): Promise<Response<string>> => {
    return post<string, RejectionDTO>(
      (isGuest ? '/guest' : '') + '/quotes/' + id + '/reject',
      comment
    )
  },
  archiveQuote: (id: string): Promise<Response<string>> => {
    return post<string, QuoteId>('/quotes/actions/archive', {
      id: id
    })
  },
  dearchiveQuote: (id: string): Promise<Response<string>> => {
    return post<string, QuoteId>('/quotes/actions/dearchive', {
      id: id
    })
  },
  orderPlatformQuote: (quoteId: string): Promise<Response<OrderResponse>> => {
    return post<OrderResponse, unknown>('/order/actions/send-platform-quote?quoteId=' + quoteId)
  },
  createQuoteRequest: (quoteRequest: QuoteRequestPostDTO): Promise<Response<string>> => {
    return post<string, QuoteRequestPostDTO>('/quote-requests/', quoteRequest)
  },
  getQuoteRequest: (id: string): Promise<Response<QuoteRequest>> => {
    return get<QuoteRequest>('/quote-requests/' + id)
  },
  deleteQuoteRequest: (id: string): Promise<Response<string>> => {
    return del<string>('/quote-requests/' + id)
  },
  updateQuoteRequest: (
    requestId: string,
    requestPatch: QuoteRequestPatch
  ): Promise<Response<string>> => {
    return patch<string, CollectionPatch>('/quote-requests/' + requestId, requestPatch)
  },
  addToQuoteRequest: (
    requestId: string,
    itemPostDTO: QuoteRequestItemPostDTO
  ): Promise<Response<string>> => {
    return post<string, QuoteRequestItemPostDTO>('/quote-requests/' + requestId, itemPostDTO)
  },
  updateQuoteRequestItem: (
    requestId: string,
    itemId: string,
    update: RequestItemUpdate
  ): Promise<Response<string>> => {
    return patch<string, RequestItemUpdate>(`/quote-requests/${requestId}/items/${itemId}`, update)
  },
  deleteRequestItem: (requestId: string, itemId: string): Promise<Response<string>> => {
    return del<string>('/quote-requests/' + requestId + '/items/' + itemId)
  },
  sendQuoteRequest: (requestId: string): Promise<Response<string>> => {
    return post<string, unknown>('/quote-requests/' + requestId + '/send')
  },
  getDraftQuoteRequestForProduct: (productId: string): Promise<Response<QuoteRequestDraft[]>> => {
    return get<QuoteRequestDraft[]>('/quote-requests/products/' + productId)
  },
  isProductInDraftQuoteRequest: (productId: string): Promise<Response<boolean>> => {
    return get<boolean>('/quote-requests/' + productId + '/hasProduct')
  },
  updateQuoteItem: (
    quoteId: string,
    itemId: string,
    update: QuoteItemUpdate,
    isGuest = false
  ): Promise<Response<string>> => {
    return patch<string, QuoteItemUpdate>(
      (isGuest ? '/guest' : '') + '/quotes/' + quoteId + '/items/' + itemId,
      update
    )
  },
  updateQuote: (
    quoteId: string,
    update: QuoteUpdate,
    isGuest = false
  ): Promise<Response<string>> => {
    return patch<string, QuoteUpdate>((isGuest ? '/guest' : '') + '/quotes/' + quoteId, update)
  },
  countNewQuotes: (): Promise<Response<QuoteNewCount>> => {
    return get<QuoteNewCount>('/quotes/new-count')
  },
  getQuoteFilters: (filter?: string, keyword?: string): Promise<Response<FilterIdentifier[]>> => {
    const config: Request = {
      params: {
        filter,
        keyword
      }
    }
    return get<FilterIdentifier[]>('/quotes/filters', config)
  },
  getActivityEntries: (quoteId: string, isGuest = false): Promise<Response<QuoteActivity[]>> => {
    return get<QuoteActivity[]>((isGuest ? '/guest' : '') + '/quotes/' + quoteId + '/activity')
  },
  getActivityEntriesUpdateStreamPath: (quoteId: string, isGuest = false): string => {
    return (isGuest ? '/guest' : '') + '/quotes/' + quoteId + '/activity-sse'
  },
  addActivityEntry: (
    quoteId: string,
    commentDto: CommentDTO,
    isGuest = false
  ): Promise<Response<string>> => {
    return post<string, CommentDTO>(
      (isGuest ? '/guest' : '') + '/quotes/' + quoteId + '/activity',
      commentDto
    )
  },
  orderGuestQuote: (id: string): Promise<Response<string>> => {
    return post<string, Record<string, unknown>>(
      '/guest/quote/order',
      {},
      { params: { quoteId: id } }
    )
  },
  loadDatasheet: (id: string, itemId: string, isGuest = false): Promise<Response<Blob>> => {
    return get<Blob>(
      (isGuest ? '/guest' : '') + '/quotes/' + id + '/items/' + itemId + '/datasheet',
      {
        responseType: 'blob'
      }
    )
  }
}
