import { del, get, patch, post, Response } from '@/api'
import { Address, AddressType } from '@/modules/address/types'
import { CheckoutAddressValidation } from '@/modules/order/types'

export default {
  getAddresses: (): Promise<Response<Address[]>> => {
    return get<Address[]>('/addresses')
  },
  createAddress: (address: Address): Promise<Response<Address>> => {
    return post<Address, Address>('/addresses', address)
  },
  updateAddress: (address: Address): Promise<Response<string>> => {
    return patch<string, Address>('/addresses/' + address.id, address)
  },
  deleteAddress: (id: string): Promise<Response<string>> => {
    return del<string>('/addresses/' + id)
  },
  validateCheckoutAddress: (
    type: AddressType,
    address?: Address
  ): Promise<Response<CheckoutAddressValidation>> => {
    return post<CheckoutAddressValidation, Address>('/addresses/validate/' + type, address)
  }
}
