<template>
  <v-card class="pa-2 mb-1">
    <v-row no-gutters style="min-height: 96px">
      <v-col cols="12" md="auto" class="d-flex align-center">
        <product-image :url="promotionalProduct.product.imageUrl" :size="180" />
      </v-col>

      <v-col cols="12" md="" class="d-flex flex-column mr-md-4 mt-1 mt-md-0">
        <router-link
          :to="{
            name: 'Product',
            params: { id: promotionalProduct.product.productId }
          }"
          class="text-h5 text-primary text-decoration-none"
          :class="[{ 'text-truncate': $vuetify.display.mdAndUp }]"
        >
          {{ promotionalProduct.product.title }}
        </router-link>
        <product-identifiers
          class="mt-1"
          :article-number="promotionalProduct.product.articleNumber"
          :product-id="promotionalProduct.product.productId"
        />
        <sf-text class="my-1 text-justify">
          {{ promotionalProduct.marketingText }}
        </sf-text>
        <div class="text-medium-emphasis text-decoration-line-through">
          {{ formatMoney(promotionalProduct.oldPrice, true) }}
        </div>
        <div class="d-flex flex-wrap align-center">
          <div
            v-if="promotionalProduct.product.price"
            class="text-h4 font-weight-bold text-primary mr-2"
          >
            {{ formatMoney(promotionalProduct.product.price.resellPrice, true) }}
          </div>
          <v-chip
            v-if="promotionalProduct.product.price && promotionalProduct.oldPrice"
            label
            color="primary"
            variant="flat"
            class="mr-3"
          >
            <span class="text-body-1 font-weight-bold">
              {{
                formatDiscount(
                  promotionalProduct.oldPrice.value,
                  promotionalProduct.product.price.resellPrice.value
                )
              }}
            </span>
          </v-chip>
          <span v-if="promotionalProduct.endDate">
            {{ $t('validDate') + ' ' + formatDate(promotionalProduct.endDate, false) }}
          </span>
        </div>
      </v-col>

      <v-col cols="12" md="auto" class="d-flex flex-row mt-2 mt-md-0">
        <cart-button
          v-if="promotionalProduct.product.price"
          :is-icon="true"
          :is-request="promotionalProduct.product.price.resellPrice.value <= 0"
          :product-id="promotionalProduct.product.productId"
          :quantity="1"
          :supplier-row-id="promotionalProduct.product.price.supplierRowId"
          :on-click-event="addToCartEvent"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import CartButton from '@/components/button/CartButton.vue'
import ProductImage from '@/components/ProductImage.vue'
import SfText from '@/components/text/SfText.vue'
import ProductIdentifiers from '@/modules/product/components/ProductIdentifiers.vue'
import { formatMoney, formatDiscount } from '@/modules/product/helpers'
import PromotionalProductsEvent from '@/modules/tracking/events/promotionalProducts'
import { formatDate } from '@/utils/timeFormatHelpers'
import { defineComponent, PropType } from 'vue'
import { PromotionalProduct } from '@/modules/promotionalProducts/types'

export default defineComponent({
  name: 'ProductRow',
  components: {
    ProductImage,
    ProductIdentifiers,
    CartButton,
    SfText
  },
  props: {
    promotionalProduct: {
      type: Object as PropType<PromotionalProduct>,
      required: true
    }
  },
  setup() {
    const addToCartEvent = PromotionalProductsEvent.AddToCart

    return {
      formatDate,
      formatDiscount,
      formatMoney,
      addToCartEvent
    }
  }
})
</script>
