import {
  Selection,
  ContactTitle,
  AcademicTitle,
  Department,
  User,
  ShortUser
} from '@/modules/user/types'
import i18n from '@/plugins/i18n'
import { MobexPaymentMethod } from '@/modules/order/types'
import { InternalItem, Language } from '@/types'

const prepareForForm = (user: User): User => {
  user.phoneMobile = user.phoneMobile || ''
  user.phoneOffice = user.phoneOffice || ''
  user.fax = user.fax || ''
  user.position = user.position || ''

  return user
}

const getContactTitles = (): Selection[] => {
  const titles: Selection[] = []
  Object.keys(ContactTitle)
    .filter((x) => isNaN(parseInt(x)))
    .forEach((title, index) => {
      titles.push({
        value: index,
        title: title,
        translation: i18n.global.t('contactTitles.' + title.toLowerCase()).toString()
      })
    })
  return titles
}

const getEmailDomain = (mail: string) => {
  return mail.substring(mail.indexOf('@') + 1)
}

const getAcademicTitles = (): Selection[] => {
  const titles: Selection[] = []
  Object.keys(AcademicTitle)
    .filter((x) => isNaN(parseInt(x)))
    .forEach((title, index) => {
      titles.push({
        value: index,
        title: title,
        translation: i18n.global.t('academicTitles.' + title).toString()
      })
    })
  return titles
}

const getDepartmentTitles = (): Selection[] => {
  const titles: Selection[] = []
  Object.keys(Department)
    .filter((x) => isNaN(parseInt(x)))
    .forEach((department, index) => {
      if (department != Department.RegistrationPending) {
        titles.push({
          value: index,
          title: department,
          translation: i18n.global.t('departments.' + department).toString()
        })
      }
    })
  return titles.sort((a, b) =>
    a.translation > b.translation ? 1 : b.translation > a.translation ? -1 : 0
  )
}

const getSortedDepartments = (): Selection[] => {
  const sortedDepartements = getDepartmentTitles()
  sortedDepartements.push({
    value: sortedDepartements.length,
    title: Department.RegistrationPending,
    translation: i18n.global.t('departments.' + Department.RegistrationPending).toString()
  })
  return sortedDepartements
}

const getLanguages = (): Selection[] => {
  const languages: Selection[] = []
  Object.keys(Language)
    .filter((x) => isNaN(parseInt(x)))
    .forEach((lang, index) => {
      languages.push({
        value: index,
        title: lang,
        translation: i18n.global.t('languages.' + lang).toString()
      })
    })
  return languages
}

const getPaymentMethods = (): Selection[] => {
  const paymentMethods: Selection[] = []
  Object.keys(MobexPaymentMethod)
    .filter((x) => isNaN(parseInt(x)))
    .forEach((method, index) => {
      paymentMethods.push({
        value: index,
        title: method,
        translation: i18n.global.t('mobexPaymentMethod.' + method).toString()
      })
    })
  return paymentMethods
}

const customShortUserFilter = (_: string, query: string, item?: InternalItem<ShortUser>) => {
  return (
    item?.raw.firstName?.toLowerCase().includes(query) ||
    item?.raw.lastName?.toLowerCase().includes(query) ||
    false
  )
}

export {
  getContactTitles,
  getAcademicTitles,
  getDepartmentTitles,
  getSortedDepartments,
  getLanguages,
  getPaymentMethods,
  prepareForForm,
  customShortUserFilter,
  getEmailDomain
}
