import { handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import api from '@/modules/collection/api'
import { EmailPreview } from '@/types'
import { SendCollectionPostDTO } from '@/modules/collection/types'
import helpers from '@/modules/message/helpers'

interface CollectionEmailState {
  item: EmailPreview | undefined
  loading: boolean
  sending: boolean
}

const initialState = (): CollectionEmailState => {
  return {
    item: undefined,
    loading: false,
    sending: false
  }
}

const useCollection = defineStore('collectionEmail', {
  state: () => initialState(),
  actions: {
    async getEmailPreview(id: string) {
      this.loading = true

      return api
        .getEmailPreview(id)
        .then(({ data }) => {
          this.item = data
        })
        .catch(handleApiError)
        .finally(() => (this.loading = false))
    },
    async sendEmail(id: string, dto: SendCollectionPostDTO) {
      this.sending = true

      return api
        .sendEmail(id, dto)
        .then(() => helpers.reportSuccess('collection.emailSent'))
        .catch(handleApiError)
        .finally(() => (this.sending = false))
    }
  }
})

export default useCollection
