import { Event } from '@/modules/tracking/types'

export interface UserEvent {
  orderPermission(): Event
  adminPermission(): Event
  subsidiaryPermission(): Event
  changedPassword(): Event
  changedAvatar(): Event
  deleteEmployee: Event
  changedLanguage(language: string): Event
  changedUserData: UserData
}

interface UserData {
  title: Event
  academicTitle: Event
  name: Event
  lastName: Event
  department: Event
  position: Event
  phoneMobile: Event
  phoneOffice: Event
  mail: Event
  fax: Event
}

export class OtherUserEvent extends Event {
  constructor(action: string, label?: string) {
    super('Mitarbeiter', action, label ?? '')
  }
}
export class MyUserEvent extends Event {
  constructor(action: string, label?: string) {
    super('Mein Profil', action, label ?? '')
  }
}

const personalDataAction = 'Persönliche Daten angepasst'

export const CompanyUserEvent: UserEvent = {
  orderPermission: function (): Event {
    return new OtherUserEvent('Bestellrecht angepasst')
  },
  adminPermission: function (): Event {
    return new OtherUserEvent('Adminrecht angepasst')
  },
  subsidiaryPermission: function (): Event {
    return new OtherUserEvent('Impersonierrecht angepasst')
  },
  changedPassword: function (): Event {
    return new OtherUserEvent('Passwort geändert')
  },
  changedAvatar: function (): Event {
    return new OtherUserEvent('Avatar geändert')
  },
  changedUserData: {
    title: new OtherUserEvent(personalDataAction, 'Anrede'),
    academicTitle: new OtherUserEvent(personalDataAction, 'Titel'),
    name: new OtherUserEvent(personalDataAction, 'Vorname'),
    lastName: new OtherUserEvent(personalDataAction, 'Nachname'),
    department: new OtherUserEvent(personalDataAction, 'Abteilung'),
    position: new OtherUserEvent(personalDataAction, 'Position'),
    phoneMobile: new OtherUserEvent(personalDataAction, 'Nummer Mobil'),
    phoneOffice: new OtherUserEvent(personalDataAction, 'Nummer Büro'),
    mail: new OtherUserEvent(personalDataAction, 'E-Mail'),
    fax: new OtherUserEvent(personalDataAction, 'Fax')
  },
  changedLanguage: (language: string) => new OtherUserEvent('Sprache geändert', language),
  deleteEmployee: new OtherUserEvent('Mitarbeiter gelöscht')
}

export const MyProfileEvent: UserEvent = {
  orderPermission: function (): Event {
    return new MyUserEvent('Bestellrecht angepasst')
  },
  adminPermission: function (): Event {
    return new MyUserEvent('Adminrecht angepasst')
  },
  subsidiaryPermission: function (): Event {
    return new MyUserEvent('Impersonierrecht angepasst')
  },
  changedPassword: function (): Event {
    return new MyUserEvent('Passwort geändert')
  },
  changedAvatar: function (): Event {
    return new MyUserEvent('Avatar geändert')
  },
  changedUserData: {
    title: new MyUserEvent(personalDataAction, 'Anrede'),
    academicTitle: new MyUserEvent(personalDataAction, 'Titel'),
    name: new MyUserEvent(personalDataAction, 'Vorname'),
    lastName: new MyUserEvent(personalDataAction, 'Nachname'),
    department: new MyUserEvent(personalDataAction, 'Abteilung'),
    position: new MyUserEvent(personalDataAction, 'Position'),
    phoneMobile: new MyUserEvent(personalDataAction, 'Nummer Mobil'),
    phoneOffice: new MyUserEvent(personalDataAction, 'Nummer Büro'),
    mail: new MyUserEvent(personalDataAction, 'E-Mail'),
    fax: new MyUserEvent(personalDataAction, 'Fax')
  },
  changedLanguage: (language: string) => new MyUserEvent('Sprache geändert', language),
  deleteEmployee: new MyUserEvent('Mitarbeiter gelöscht')
}
