import { get, post, Response } from '@/api'
import { MediumProduct } from '@/modules/product/types'
import {
  ModelConfigurationResource,
  ModelGroupResource,
  ProductClass,
  ProductInformationPostDTO
} from '@/modules/ctoFinder/types'

export default {
  getProductClasses: (): Promise<Response<ProductClass[]>> => {
    return get<ProductClass[]>('/cto/productclasses')
  },
  getModelGroups: (productClassId: string): Promise<Response<ModelGroupResource[]>> => {
    return get<ModelGroupResource[]>('/cto/modelgroup/' + productClassId)
  },
  getModelConfiguration: (
    manufacturerMaterialNo: string
  ): Promise<Response<ModelConfigurationResource>> => {
    return get<ModelConfigurationResource>('/cto/productclasses/' + manufacturerMaterialNo)
  },
  getProductInformation: (dto: ProductInformationPostDTO): Promise<Response<MediumProduct>> => {
    return post<MediumProduct, ProductInformationPostDTO>('/cto/productinformation', dto)
  }
}
