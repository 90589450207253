<template>
  <div class="pw-cto-comp-group">
    <sf-sub-heading ref="title">
      {{ modelComponentGroup.description }}
    </sf-sub-heading>
    <v-card
      v-for="modelComponent in modelComponentGroup.modelComponents.slice(
        0,
        showAll ? modelComponentGroup.modelComponents.length : 3
      )"
      :key="modelComponent.componentId"
      variant="outlined"
      color="grey-lighten-2"
      :class="[{ 'border--primary': modelComponent.selected }, 'my-2 pa-2']"
      v-on="
        !modelComponent.selected
          ? {
              click: () =>
                $emit(
                  'selectModelComponent',
                  modelComponentGroup.groupId,
                  modelComponent.componentId
                )
            }
          : {}
      "
    >
      <sf-text>{{ modelComponent.description }}</sf-text>
    </v-card>
    <v-card
      v-if="!showAll"
      variant="outlined"
      class="text-center my-2 pa-2 cursor-pointer"
      color="grey-lighten-2"
      v-on="{ click: () => (showAll = true) }"
    >
      <sf-color-text color="primary">
        {{ $t('moreUppercase') + $t('ellipsis') }}
      </sf-color-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import SfColorText from '@/components/text/SfColorText.vue'
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { defineComponent, PropType, ref } from 'vue'
import { ModelComponentGroup } from '@/modules/ctoFinder/types'

export default defineComponent({
  name: 'ModelComponentGroup',
  components: { SfText, SfSubHeading, SfColorText },
  props: {
    modelComponentGroup: {
      type: Object as PropType<ModelComponentGroup>,
      required: true
    }
  },
  emits: ['selectModelComponent'],
  setup(props) {
    const showAll = ref(
      props.modelComponentGroup.description != 'Tastaturen' ||
        props.modelComponentGroup.modelComponents.length <= 3
    )

    return {
      showAll
    }
  }
})
</script>
