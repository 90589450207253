import { Event } from '@/modules/tracking/types'

export class PunchoutEvent extends Event {
  constructor(action: string) {
    super('Punchout', action, '')
  }
  static OpenBackToSystem = new PunchoutEvent('Checkout Dialog geöffnet')

  static ClickedPunchoutQuoteFormSubmit = new PunchoutEvent('Angebot zurückgegeben')

  static ClickedPunchoutFormSubmit = new PunchoutEvent('Warenkorb zurückgegeben')
}
