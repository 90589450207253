import { handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import {
  DefaultOrderLimit,
  DefaultOrderLimitPatch,
  OrderLimitUser,
  OrderLimitUserPatch
} from '@/modules/orderLimits/types'
import api from '@/modules/orderLimits/api'
import { ShortUser } from '@/modules/user/types'
import usePortalSettings from '@/modules/portalSettings/usePortalSettings'

export interface OrderLimitsState {
  defaultOrderLimit: DefaultOrderLimit
  orderLimitUsers: OrderLimitUser[]
  approvers: ShortUser[]
  loading: boolean
}

const initialState = (): OrderLimitsState => {
  return {
    defaultOrderLimit: {
      activated: false,
      defaultApprovers: []
    },
    orderLimitUsers: [],
    approvers: [],
    loading: false
  }
}

const useOrderLimits = defineStore('orderLimits', {
  state: () => initialState(),
  actions: {
    async loadOrderLimits() {
      this.loading = true

      const loadDefaultOrderLimit = api.getDefaultOrderLimit().then(({ data }) => {
        this.defaultOrderLimit = data
      })

      let loadOrderLimitUsers
      if (!usePortalSettings().portalSettings.hideColleaguesPermissions) {
        loadOrderLimitUsers = api.getOrderLimitUsers().then(({ data }) => {
          this.orderLimitUsers = data
        })
      }

      return Promise.all([loadDefaultOrderLimit, loadOrderLimitUsers])
        .catch(handleApiError)
        .finally(() => {
          this.loading = false
        })
    },
    async getApprovers() {
      return api
        .getApprovers()
        .then(({ data }) => {
          this.approvers = data
        })
        .catch(handleApiError)
    },
    async updateDefaultOrderLimit(dto: DefaultOrderLimitPatch) {
      if (dto.approverIds) {
        const approvers: ShortUser[] = []
        dto.approverIds.forEach((approverId) => {
          const approver = this.approvers.find((el) => el.id === approverId)
          if (approver) {
            approvers.push(approver)
          }
        })
        this.defaultOrderLimit.defaultApprovers = approvers
      }

      return api.updateDefaultOrderLimit(dto).catch(handleApiError)
    },
    async updateOrderLimitUser(id: string, dto: OrderLimitUserPatch) {
      const item = this.orderLimitUsers.find((el) => el.id === id)
      if (item) {
        item.submitOrder = dto.submitOrder
        item.orderLimitType = dto.orderLimitType
        item.userOrderLimit = dto.userOrderLimit
        const approvers: ShortUser[] = []
        dto.approverIds.forEach((approverId) => {
          const approver = this.approvers.find((el) => el.id === approverId)
          if (approver) {
            approvers.push(approver)
          }
        })
        item.approvers = approvers
      }

      return api.updateOrderLimitUser(id, dto).catch(handleApiError)
    }
  },
  getters: {
    getOrderLimitUser: (state) => (id: string) => {
      return state.orderLimitUsers.find((el) => el.id === id)
    }
  }
})

export default useOrderLimits
