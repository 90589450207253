<template>
  <div class="d-flex flex-row text-grey">
    <v-icon color="grey align-self-center" :icon="mdiInformationOutline" />
    <div class="d-flex flex-column ml-1 font-italic">
      <span>{{ $t('productStockInfo.unavailableMessage') }}</span>
      <span>
        {{
          stock.deliveryDate
            ? $t('productStockInfo.nextDelivery', [stock.deliveryDate])
            : $t('productStockInfo.noNextDelivery')
        }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { SimpleStock, StockStatus } from '@/modules/product/types'
import { mdiInformationOutline } from '@mdi/js'
import { PropType, defineComponent } from 'vue'

export default defineComponent({
  name: 'NotAvailableHint',
  props: {
    stock: {
      type: Object as PropType<SimpleStock>,
      required: true
    }
  },
  setup() {
    return {
      StockStatus,
      mdiInformationOutline
    }
  }
})
</script>
