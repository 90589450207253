import { concat } from 'lodash-es'
import api from '@/modules/startPage/api'
import { ApiError, handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import { PAGE_SIZE } from '@/types'
import { StartPage, StartPageType } from '@/modules/startPage/types'

interface StartPageState {
  startPage: StartPage
  loading: boolean
  loadingProducts: boolean
}

const initialState = (): StartPageState => {
  return {
    startPage: {
      startPageType: StartPageType.individual,
      featuredProducts: []
    },
    loading: false,
    loadingProducts: false
  }
}

const useStartPage = defineStore('startPage', {
  state: () => initialState(),
  actions: {
    async loadStartPage(page: number) {
      this.loading = true

      return api
        .loadStartPage(page, PAGE_SIZE)
        .then(({ data }) => {
          this.startPage = data
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            appearance: 'FULLPAGE',
            customMessageKey: 'error.startPage'
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    async loadStartPageNextPage(page: number) {
      this.loadingProducts = true

      return api
        .loadStartPage(page, PAGE_SIZE)
        .then(({ data }) => {
          this.startPage.featuredProducts = concat(
            this.startPage.featuredProducts,
            data.featuredProducts
          )
        })
        .catch(handleApiError)
        .finally(() => {
          this.loadingProducts = false
        })
    }
  }
})

export default useStartPage
