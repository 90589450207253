<template>
  <v-card class="pa-2">
    <v-row dense>
      <v-col cols="auto">
        <product-image :url="product.imageUrl" :size="60" />
      </v-col>
      <v-col cols class="d-flex align-center">
        <sf-sub-heading>
          <router-link
            class="text-primary text-decoration-none"
            :to="{
              name: 'Product',
              params: { id: product.productId }
            }"
            @click="trackGoToProductPage"
          >
            {{ product.title }}
          </router-link>
        </sf-sub-heading>
      </v-col>
      <v-col cols="auto">
        <quote-button
          v-if="portalSettings.useQuotes"
          :product-id="product.productId"
          :add-to-quote-event="ProductSearchEvent.AddProductToExistingQuote.Detail"
          :create-quote-event="ProductSearchEvent.AddProductToNewQuote.Detail"
        />
        <comparison-button
          :product="product"
          :add-to-comparison-event="
            ProductSearchEvent.Comparison.AddToComparison(product.productId)
          "
          :remove-from-comparison-event="
            ProductSearchEvent.Comparison.RemoveFromComparison(product.productId)
          "
        />
        <collection-button
          :product-id="product.productId"
          :add-to-collection-event="ProductSearchEvent.AddToCollection.Detail"
          :create-collection-event="ProductSearchEvent.CreateCollection.Detail"
        />
      </v-col>
    </v-row>
    <v-divider class="my-1" />
    <v-row v-if="product.suppliers.some((e) => e.scales.length > 0)" dense>
      <v-col>
        <product-graduation :suppliers="product.suppliers" />
      </v-col>
    </v-row>
    <list-content ref="productSources" :extra-offset="16">
      <products-list-detail-sources :sources="product.suppliers" :product-id="product.productId" />
    </list-content>
  </v-card>
</template>

<script lang="ts">
import ListContent from '@/components/list/ListContent.vue'
import ProductImage from '@/components/ProductImage.vue'
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import { scrollToTop } from '@/helpers'
import ComparisonButton from '@/modules/product/components/ComparisonButton.vue'
import CollectionButton from '@/modules/collection/components/CollectionButton.vue'
import { PortalSettings } from '@/modules/portalSettings/types'
import ProductGraduation from '@/modules/product/components/ProductGraduation.vue'
import ProductsListDetailSources from '@/modules/product/components/ProductsListDetailSources.vue'
import { MediumProduct } from '@/modules/product/types'
import ProductSearchEvent from '@/modules/tracking/events/search'
import { trackEvent } from '@/modules/tracking/useTracking'
import { ComponentPublicInstance, defineComponent, PropType, ref, watch } from 'vue'
import ProductPageEvent from '@/modules/tracking/events/productPage'
import QuoteButton from '@/modules/quote/components/QuoteButton.vue'

export default defineComponent({
  name: 'ProductsListDetail',
  components: {
    QuoteButton,
    ComparisonButton,
    CollectionButton,
    ListContent,
    ProductGraduation,
    ProductImage,
    ProductsListDetailSources,
    SfSubHeading
  },
  props: {
    product: {
      type: Object as PropType<MediumProduct>,
      required: true
    },
    portalSettings: {
      type: Object as PropType<PortalSettings>,
      required: true
    }
  },
  setup(props) {
    const productSources = ref<ComponentPublicInstance<typeof ProductsListDetailSources>>()

    const trackGoToProductPage = () => {
      trackEvent(ProductSearchEvent.GoToProductPage.Detail.withProduct(props.product.productId))
    }

    watch(
      () => props.product,
      () => {
        scrollToTop(productSources.value)
      }
    )

    return {
      productSources,
      ProductSearchEvent,
      ProductPageEvent,
      trackGoToProductPage
    }
  }
})
</script>
