<template>
  <v-card class="order-limit-warning mb-2">
    <v-card-item>
      <v-card-title>
        <sf-heading>{{ $t('orderLimits.orderApproval') }}</sf-heading>
      </v-card-title>
    </v-card-item>
    <v-card-text>
      <v-row dense>
        <v-col class="pb-0">
          <sf-alert-warning ref="hint" class="mb-0">
            {{
              noOrderRight
                ? $t('order.noOrderRightHint')
                : $t('order.orderLimitHint', [
                    formatMoney(costs, false, true),
                    formatMoney(orderLimitValue, false, true)
                  ])
            }}
          </sf-alert-warning>
        </v-col>
      </v-row>
      <v-row v-if="!hasApprovers" dense>
        <v-col>
          <sf-alert-warning ref="no-approver" class="mb-0">
            {{ $t('order.noApprover') }}
          </sf-alert-warning>
        </v-col>
      </v-row>
      <v-row v-if="hasApprovers" dense>
        <v-col class="pb-0">
          <sf-text>
            {{ $t('order.orderLimitApprovalHint') }}
          </sf-text>
          <v-row dense class="my-1">
            <v-col
              v-for="(approver, index) in approvers"
              :key="index"
              cols="auto"
              class="d-flex align-center mr-2"
            >
              <avatar-image class="mr-1" :size="40" :user="approver" />
              <sf-text>{{ getFullNameAndCompany(approver) }}</sf-text>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="hasApprovers" dense>
        <v-col class="mb-2">
          <v-textarea
            ref="comment-input"
            v-model="comment"
            :label="$t('common.comment')"
            rows="1"
            auto-grow
            hide-details
            density="compact"
            variant="underlined"
            color="primary"
            autocomplete="off"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-row dense>
    <v-col>
      <div class="d-flex flex-column flex-sm-row">
        <sf-large-button
          class="flex-grow-0 mr-0 mr-sm-2 mb-2 mb-sm-0 mt-2 mt-sm-0"
          :loading="loading"
          :disabled="!hasApprovers || disabled"
          @click="requestOrder()"
          >{{ $t('order.requestOrder') }}
        </sf-large-button>
        <div class="flex-grow-1 order-first order-sm-last">
          <slot />
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { getFullNameAndCompany } from '@/helpers'
import { ShortUser } from '@/modules/user/types'
import { computed, defineComponent, PropType, ref } from 'vue'
import SfAlertWarning from '@/components/alerts/SfAlertWarning.vue'
import AvatarImage from '@/components/AvatarImage.vue'
import SfLargeButton from '@/components/button/SfLargeButton.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { Money } from '@/modules/product/types'
import { formatMoney } from '@/modules/product/helpers'

export default defineComponent({
  name: 'OrderLimitWarning',
  components: { AvatarImage, SfLargeButton, SfHeading, SfText, SfAlertWarning },
  props: {
    approvers: {
      type: Array as PropType<ShortUser[]>,
      required: true
    },
    noOrderRight: {
      type: Boolean,
      required: true
    },
    costs: {
      type: Object as PropType<Money>,
      default: undefined
    },
    orderLimitValue: {
      type: Object as PropType<Money>,
      default: undefined
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  emits: ['requestOrder'],
  setup(props, { emit }) {
    const comment = ref('')
    const hasApprovers = computed(() => props.approvers.length !== 0)

    const requestOrder = () => {
      emit('requestOrder', comment.value)
    }

    return {
      comment,
      formatMoney,
      hasApprovers,
      requestOrder,
      getFullNameAndCompany
    }
  }
})
</script>
