import { get, Response } from '@/api'
import { PunchoutForm } from '@/modules/punchout/types'

export default {
  loadPunchoutCartForm: (supplierCartId?: string): Promise<Response<PunchoutForm>> => {
    return get<PunchoutForm>(
      '/order/actions/send-cart-eprocurement' +
        (supplierCartId ? `?supplierCartId=${supplierCartId}` : '')
    )
  },

  loadPunchoutQuoteForm: (quoteId: string): Promise<Response<PunchoutForm>> => {
    return get<PunchoutForm>('/order/actions/send-quote-eprocurement?quoteId=' + quoteId)
  }
}
