import { ProductSort } from '@/modules/product/types'

type FilterIdentifier = {
  name: string
  values: FilterValue[]
}

type FilterValue = {
  name: string
  term: string
  count?: number
}

type FilterSelection<T> = {
  sort: T
  filters: FilterIdentifier[]
  priceRangeFilter?: PriceRangeFilter
}

type ProductFilterSelection = FilterSelection<ProductSort> & {
  priceRangeFilter: PriceRangeFilter
  stockCountFilter: number | undefined
}

type PriceRangeFilter = {
  start?: number
  end?: number
}

const DEFAULT_FILTER_TERM = 'ARCNOTARCHIVED'
const PRICE_RANGE_FILTER_PREFIX = 'PRR'
const STOCK_COUNT_FILTER_PREFIX = 'STK'

export {
  FilterIdentifier,
  FilterValue,
  FilterSelection,
  ProductFilterSelection,
  PriceRangeFilter,
  DEFAULT_FILTER_TERM,
  PRICE_RANGE_FILTER_PREFIX,
  STOCK_COUNT_FILTER_PREFIX
}
