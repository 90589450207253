import { Timestamped } from '@/utils/timestampUtils'
import { Address } from '@/modules/address/types'
import { ContactData } from '@/modules/contactData/types'
import { ArticleNumber, Money, PackagingModel, SubscriptionModel } from '@/modules/product/types'
import { ShortUser } from '@/modules/user/types'

const ORDER_NUMBER_MAX_LENGTH = 250

type OrderSearchModel = {
  id: string
  initiatorFullName: string
  itemCount: number
  orderNumber: string
  lastUpdated: string
  orderStatus: OrderStatus
}

type Order = {
  id: string
  initiatorFullName: string
  itemCount: number
  sum: Money
  orderNumber: string
  distributorNames: string[]
  lastUpdated: string
  sentDate?: string
  orderStatus: OrderStatus
  isArchived: boolean
}

type OrderDocument = {
  id: string
  date: string
  transactionType: TransactionType
  status: OrderStatus
  documentNumber?: string
  hasPdfDocument: boolean
}

enum TransactionType {
  ORDER = 'ORDER',
  PAYMENT = 'PAYMENT',
  ORDERSTATUS = 'ORDERSTATUS',
  DELIVERY = 'DELIVERY',
  INVOICE = 'INVOICE'
}

type OrderDocuments = {
  orderId: string
  transactions: OrderDocument[]
}

type DetailedOrder = Timestamped & {
  id: string
  initiator: ShortUser
  itemCount: number
  orderSumInformation: OrderSumInformation
  orderNumber: string
  customerOrderId?: string
  distributorNames: string[]
  lastUpdated: string
  sentDate?: string
  orderStatus: OrderStatus
  customerNumber: string
  deliveryAddress?: Address
  billingAddress: Address
  deliveryAddressSelection?: AddressSelection
  billingAddressSelection?: AddressSelection
  billingContactData: ContactData
  deliveryContactData?: ContactData
  items: OrderItem[]
  shippingCost: Money
  orderLimitExceedance?: OrderLimitExceedance
  orderLimitValue?: Money
  orderLimitApprovers?: ShortUser[]
  quantitySurcharge?: Money
  isArchived: boolean
  orderComment?: string
  approvalRequest?: ApprovalRequest
  hasPartialShipment: boolean
}

type AddressSelection = {
  availableAddresses: Address[]
  canAdd: boolean
}

type OrderPreviewColumns = {
  ARRIVED: OrderPreviewColumn
  PROCESSING: OrderPreviewColumn
  ADVISED: OrderPreviewColumn
  INVOICED: OrderPreviewColumn
  ERROR: OrderPreviewColumn
}

type OrderPreviewColumnsKey = keyof OrderPreviewColumns

type OrderPreview = {
  id: string
  initiatorUser: ShortUser
  orderSumInformation: OrderSumInformation
  distributorNames: string[]
  orderNumber: string
  orderStatus: OrderStatus
  items: OrderItem[]
  shippingCost: Money
  sentDate?: string
}

type OrderPreviewColumn = {
  orderStatus: OrderStatus
  orderPreviews: OrderPreview[]
  count: number
  pages: number
  loading: boolean
  loadingNextPage: boolean
}

type OrderSumInformation = {
  netSum: Money
  grossSum: Money
  totalTaxes: TotalTaxInfo[]
  netDiscount?: Money
  netSumWithoutDiscount: Money
}

type TotalTaxInfo = {
  vatPercent: number
  vatSum: Money
}

type OrderItem = {
  id: string
  quantity: number
  supplierName: string
  productId?: number
  productTitle: string
  productImageUrl?: string
  price?: Money
  articleNumber?: ArticleNumber
  subscriptionModel?: SubscriptionModel
  packagingModel?: PackagingModel
  comment: Comment
  sellerComment: Comment
  serialNumbers: SerialNumber[]
  deliveryInfo?: DeliveryInfo
}

type Comment = {
  value?: string
  maxLength: number
}

type UpdateOrderPatchDTO = {
  deliveryAddress?: Address
  billingAddress?: Address
  billingContactData?: ContactData
  deliveryContactData?: ContactData
  isArchived?: boolean
  hasPartialShipment?: boolean
  comment?: string
  orderNumber?: string
  customerOrderId?: string
  timestamp: string
}

type UpdateOrderItemDTO = {
  quantity?: number
  comment?: string
  sellerComment?: string
  timestamp?: string
}

type Checkout = {
  orderLimitExceedance: OrderLimitExceedance
  orderLimitApprovers: ShortUser[]
  orderLimitValue?: Money
  orderNumber?: string
  customerNumber: string
  deliveryAddress: CheckoutAddress
  billingAddress: CheckoutAddress
}

enum OrderLimitExceedance {
  KEPT = 'KEPT',
  NO_ORDER_RIGHT = 'NO_ORDER_RIGHT',
  EXCEEDED = 'EXCEEDED'
}

type CheckoutAddress = AddressSelection & {
  default?: Address
}

type CheckoutAddressValidation = {
  valid: boolean
  error?: string
}

type PreparedOrder = {
  orderNumber?: string
  customerOrderId?: string
  billingAddress: Address
  deliveryAddress: Address
  orderComment?: string
  usePartialShipment?: boolean
  billingContactData?: ContactData
  deliveryContactData?: ContactData
}

type OrderPostDTO = PreparedOrder & {
  timestamp: string
}

type SerialNumber = {
  type?: string
  value: string
}

type DeliveryInfo = {
  carrier: string
  trackingLinks: TrackingLink[]
}

type TrackingLink = {
  packageId: string
  url?: string
}

type RequestOrderPostDTO = {
  commentToApprovers?: string
}

type ValidateOrderNumberDTO = {
  orderNumber: string
}

type ProposeOrderPostDTO = OrderPostDTO & RequestOrderPostDTO

type OrderResponse = {
  id: string
}

type ApprovalRequest = {
  createdDate: string
  processedDate?: string
  assignees: ShortUser[]
  processor?: ShortUser
  processorComment?: string
  requestComment?: string
  status: ApprovalRequestStatus
}

enum ApprovalRequestStatus {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected'
}

enum OrderStatus {
  ADVISED = 'ADVISED',
  ARRIVED = 'ARRIVED',
  ARRIVED_WARNING = 'ARRIVED_WARNING',
  BLOCKED = 'BLOCKED',
  BLOCKED_BYLIMIT = 'BLOCKED_BYLIMIT',
  CANCELED = 'CANCELED',
  ERROR = 'ERROR',
  INVOICED = 'INVOICED',
  ORDERED_ELSEWHERE = 'ORDERED_ELSEWHERE',
  PARTIALLY_ADVISED = 'PARTIALLY_ADVISED',
  PARTIALLY_INVOICED = 'PARTIALLY_INVOICED',
  PARTIALLY_PROCESSING = 'PARTIALLY_PROCESSING',
  PAYMENT_AWAITING = 'PAYMENT_AWAITING',
  PAYMENT_SUCCESSFUL = 'PAYMENT_SUCCESSFUL',
  PORTAL_APPROVAL_DENIED = 'PORTAL_APPROVAL_DENIED',
  PREPARING = 'PREPARING',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED',
  SENDING_ERROR = 'SENDING_ERROR',
  SENT = 'SENT',
  TRANSMITTING = 'TRANSMITTING'
}

enum OrderSortOrder {
  SENT_DESC = 'SENT_DESC',
  SENT_ASC = 'SENT_ASC',
  LAST_MODIFIED = 'LAST_MODIFIED',
  SUPPLIER = 'SUPPLIER',
  BUYER = 'BUYER',
  ID = 'ID',
  CREATED_DESC = 'CREATED_DESC',
  STATUS_DESC = 'STATUS_DESC',
  STATUS_ASC = 'STATUS_ASC'
}

enum SortDirection {
  ASC,
  DESC,
  NONE
}

enum MobexPaymentMethod {
  BILL,
  MOBEX360
}

enum OrdersView {
  LIST = 'LIST',
  GRID = 'GRID',
  APPROVALS = 'APPROVALS'
}

enum AddToCartSuccess {
  SUCCESS = 'SUCCESS',
  PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
  FAILURE = 'FAILURE'
}

export {
  AddressSelection,
  AddToCartSuccess,
  ApprovalRequest,
  ApprovalRequestStatus,
  Checkout,
  CheckoutAddress,
  CheckoutAddressValidation,
  DeliveryInfo,
  DetailedOrder,
  MobexPaymentMethod,
  ORDER_NUMBER_MAX_LENGTH,
  Order,
  OrderDocument,
  OrderDocuments,
  OrderItem,
  OrderLimitExceedance,
  OrderPostDTO,
  OrderPreview,
  OrderPreviewColumn,
  OrderPreviewColumns,
  OrderPreviewColumnsKey,
  OrderResponse,
  OrderSearchModel,
  OrderSortOrder,
  OrderStatus,
  OrderSumInformation,
  OrdersView,
  PreparedOrder,
  ProposeOrderPostDTO,
  RequestOrderPostDTO,
  SerialNumber,
  SortDirection,
  TransactionType,
  UpdateOrderItemDTO,
  UpdateOrderPatchDTO,
  ValidateOrderNumberDTO
}
