import { defineStore } from 'pinia'
import { MediumProduct } from '@/modules/product/types'
import { handleApiError } from '@/handleApiError'
import productApi from '@/modules/product/api'
import orderApi from '@/modules/order/api'
import { OrderSearchModel } from '@/modules/order/types'
import { InstantSearchItem, InstantSearchType } from '@/modules/search/type'

interface InstantSearchState {
  instantSearchItems: {
    products: MediumProduct[]
    orders: OrderSearchModel[]
  }
  loading: boolean
}

const initialState = (): InstantSearchState => {
  return {
    instantSearchItems: {
      products: [],
      orders: []
    },
    loading: false
  }
}

const useInstantSearch = defineStore('instantSearch', {
  state: () => initialState(),
  getters: {
    getInstantSearchItems: (state) => {
      let items: InstantSearchItem[] = []

      if (state.instantSearchItems.products.length > 0) {
        items.push({ key: 'products', type: InstantSearchType.PRODUCT })
        items = items.concat(state.instantSearchItems.products)
      }

      if (
        state.instantSearchItems.products.length > 0 &&
        state.instantSearchItems.orders.length > 0
      ) {
        items.push({ divider: true })
      }

      if (state.instantSearchItems.orders.length > 0) {
        items.push({ key: 'order.orders', type: InstantSearchType.ORDER })
        items = items.concat(state.instantSearchItems.orders)
      }

      return items
    }
  },
  actions: {
    async instantSearch(searchTerm: string) {
      this.loading = true

      const searchProducts = productApi
        .searchProducts({ keyword: searchTerm, instantSearch: true, size: 5 })
        .then(({ data }) => {
          this.instantSearchItems.products = data.items
        })

      const searchOrders = orderApi.searchOrders(searchTerm).then(({ data }) => {
        this.instantSearchItems.orders = data
      })

      return Promise.all([searchProducts, searchOrders])
        .catch(handleApiError)
        .finally(() => {
          this.loading = false
        })
    },
    clearItems() {
      this.instantSearchItems.products = []
      this.instantSearchItems.orders = []
    }
  }
})

export default useInstantSearch
