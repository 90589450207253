import { OrdersView } from '@/modules/order/types'
import { Event } from '@/modules/tracking/types'

export default class OrderEvent extends Event {
  static TextSearchInstantSearch = new OrderEvent(
    'Bestellungen durchsucht',
    'Über die Instant search'
  )
  static TextSearch = new OrderEvent(
    'Bestellungen durchsucht',
    'Über die Suchleiste in der Liste der Bestellungen'
  )
  static InstantSearch = new OrderEvent('Bestellung in Instant Search ausgewählt')
  static OpenSerialNumbers = new OrderEvent('Seriennummern-Dialog geöffnet')
  static CopySerialNumbers = new OrderEvent('Alle Seriennummern einer Position kopiert')
  static OpenParcelTracking = new OrderEvent('Lieferung verfolgen Dialog geöffnet')
  static OpenParcelTrackingLink = new OrderEvent('Paketverfolgungslink geöffnet')
  static Download = class {
    static All = new OrderEvent('Alle Bestelldokumente wurden heruntergeladen.')
    static Delivery = new OrderEvent('Ein Lieferavis wurde heruntergeladen.')
    static Confirmation = new OrderEvent('Eine Bestellbestätigung wurde heruntergeladen.')
    static Order = new OrderEvent('Eine Bestellung wurde heruntergeladen.')
    static FailedOrder = new OrderEvent('Eine nicht sendbare Bestellung wurde heruntergeladen.')
    static Invoice = new OrderEvent('Eine Rechnung wurde heruntergeladen.')
  }
  static Approve = new OrderEvent('Freizugebende Bestellung akzeptiert')
  static RequestApproval = new OrderEvent('Freizugebende Bestellung erneut angefragt')
  static DeclineApproval = new OrderEvent('Freizugebende Bestellung abgelehnt')
  static Request = new OrderEvent('Ausstehende Bestellung angefragt')
  static Send = new OrderEvent('Ausstehende Bestellung abgeschickt')
  static Decline = new OrderEvent('Ausstehende Bestellung abgelehnt')
  static SendAndPay = new OrderEvent('Bezahlung via Sofortüberweisung initiiert')
  static Archive = new OrderEvent('Archiviert')
  static Dearchive = new OrderEvent('Dearchiviert')
  static Sort = class {
    static Date = class {
      static Ascending = new OrderEvent('Sortiert nach Datum', 'Aufsteigend')
      static Descending = new OrderEvent('Sortiert nach Datum', 'Absteigend')
    }
    static Status = class {
      static Ascending = new OrderEvent('Sortiert nach Status', 'Aufsteigend')
      static Descending = new OrderEvent('Sortiert nach Status', 'Absteigend')
    }
  }

  static ToggleView = (view: string): OrderEvent => {
    if (view === OrdersView.GRID) {
      return new OrderEvent('Darstellungsansicht umgeschaltet', 'Kachelansicht')
    }
    if (view === OrdersView.LIST) {
      return new OrderEvent('Darstellungsansicht umgeschaltet', 'Listenansicht')
    }
    if (view === OrdersView.APPROVALS) {
      return new OrderEvent('Darstellungsansicht umgeschaltet', 'Freizugebende Bestellungen')
    }
    return new OrderEvent('Darstellungsansicht umgeschaltet', view)
  }
  static AddToCart = new OrderEvent('Zum Warenkorb hinzugefügt')

  private constructor(action: string, label?: string) {
    super('Bestellungen', action, label ?? '')
  }
}
