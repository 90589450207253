import useQuotes from '@/modules/quote/useQuotes'
import useBranding from '@/modules/portalSettings/useBranding'
import {
  mdiAccountPlusOutline,
  mdiApple,
  mdiDomain,
  mdiHandshake,
  mdiInboxArrowUp,
  mdiLogin,
  mdiStarOutline,
  mdiStore,
  mdiTagArrowDownOutline
} from '@mdi/js'

export type MenuItem = {
  icon: string
  key: string
  link: string
  restriction?: 'useAppleCtoFinder' | 'useQuotes' | 'usePromotionalProducts'
  count?: {
    loadFunction: () => Promise<void | number>
    getter: () => number
  }
}

const menu = (): MenuItem[] => [
  {
    icon: mdiStore,
    key: 'products',
    link: '/products'
  },
  {
    icon: mdiApple,
    key: 'cto.appleCtoFinder',
    link: '/cto',
    restriction: 'useAppleCtoFinder'
  },
  {
    icon: mdiStarOutline,
    key: 'collection.collections',
    link: '/collections'
  },
  {
    icon: mdiHandshake,
    key: 'quotes.quotes',
    link: '/quotes',
    restriction: 'useQuotes',
    count: {
      loadFunction: useQuotes().getNewQuotesCount,
      getter: useQuotes().getNewCount
    }
  },
  {
    icon: mdiInboxArrowUp,
    key: 'order.orders',
    link: '/orders'
  },
  {
    icon: mdiTagArrowDownOutline,
    key: 'promotionalProducts',
    link: '/promotional-products',
    restriction: 'usePromotionalProducts'
  }
]

const toolbarUserMenu: MenuItem[] = [
  {
    icon: mdiDomain,
    key: 'myCompany',
    link: '/company'
  }
]

const guestToolbarUserMenu = (isRegistred: boolean): MenuItem[] => {
  if (!useBranding().quotesPortalRegistrationAllowed.value) {
    return []
  }
  if (!isRegistred) {
    return [
      {
        icon: mdiAccountPlusOutline,
        key: 'register',
        link: '/guest/registration'
      }
    ]
  } else {
    return [
      {
        icon: mdiLogin,
        key: 'login',
        link: '/login'
      }
    ]
  }
}

export { menu, toolbarUserMenu, guestToolbarUserMenu }
