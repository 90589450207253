import { ApiError, handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import api from '@/modules/quote/api'
import {
  QuoteRequest,
  QuoteRequestItemPostDTO,
  QuoteRequestPatch,
  RequestItemUpdate
} from '@/modules/quote/types'
import helpers from '@/modules/message/helpers'
import router from '@/router'
import useQuote from '@/modules/quote/useQuote'

interface QuoteRequestState {
  quoteRequest: QuoteRequest | undefined
  loading: boolean
  rejecting: boolean
  printing: boolean
  creating: boolean
  requesting: boolean
  deleting: boolean
}

const initialState = (): QuoteRequestState => {
  return {
    quoteRequest: undefined,
    loading: false,
    rejecting: false,
    printing: false,
    creating: false,
    requesting: false,
    deleting: false
  }
}

const useQuoteRequest = defineStore('quoteRequest', {
  state: () => initialState(),
  actions: {
    init(quoteRequest?: QuoteRequest) {
      this.quoteRequest = quoteRequest
    },
    async createQuoteRequest(name: string, items: QuoteRequestItemPostDTO[] = []) {
      this.creating = true

      return api
        .createQuoteRequest({ name, items })
        .then(({ data }) => {
          router.push({
            name: 'Quote',
            params: { id: data }
          })
        })
        .catch(handleApiError)
        .finally(() => (this.creating = false))
    },
    async getQuoteRequest(id: string, backgroundLoading = false) {
      this.loading = !backgroundLoading

      return api
        .getQuoteRequest(id)
        .then(({ data }) => {
          this.quoteRequest = data
        })
        .catch((error: ApiError) => {
          handleApiError(
            error,
            {
              appearance: 'CUSTOM',
              customHandler: 'CategoryLayout'
            },
            {
              errorCode: 404,
              logError: false,
              customMessageKey: 'quoteRequest.requestNotFound'
            }
          )
        })
        .finally(() => {
          this.loading = false
        })
    },
    async updateQuoteRequest(id: string, patch: QuoteRequestPatch) {
      return api.updateQuoteRequest(id, patch).catch((error) => {
        handleApiError(error, undefined, {
          errorCode: 403,
          logError: false,
          customMessageKey: 'quoteRequest.cannotEditRequest'
        })

        if (error.code === 403) {
          useQuote().getQuote(id, true)
        }
      })
    },
    async deleteQuoteRequest(id: string) {
      this.deleting = true

      return api
        .deleteQuoteRequest(id)
        .then(() => router.push({ name: 'Quotes' }))
        .catch((error) => {
          handleApiError(error, undefined, {
            errorCode: 403,
            logError: false,
            customMessageKey: 'quoteRequest.cannotEditRequest'
          })

          if (error.code === 403) {
            useQuote().getQuote(id, true)
          }
        })
        .finally(() => (this.deleting = false))
    },
    async updateLabel(itemId: string, label: string | undefined) {
      if (this.quoteRequest) {
        const requestId = this.quoteRequest.id

        return api
          .updateQuoteRequestItem(this.quoteRequest.id, itemId, {
            comment: label
          })
          .catch((error: ApiError) => {
            handleApiError(
              error,
              {
                customMessageKey: 'quoteRequest.textLineItemChangeError'
              },
              {
                errorCode: 403,
                logError: false,
                customMessageKey: 'quoteRequest.cannotEditRequest'
              }
            )

            if (error.code === 403) {
              useQuote().getQuote(requestId, true)
            }
          })
      }
    },
    async updateRequestItemQuantity(id: string, quantity: number, comment: string) {
      if (this.quoteRequest) {
        const requestId = this.quoteRequest.id
        const update: RequestItemUpdate = {
          quantity: quantity,
          comment: comment // Hack because API otherwise sets comment to null
        }
        return api
          .updateQuoteRequestItem(requestId, id, update)
          .then(() => {
            this.getQuoteRequest(requestId, true)
          })
          .catch((error) => {
            handleApiError(error, undefined, {
              errorCode: 403,
              logError: false,
              customMessageKey: 'quoteRequest.cannotEditRequest'
            })

            if (error.code === 403) {
              useQuote().getQuote(requestId, true)
            }
          })
      }
    },
    async deleteRequestItem(id: string) {
      if (this.quoteRequest) {
        const requestId = this.quoteRequest.id
        return api
          .deleteRequestItem(requestId, id)
          .then(() => {
            helpers.reportSuccess('collection.success.itemRemoved')
            this.getQuoteRequest(requestId, true)
          })
          .catch((error) => {
            handleApiError(error, undefined, {
              errorCode: 403,
              logError: false,
              customMessageKey: 'quoteRequest.cannotEditRequest'
            })

            if (error.code === 403) {
              useQuote().getQuote(requestId, true)
            }
          })
      }
    },
    async addLineItem(newLineItem: QuoteRequestItemPostDTO, reload = false) {
      if (this.quoteRequest) {
        const requestId = this.quoteRequest.id
        return api
          .addToQuoteRequest(this.quoteRequest.id, newLineItem)
          .then(() => {
            helpers.reportSuccess('quoteRequest.lineItemAdded')
            if (reload) {
              this.getQuoteRequest(requestId, true)
            }
          })
          .catch((error) => {
            handleApiError(error, undefined, {
              errorCode: 403,
              logError: false,
              customMessageKey: 'quoteRequest.cannotEditRequest'
            })

            if (error.code === 403) {
              useQuote().getQuote(requestId, true)
            }
          })
      }
    },
    async sendRequest() {
      if (this.quoteRequest) {
        this.requesting = true
        const requestId = this.quoteRequest.id
        return api
          .sendQuoteRequest(requestId)
          .then(() => {
            helpers.reportSuccess('quoteRequest.sentQuoteRequest')
            const quoteId = useQuote().quote?.id
            if (quoteId) {
              useQuote().getQuote(quoteId, true)
            }
          })
          .catch((error) => {
            handleApiError(error, undefined, {
              errorCode: 403,
              logError: false,
              customMessageKey: 'quoteRequest.cannotEditRequest'
            })

            if (error.code === 403) {
              useQuote().getQuote(requestId, true)
            }
          })
          .finally(() => (this.requesting = false))
      }
    }
  }
})

export default useQuoteRequest
