import i18n from '@/plugins/i18n'

const mailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const passwordLengthPattern = /.{8,}/
const passwordPattern = /^[\x20-\x7E\xA0-\xFF]{8,}$/
const namePattern = /^[A-Za-z0-9\u00C0-\u017F ,.'-]{0,50}$/
const phonePattern = /^[^a-zA-Z]{0,20}$/
const positiveNumberPattern = /^[+]?\d+([.,]\d+)?$/
const urlPattern = new RegExp(
  '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', // fragment locator
  'i'
)
export const mailRule = (value: string): boolean | string =>
  !value ||
  value.length === 0 ||
  mailPattern.test(value) ||
  i18n.global.t('error.invalidEmail').toString()

export const passwordLengthRule = (value: string): boolean | string =>
  passwordLengthPattern.test(value) || i18n.global.t('error.shortPassword').toString()

export const passwordRule = (value: string): boolean | string =>
  passwordPattern.test(value) || i18n.global.t('error.passwordNotAllowedChar').toString()

export const passwordMatchRule = (password: string) => {
  return (value: string): boolean | string =>
    password === value || i18n.global.t('error.passwordRepeat').toString()
}

export const nameRule = (value: string): boolean | string =>
  namePattern.test(value) || i18n.global.t('error.invalidName').toString()

export const phoneRule = (value: string | undefined): boolean | string =>
  value === undefined || phonePattern.test(value) || i18n.global.t('error.invalidNumber').toString()

export const requiredRule = (value: string | boolean): boolean | string =>
  !!value || i18n.global.t('error.requiredField').toString()

export const positiveNumberRule = (value: string): boolean | string =>
  positiveNumberPattern.test(value) || i18n.global.t('error.invalidNegativeNumber').toString()

export const urlRuleHttpOptional = (value: string): boolean | string =>
  !value ||
  value.length === 0 ||
  urlPattern.test(value) ||
  i18n.global.t('error.invalidUrl').toString()

export default {
  mailRule,
  nameRule,
  passwordLengthRule,
  passwordMatchRule,
  passwordRule,
  phoneRule,
  positiveNumberRule,
  requiredRule,
  urlRuleHttpOptional
}
