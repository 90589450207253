<template>
  <div>
    <v-dialog v-model="dialog" :width="500">
      <v-card ref="reset-password-card">
        <v-card-item>
          <v-card-title>
            <sf-heading>{{ $t('forgotPassword') }}</sf-heading>
          </v-card-title>
        </v-card-item>
        <v-card-text class="pb-0">
          <v-form ref="resetForm" v-model="validResetMail" lazy-validation>
            <sf-text-field
              v-model="resetMail"
              :label="$t('emailAddress')"
              :rules="[rules.requiredRule, rules.mailRule]"
              class="mt-2"
              required
              @keydown.enter.prevent="reset"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <sf-text-button color="grey" class="text-medium-emphasis" text @click="dialog = false">
            {{ $t('cancel') }}
          </sf-text-button>
          <sf-text-button color="primary" :disabled="!validResetMail" @click="reset">
            {{ $t('send') }}
          </sf-text-button>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-form
      v-if="
        !branding.ssoSettings || (branding.ssoSettings && !branding.ssoSettings.ssoLoginExclusive)
      "
      ref="form"
      v-model:model-value="valid"
      lazy-validation
    >
      <sf-text-field
        ref="mail-field"
        v-model="mail"
        color="primary"
        :label="$t('emailAddress')"
        :rules="[rules.mailRule, rules.requiredRule]"
        variant="outlined"
        density="default"
        required
        autocomplete="username"
        @keydown.enter="login"
      />
      <sf-password-field
        ref="password-field"
        v-model="password"
        color="primary"
        :label="$t('password')"
        :rules="[rules.requiredRule]"
        variant="outlined"
        density="default"
        required
        autocomplete="current-password"
        @keydown.enter="login"
      />
      <div v-if="loginError === LoginError.WRONG_CREDENTIALS" class="text-error mt-1">
        {{ $t('error.wrongCredentials') }}
      </div>
      <div v-if="loginError === LoginError.USER_DEACTIVATED" class="text-error mt-1">
        {{ $t('error.userDeactivated') }}
      </div>
      <div v-if="loginError === LoginError.UNKNOWN_ERROR" class="text-error mt-1">
        {{ $t('error.unknownLoginError') }}
      </div>
      <div
        :class="showRegistration ? 'justify-space-between' : 'justify-center'"
        class="text-center mt-2 text-body-2 text-primary d-flex"
      >
        <div
          ref="reset-password-button"
          class="d-inline cursor-pointer"
          @click="showResetPasswordDialog"
        >
          {{ $t('forgotPassword') }}
        </div>
        <div
          v-if="showRegistration"
          ref="register-button"
          class="d-inline cursor-pointer"
          @click="navigateToSelfRegistration"
        >
          {{ $t('register') }}
        </div>
      </div>
      <sf-large-button ref="login-button" block class="mt-2" @click="login">
        {{ $t('signIn') }}
      </sf-large-button>
      <div v-if="branding.ssoSettings" class="d-flex justify-center mt-2">
        <a
          class="cursor-pointer text-primary text-decoration-none"
          :href="branding.ssoSettings.url + getRouteRedirect($router)"
        >
          {{ $t('login_sso') }}
        </a>
      </div>
    </v-form>
    <sf-large-button
      v-if="branding.ssoSettings && branding.ssoSettings.ssoLoginExclusive"
      block
      class="mt-2"
      :href="'https://' + host + branding.ssoSettings.url + getRouteRedirect($router)"
    >
      {{ $t('signIn') }}
    </sf-large-button>
  </div>
</template>

<script lang="ts">
import SfLargeButton from '@/components/button/SfLargeButton.vue'
import SfTextButton from '@/components/button/SfTextButton.vue'
import SfTextField from '@/components/input/SfTextField.vue'
import SfPasswordField from '@/components/input/SfPasswordField.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import { fitCropImage } from '@/helpers'
import { LoginCredentials, LoginError } from '@/modules/authentication/types'
import { Branding } from '@/modules/portalSettings/types'
import router from '@/router'
import { getRouteRedirect } from '@/router/helpers'
import rules from '@/rules'
import { VForm } from '@/types'
import { defineComponent, PropType, Ref, ref } from 'vue'
import { computed } from 'vue'

export default defineComponent({
  name: 'LoginForm',
  components: { SfLargeButton, SfHeading, SfTextButton, SfPasswordField, SfTextField },
  props: {
    branding: {
      type: Object as PropType<Branding>,
      required: true
    },
    loginError: {
      type: String as PropType<LoginError>,
      required: true
    }
  },
  emits: ['login', 'reset'],
  setup(props, { emit }) {
    const form = ref<VForm>()
    const resetForm = ref<VForm>()
    const mail = ref('')
    const password = ref('')
    const dialog = ref(false)
    const resetMail = ref('')
    const valid = ref(false)
    const validResetMail = ref(false)
    const showRegistration = computed(
      () => props.branding.isUserRegistration && !props.branding.isQuotesPortal
    )

    const isFormValid = async (checkForm: Ref<VForm | undefined>): Promise<boolean> => {
      if (!checkForm.value) {
        return false
      }
      const result = (await checkForm.value.validate()) as unknown as { valid: boolean }
      return result.valid
    }

    const login = async () => {
      const loginCredentials: LoginCredentials = {
        mail: mail.value,
        password: password.value
      }

      if (await isFormValid(form)) {
        emit('login', loginCredentials)
      }
    }

    const reset = async () => {
      if (await isFormValid(resetForm)) {
        dialog.value = false
        emit('reset', resetMail.value)
      }
    }

    const showResetPasswordDialog = () => {
      resetMail.value = mail.value
      dialog.value = true
    }

    const navigateToSelfRegistration = () => {
      router.push({
        name: 'SelfRegistration'
      })
    }

    return {
      host: location.host,
      rules,
      form,
      resetForm,
      LoginError,
      mail,
      password,
      valid,
      dialog,
      resetMail,
      fitCropImage,
      login,
      reset,
      showResetPasswordDialog,
      navigateToSelfRegistration,
      getRouteRedirect,
      validResetMail,
      showRegistration
    }
  }
})
</script>
