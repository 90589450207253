import { ApiError, handleApiError } from '@/handleApiError'
import { Language } from '@/types'
import { defineStore } from 'pinia'
import helpers from '@/modules/message/helpers'
import api from '@/modules/user/api'
import { prepareForForm } from '@/modules/user/helpers'
import { ChangePassword, User, UserPermissionPatchDTO } from '@/modules/user/types'
import useOwnUser from '@/modules/user/useOwnUser'

interface UserState {
  user: User | undefined
  loading: boolean
  sending: boolean
}

const initialState = (): UserState => {
  return {
    user: undefined,
    loading: false,
    sending: false
  }
}

const useUser = defineStore('user', {
  state: () => initialState(),
  actions: {
    async getUser(id: string) {
      this.loading = true

      return api
        .getUser(id)
        .then(({ data }) => {
          this.user = prepareForForm(data)
        })
        .catch(handleApiError)
        .finally(() => (this.loading = false))
    },
    async updateUser(user: User) {
      if (this.user) {
        Object.assign(this.user, user)
        useOwnUser().updateOwnUser(user)

        return api.updateUser(user).catch(handleApiError)
      }
    },
    async updateLanguage(lng: Language) {
      if (this.user) {
        this.user.alpha2language = lng
        useOwnUser().updateOwnUser(this.user)

        return api.updateUser(this.user).catch(handleApiError)
      }
    },
    async updatePermissions(id: string, dto: UserPermissionPatchDTO) {
      return api
        .updateUserPermissions(id, dto)
        .then(async () => {
          return api.getUser(id).then(({ data }) => {
            this.user = data
            useOwnUser().updateOwnUser(data)
          })
        })
        .catch(handleApiError)
    },
    async updateAvatar(id: string, form: FormData) {
      return api
        .updateAvatar(id, form)
        .then(async () => {
          return api.getUser(id).then(({ data }) => {
            this.user = data
            useOwnUser().updateOwnUser(data)
          })
        })
        .catch((error: ApiError) => {
          handleApiError(
            error,
            {},
            {
              errorCode: 415,
              customMessageKey: 'error.unsupportedMediaType',
              logError: false
            },
            {
              errorCode: 413,
              customMessageKey: 'error.fileTooLarge',
              logError: false
            }
          )
        })
    },

    async changePassword(
      password: ChangePassword
    ): Promise<'SUCCESS' | 'OLD_PASSWORD_INVALID' | 'NEW_PASSWORD_INVALID'> {
      this.sending = true

      return api
        .changePassword(password)
        .then(() => {
          helpers.reportSuccess('success.changePassword')
          return 'SUCCESS' as const
        })
        .catch((error: ApiError) => {
          handleApiError(
            error,
            undefined,
            {
              errorCode: 403,
              logError: false,
              customMessageKey: 'error.oldPassword'
            },
            {
              errorCode: 400,
              logError: false,
              customMessageKey: 'error.passwordRequirements'
            }
          )
          return error.code?.valueOf() == 403
            ? ('OLD_PASSWORD_INVALID' as const)
            : ('NEW_PASSWORD_INVALID' as const)
        })
        .finally(() => (this.sending = false))
    }
  }
})

export default useUser
