import { ApiError, handleApiError } from '@/handleApiError'
import { defineStore } from 'pinia'
import api from '@/modules/authentication/api'
import { RegistrationDTO, Token } from '@/modules/authentication/types'
import router from '@/router'
import helpers from '@/modules/message/helpers'
import { Password } from '@/modules/user/types'

interface Registration {
  tokenError: boolean
  sending: boolean
  loading: boolean
}

const initialState = (): Registration => {
  return {
    tokenError: false,
    sending: false,
    loading: false
  }
}

const useRegistration = defineStore('registration', {
  state: () => initialState(),
  actions: {
    async checkRegisterToken(dto: Token) {
      this.loading = true

      return api
        .checkRegisterToken(dto)
        .then(() => {
          this.tokenError = false
        })
        .catch((error: ApiError) => {
          if (error.code === 400) {
            // token not valid
            this.tokenError = true
          } else {
            // other error
            handleApiError(error)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async finishRegistration(dto: RegistrationDTO) {
      this.sending = true

      return api
        .finishRegistration(dto)
        .then(() => {
          this.tokenError = false
          helpers.reportSuccess('success.registrationSent')
          router.replace('/login')
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            customMessageKey: 'error.registration'
          })
        })
        .finally(() => {
          this.sending = false
        })
    },
    async guestRegistration(dto: Password) {
      this.sending = true

      return api
        .guestRegistration(dto)
        .then(({ data }) => {
          window.location.assign('/autologin?token=' + data)
        })
        .catch((error: ApiError) => {
          handleApiError(error, {
            customMessageKey: 'error.registration'
          })
        })
        .finally(() => {
          this.sending = false
        })
    }
  }
})

export default useRegistration
