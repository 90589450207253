<template>
  <list-row class="pw-quote-row" :to="{ name: 'Quote', params: { id: quote.id } }">
    <template #avatar>
      <avatar-image v-if="quote.assignee" :user="quote.assignee" :size="40" />
    </template>
    <template #title>
      <div class="d-flex align-center">
        <sf-text ref="title" class="text-truncate">
          {{ quote.name }}
        </sf-text>
        <sf-new-chip v-if="!quote.alreadyViewed" class="ml-1 flex-shrink-0" />
      </div>
      <sf-secondary-text ref="info">
        <span>{{ quote.quoteNumber }}</span>
        <span class="mx-1">-</span>
        <template v-if="quote.assignee">
          <span ref="assignee">{{ getFullName(quote.assignee) }}</span>
          <span class="mx-1">-</span>
        </template>
        <span>{{ $t('positions', quote.itemCount) }}</span>
        <template v-if="quote.buyerArchived">
          <span class="mx-1">-</span>
          <span ref="archived">{{ $t('common.archived') }}</span>
        </template>
      </sf-secondary-text>
    </template>
    <template #date>
      <sf-text>
        {{ formatDate(quote.createdDate) }}
      </sf-text>
    </template>
    <template #costs>
      <sf-heading v-if="showPrice" ref="costs">
        {{ formatMoney(quote.sum, false, true) }}
      </sf-heading>
    </template>
    <template #status>
      <sf-color-text ref="status" :color="getQuoteStatusColor(quote.quoteStatus)">
        {{ $t('quoteStatus.' + quote.quoteStatus) }}
      </sf-color-text>
    </template>
  </list-row>
</template>

<script lang="ts">
import AvatarImage from '@/components/AvatarImage.vue'
import SfNewChip from '@/components/chips/SfNewChip.vue'
import ListRow from '@/components/list/ListRow.vue'
import SfColorText from '@/components/text/SfColorText.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import SfSecondaryText from '@/components/text/SfSecondaryText.vue'
import SfText from '@/components/text/SfText.vue'
import { getFullName, isQuotesPortal } from '@/helpers'
import { formatMoney } from '@/modules/product/helpers'
import { getQuoteStatusColor } from '@/modules/quote/helpers'
import { QuoteStatus, ShortQuote } from '@/modules/quote/types'
import { formatDate } from '@/utils/timeFormatHelpers'
import { PropType, computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'QuoteRow',
  components: {
    AvatarImage,
    ListRow,
    SfColorText,
    SfNewChip,
    SfSecondaryText,
    SfText,
    SfHeading
  },
  props: {
    quote: {
      type: Object as PropType<ShortQuote>,
      required: true
    }
  },
  setup(props) {
    const showPrice = computed(
      () =>
        !isQuotesPortal.value ||
        (props.quote.quoteStatus !== QuoteStatus.DRAFT &&
          props.quote.quoteStatus !== QuoteStatus.REQUESTED)
    )

    return {
      formatDate,
      formatMoney,
      getFullName,
      getQuoteStatusColor,
      showPrice
    }
  }
})
</script>
