import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import axios from 'axios'

function get<T>(path: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
  return axios.get<T>(path, config)
}

function put<T, D>(
  path: string,
  data: D,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> {
  return axios.put<T, AxiosResponse<T>, D>(path, data, config)
}

function post<T, D>(
  path: string,
  data?: D,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> {
  return axios.post<T, AxiosResponse<T>, D>(path, data, config)
}

function del<T>(path: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
  return axios.delete<T>(path, config)
}

function patch<T, D>(
  path: string,
  data: D,
  config: AxiosRequestConfig = {}
): Promise<AxiosResponse<T>> {
  return axios.patch<T, AxiosResponse<T>, D>(path, data, config)
}

export { AxiosRequestConfig as Request, AxiosResponse as Response, del, get, patch, post, put }
