type PageableResponse<T> = {
  totalCount: number
  pageCount: number
  items: T[]
}

type SelectOption<T> = {
  title: string
  value: T
}

enum SortDirection {
  ASC,
  DESC,
  NONE
}

enum QuoteTabs {
  REQUEST,
  QUOTE,
  DOCUMENTS
}

type EmailPreview = {
  subject: string
  body?: string
  attachments: Attachment[]
}

type Attachment = {
  name: string
  content: string
  contentType: AttachmentType
}

enum AttachmentType {
  PDF = 'PDF',
  XLS = 'XLS'
}

type ViewToggle<T> = {
  name: T
  icon: string
  label?: string
  showOnMobile: boolean
  restriction: () => boolean
  count?: {
    loadFunction: () => Promise<void | boolean>
    getter: () => boolean
  }
}

type VForm = {
  validate: () => boolean
  resetValidation: () => boolean
  reset: () => void
}

type VMenu = {
  isActive: boolean
}

type VAutoCompleteGroupDivider = { divider: true }

type RejectionDTO = {
  comment: string
}

const PAGE_SIZE = 24

type CommentType = 'ORDER' | 'QUOTE'

type ShoppingCartWarning = {
  messageKey: string
  isError: boolean
}

enum Language {
  de = 'de',
  en = 'en',
  fr = 'fr',
  it = 'it',
  pl = 'pl',
  nl = 'nl',
  es = 'es'
}

const FALLBACK_LOCALE = 'en'
const supportedLocales = Object.keys(Language)
type Locale = (typeof supportedLocales)[number]

type KeyValue = { key: string; value: boolean }

// TODO: DataTableHeader automatisch typisieren https://stackoverflow.com/questions/75991355/import-datatableheader-typescript-type-of-vuetify3-v-data-table
type DataTableCompareFunction<T> = (a: T, b: T) => number
type DataTableHeader<T> = {
  title?: string
  key?: string
  value?: string
  width?: string
  align?: 'center' | 'end' | 'start' | undefined
  sortable?: boolean
  sort?: DataTableCompareFunction<T> | undefined
  headerProps?: {
    readonly [x: string]: unknown
  }
  cellProps?: {
    readonly [x: string]: unknown
  }
}

interface InternalItem<T> {
  value: T
  raw: T
}

type InfiniteScrollSide = 'start' | 'end' | 'both'
type InfiniteScrollStatus = 'ok' | 'empty' | 'loading' | 'error'
type InfiniteScrollOnLoadOptions = {
  side: InfiniteScrollSide
  done: (status: InfiniteScrollStatus) => void
}

export {
  Attachment,
  AttachmentType,
  CommentType,
  DataTableCompareFunction,
  DataTableHeader,
  EmailPreview,
  FALLBACK_LOCALE,
  KeyValue,
  Language,
  Locale,
  InfiniteScrollOnLoadOptions,
  InfiniteScrollSide,
  InfiniteScrollStatus,
  InternalItem,
  PAGE_SIZE,
  PageableResponse,
  QuoteTabs,
  RejectionDTO,
  SelectOption,
  ShoppingCartWarning,
  SortDirection,
  VAutoCompleteGroupDivider,
  VForm,
  VMenu,
  ViewToggle,
  supportedLocales
}
