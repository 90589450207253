<template>
  <list-content class="mt-1">
    <v-col cols="12">
      <v-row dense>
        <v-col cols="12" sm="6">
          <quote-address-and-contact-data
            v-if="isQuotesPortal"
            id="pw-quote-address-card"
            :editable="quoteStatus === QuoteStatus.NEGOTIATION"
            :address-selection="quote.buyerAddressSelection"
            :address="quote.buyerAddress"
            :contact-data="quote.buyerUser"
            :customer-number="quote.buyerCustomerNumber"
            @update-contact-data="updateContactData"
            @update-address="updateAddress"
            @select-address="selectAddress"
          />
          <quote-address-card v-else :order-address="quote.buyerAddress" />
        </v-col>

        <v-col v-if="quote.sellerUser" cols="12" sm="6">
          <quote-seller-info-card
            :seller-user="quote.sellerUser"
            :created-date="quote.createdDate"
            :valid-date="quote.validDate"
          />
        </v-col>
      </v-row>
      <v-divider class="my-2" />

      <v-row dense>
        <v-col>
          <sf-heading ref="ct-quote-heading">
            {{ $t('quotes.quote') }} {{ quote.quoteNumber }}
          </sf-heading>
          <sf-heading v-if="quote.quoteVersion !== '1'" ref="ct-quote-versionSuffix">
            {{ '-V' + quote.quoteVersion }}
          </sf-heading>
        </v-col>
      </v-row>

      <v-row v-if="quote.rejectionInformation && quoteStatus === QuoteStatus.REJECTED" dense>
        <v-col>
          <quote-rejection-info :rejection-info="quote.rejectionInformation" />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <span v-html="quote.headerText" />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <quote-position
            v-for="item in quote.items"
            :key="item.id"
            :quote-item="item"
            :editable="quoteStatus === QuoteStatus.NEGOTIATION"
            :is-gross-quote="quote.isGrossQuote"
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <quote-sum-info
            :quote-sum-information="quote.sumInformation"
            :is-gross-quote="quote.isGrossQuote"
          >
            <template v-if="showButtons" #button>
              <template v-if="isPunchoutMode">
                <punchout-dialog ref="ct-punchout-dialog" big-button :quote-id="quote.id" />
                <span />
                <rejection-dialog
                  class="mt-2 mt-sm-0"
                  :rejecting="rejecting"
                  :type="'QUOTE'"
                  @reject="rejectQuote"
                />
              </template>
              <template v-else>
                <span @click="trackBodyButton">
                  <sf-large-button
                    ref="ct-checkout-button"
                    :block="$vuetify.display.xs"
                    class="mr-sm-2"
                    :disabled="
                      noPositionSelected || (quote.validDate && !dateNotOver(quote.validDate))
                    "
                    :to="{
                      name: 'CheckoutQuote',
                      params: { id: quote.id }
                    }"
                  >
                    {{ $t('order.goToOrder') }}
                  </sf-large-button>
                </span>
                <rejection-dialog
                  class="mt-2 mt-sm-0"
                  :rejecting="rejecting"
                  :type="'QUOTE'"
                  @reject="rejectQuote"
                />
              </template>
            </template>
          </quote-sum-info>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <span ref="ct-footer-text" v-html="quote.footerText" />
        </v-col>
      </v-row>
    </v-col>
  </list-content>
</template>

<script lang="ts">
import RejectionDialog from '@/components/RejectionDialog.vue'
import SfLargeButton from '@/components/button/SfLargeButton.vue'
import ListContent from '@/components/list/ListContent.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import { isQuotesPortal, isQuotesPortalOrGuest } from '@/helpers'
import { Address, AddressType } from '@/modules/address/types'
import useAddressValidation from '@/modules/address/useAddressValidation'
import useAuthentication from '@/modules/authentication/useAuthentication'
import { ContactData } from '@/modules/contactData/types'
import PunchoutDialog from '@/modules/punchout/components/PunchoutDialog.vue'
import QuoteAddressAndContactData from '@/modules/quote/components/QuoteAddressAndContactData.vue'
import QuoteAddressCard from '@/modules/quote/components/QuoteAddressCard.vue'
import QuotePosition from '@/modules/quote/components/QuotePosition.vue'
import QuoteRejectionInfo from '@/modules/quote/components/QuoteRejectionInfo.vue'
import QuoteSellerInfoCard from '@/modules/quote/components/QuoteSellerInfoCard.vue'
import QuoteSumInfo from '@/modules/quote/components/QuoteSumInfo.vue'
import { FullQuote, QuoteStatus } from '@/modules/quote/types'
import useQuote from '@/modules/quote/useQuote'
import QuoteEvent from '@/modules/tracking/events/quote'
import { trackEvent } from '@/modules/tracking/useTracking'
import { dateNotOver } from '@/utils/timeFormatHelpers'
import { storeToRefs } from 'pinia'
import { PropType, computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'QuoteDetails',
  components: {
    ListContent,
    PunchoutDialog,
    QuotePosition,
    QuoteRejectionInfo,
    QuoteSumInfo,
    RejectionDialog,
    SfHeading,
    SfLargeButton,
    QuoteSellerInfoCard,
    QuoteAddressAndContactData,
    QuoteAddressCard
  },
  props: {
    quote: {
      type: Object as PropType<FullQuote>,
      required: true
    },
    quoteStatus: {
      type: String as PropType<QuoteStatus>,
      required: true
    }
  },
  setup(props) {
    const { rejecting } = storeToRefs(useQuote())
    const currentRoute = useRoute()

    const validateDeliveryAddress = () => {
      if (!isQuotesPortal.value) {
        useAddressValidation().validateAddress(props.quote.buyerAddress, AddressType.DELIVERY)
      }
    }

    validateDeliveryAddress()
    const showButtons = computed(
      () => !isQuotesPortalOrGuest.value && props.quoteStatus === QuoteStatus.NEGOTIATION
    )

    const isPunchoutMode = computed(() => useAuthentication().state.value.isPunchoutMode)

    const noPositionSelected = computed(() => {
      return !props.quote.items.find(
        (item) => (item.isOptional && item.isPickedOptional) || !item.isOptional
      )
    })

    const rejectQuote = (rejectionComment: string) => {
      useQuote().rejectQuote(currentRoute.params.id as string, rejectionComment)
      trackEvent(QuoteEvent.Decline)
    }

    const trackBodyButton = () => trackEvent(QuoteEvent.ToCart.InBody)

    const updateContactData = (contactData: ContactData) => {
      useQuote().updateQuote(undefined, undefined, contactData)
    }

    const updateAddress = (address: Address) => {
      useQuote().updateQuote(address)
      validateDeliveryAddress()
    }

    const selectAddress = (addressId: string) => {
      useQuote().updateQuote(undefined, addressId)
    }

    return {
      rejecting,
      rejectQuote,
      QuoteStatus,
      noPositionSelected,
      dateNotOver,
      trackBodyButton,
      isPunchoutMode,
      isQuotesPortal,
      showButtons,
      updateContactData,
      updateAddress,
      selectAddress
    }
  }
})
</script>
