<template>
  <filter-base
    :is-loading="store.loading"
    :is-reset-disabled="isResetFilterButtonDisabled"
    @close="$emit('close')"
    @reset-filter="resetFilter"
  >
    <template #skeleton>
      <v-skeleton-loader type="heading" />
      <v-skeleton-loader class="mt-2" type="list-item" />
      <v-skeleton-loader class="mt-2" type="heading" />
      <v-skeleton-loader v-for="index in 4" :key="index" class="mt-2" type="list-item" />
    </template>
    <template #filter>
      <div>
        <sf-heading>{{ $t('sortBy') }}</sf-heading>
        <sf-select
          v-model="selection.sort"
          :items="productSortOptions"
          class="mt-1"
          hide-details
          autocomplete="off"
          @update:model-value="changeSort"
        />
      </div>
      <div class="mt-2">
        <sf-heading>{{ $t('filter.filters') }}</sf-heading>
        <search-filter-input
          v-for="filterNode in store.nonEmptyFilterNodes"
          :key="filterNode.name"
          :filter-node="filterNode"
          :get-selection-for-filter="store.getSelectionForFilter"
          :update-filter-selection="updateFilterSelection"
          :remove-item-from-filter-selection="removeItemFromFilterSelection"
          :disabled="
            filterNode.name === $t('filter.productType').toString() &&
            store.isProductTypeSameAsCategory()
          "
          :filter-event="trackFilter('Default')"
        />
      </div>
      <div class="mt-2">
        <sf-heading>{{ $t('priceRange.priceRange') }}</sf-heading>
        <price-range-filter-input
          :price-range-filter="store.selection.priceRangeFilter"
          @update="updatePriceRangeFilter"
        />
      </div>
      <div class="mt-2">
        <sf-heading>{{ $t('quantityFrom') }}</sf-heading>
        <stock-count-filter-input
          :stok-count-filter="store.selection.stockCountFilter"
          @update="updateStockCountFilter"
        />
      </div>
      <div v-if="store.attributeNodes.length > 0" class="mt-2">
        <div class="d-flex flex-row">
          <sf-heading>{{ $t('filter.productFilters') }}</sf-heading>
          <v-spacer />
          <sf-color-text
            v-if="store.attributeNodes.length > 6 && !showAllAttributeNodes"
            color="primary"
            class="cursor-pointer"
            @click="expandAttributeNodes"
          >
            {{ $t('more') }}
          </sf-color-text>
        </div>
        <search-filter-input
          v-for="attributeNode in store.attributeNodes.slice(
            0,
            showAllAttributeNodes ? store.attributeNodes.length : 6
          )"
          :key="attributeNode.name"
          :filter-node="attributeNode"
          :get-selection-for-filter="store.getSelectionForFilter"
          :update-filter-selection="updateFilterSelection"
          :remove-item-from-filter-selection="removeItemFromFilterSelection"
          :filter-event="trackFilter('Attribute')"
        />
      </div>
    </template>
  </filter-base>
</template>

<script lang="ts">
import FilterBase from '@/components/filter/FilterBase.vue'
import PriceRangeFilterInput from '@/components/filter/PriceRangeFilterInput.vue'
import SearchFilterInput from '@/components/filter/SearchFilterInput.vue'
import StockCountFilterInput from '@/components/filter/StockCountFilterInput.vue'
import { FilterValue, PriceRangeFilter } from '@/components/filter/types'
import SfSelect from '@/components/input/SfSelect.vue'
import SfColorText from '@/components/text/SfColorText.vue'
import SfHeading from '@/components/text/SfHeading.vue'
import { getProductSortOptions, mapProductSortToText } from '@/modules/product/helpers'
import { ProductPageMode, ProductSort } from '@/modules/product/types'
import useProductFilter from '@/modules/product/useProductFilter'
import FilterEvent, { FilterType } from '@/modules/tracking/events/filter'
import { trackEvent } from '@/modules/tracking/useTracking'
import router from '@/router'
import { storeToRefs } from 'pinia'
import { PropType, computed, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'ProductFilter',
  components: {
    SearchFilterInput,
    PriceRangeFilterInput,
    FilterBase,
    SfHeading,
    SfColorText,
    SfSelect,
    StockCountFilterInput
  },
  props: {
    filterMode: {
      type: String as PropType<ProductPageMode>,
      required: true
    },
    filterTrackingFunction: {
      type: Function as PropType<(type: FilterType) => (filterName: string) => FilterEvent>,
      default: undefined
    },
    sortTrackingFunction: {
      type: Function as PropType<(sortName: string) => FilterEvent>,
      default: undefined
    }
  },
  emits: ['close'],
  setup(props) {
    const productSortOptions = computed(() => {
      let options = getProductSortOptions()
      if (props.filterMode === 'CATEGORY') {
        options = options.filter(({ value }) => value != ProductSort.RELEVANCE)
      }
      return options
    })

    const store = useProductFilter()
    const { selection } = storeToRefs(store)
    const showAllAttributeNodes = ref(false)

    const expandAttributeNodes = () => {
      showAllAttributeNodes.value = true
    }

    const resetFilter = () => {
      store.resetFilter(
        props.filterMode === 'CATEGORY' ? ProductSort.POPULARITY : ProductSort.RELEVANCE
      )
      showAllAttributeNodes.value = false
      updateUrl()
    }

    const isResetFilterButtonDisabled = computed(() => {
      const isDefaultSort =
        (props.filterMode === 'CATEGORY' && store.selection.sort === ProductSort.POPULARITY) ||
        (props.filterMode === 'SEARCH' && store.selection.sort === ProductSort.RELEVANCE)
      return (
        isDefaultSort &&
        (store.getActiveFilterLength === 0 ||
          (store.getActiveFilterLength === 1 && store.isProductTypeSameAsCategory()))
      )
    })

    const updateFilterSelection = (name: string, value: FilterValue[] | undefined): void => {
      store.updateFilterSelection(name, value)
      updateUrl()
    }

    const removeItemFromFilterSelection = (name: string, item: FilterValue): void => {
      store.removeItemFromFilterSelection(name, item)
      updateUrl()
    }

    const updatePriceRangeFilter = (filter: PriceRangeFilter): void => {
      store.updatePriceRangeFilter(filter)
      updateUrl()
    }

    const updateStockCountFilter = (value: number | undefined): void => {
      store.updateStockCountFilter(value)
      updateUrl()
    }

    const updateUrl = () => {
      const filterTerm = store.getFilterTerm().join(',')
      router.push({
        path: router.currentRoute.value.fullPath,
        query: {
          ...router.currentRoute.value.query,
          page: '1',
          filter: filterTerm ? filterTerm : undefined,
          sort: store.selection.sort
        }
      })
    }

    const trackFilter = (type: FilterType): ((filterName: string) => FilterEvent | undefined) => {
      return function (filterName: string): FilterEvent | undefined {
        if (props.filterTrackingFunction) {
          return props.filterTrackingFunction(type)(filterName)
        }
      }
    }

    const changeSort = (value: ProductSort) => {
      updateUrl()
      if (props.sortTrackingFunction) {
        trackEvent(props.sortTrackingFunction(mapProductSortToText(value)))
      }
    }

    return {
      productSortOptions,
      selection,
      store,
      showAllAttributeNodes,
      expandAttributeNodes,
      resetFilter,
      trackFilter,
      changeSort,
      updateFilterSelection,
      removeItemFromFilterSelection,
      updatePriceRangeFilter,
      updateStockCountFilter,
      isResetFilterButtonDisabled
    }
  }
})
</script>
