<template>
  <div class="d-flex flex-column">
    <sf-sub-heading>{{ address.companyName }}</sf-sub-heading>
    <sf-text>{{ address.street }}</sf-text>
    <sf-text>{{ address.zipCode }} {{ address.city }}</sf-text>
    <sf-text>{{ getCountryName(address.iso3country, $i18n.locale) }}</sf-text>
  </div>
</template>

<script lang="ts">
import SfSubHeading from '@/components/text/SfSubHeading.vue'
import SfText from '@/components/text/SfText.vue'
import { getCountryName } from '@/modules/address/helpers'
import { OrderAddress } from '@/modules/address/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'QuoteAddress',
  components: {
    SfSubHeading,
    SfText
  },
  props: {
    address: {
      type: Object as PropType<OrderAddress>,
      required: true
    }
  },
  setup() {
    return {
      getCountryName
    }
  }
})
</script>
