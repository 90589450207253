<template>
  <template v-if="branding">
    <sf-alert-success
      v-if="quoteStatus === QuoteStatus.ACCEPTED"
      class="mx-auto my-1"
      max-width="500px"
    >
      {{ $t('quoteStatus.' + quoteStatus) }}
    </sf-alert-success>
    <v-row>
      <v-col v-if="$vuetify.display.lgAndUp"></v-col>
      <v-col cols="12" md="auto" align-self="center">
        <login-wrapper :branding="branding" :title="$t('registration')" :loading="loading">
          <guest-registration-form
            class="mt-2"
            :branding="branding"
            :sending="sending"
            @finish-registration="finishRegistration"
          />
        </login-wrapper>
      </v-col>
      <v-col cols="12" md="" class="d-flex justify-center justify-md-start">
        <v-list style="background-color: transparent">
          <span v-html="getRegistrationInformation('description')" />
          <v-list-item :prepend-icon="mdiFormatListBulleted">
            <span v-html="getRegistrationInformation('quoteHistory')" />
          </v-list-item>
          <v-list-item :prepend-icon="mdiFileDocumentOutline">
            <span v-html="getRegistrationInformation('quoteDocument')" />
          </v-list-item>
          <v-list-item :prepend-icon="mdiSendVariantOutline">
            <span v-html="getRegistrationInformation('quoteRequest')" />
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </template>
</template>

<script lang="ts">
import SfAlertSuccess from '@/components/alerts/SfAlertSuccess.vue'
import GuestRegistrationForm from '@/modules/authentication/components/GuestRegistrationForm.vue'
import LoginWrapper from '@/modules/authentication/components/LoginWrapper.vue'
import useRegistration from '@/modules/authentication/useRegistration'
import useBranding from '@/modules/portalSettings/useBranding'
import { QuoteStatus } from '@/modules/quote/types'
import { GuestQuoteEvent } from '@/modules/tracking/events/quote'
import { trackEvent } from '@/modules/tracking/useTracking'
import { Password } from '@/modules/user/types'
import { useTitle } from '@/helpers'
import { storeToRefs } from 'pinia'
import { defineComponent, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { mdiFileDocumentOutline, mdiFormatListBulleted, mdiSendVariantOutline } from '@mdi/js'

export default defineComponent({
  name: 'GuestRegistrationPage',
  components: {
    GuestRegistrationForm,
    LoginWrapper,
    SfAlertSuccess
  },
  setup() {
    const { t } = useI18n()
    useTitle(ref(`${t('windowTitle.Registration')}`))
    const quoteStatus = useRoute().query.quoteStatus

    const { branding } = useBranding()
    const { sending, loading } = storeToRefs(useRegistration())

    const finishRegistration = (dto: Password) => {
      useRegistration().guestRegistration(dto)
      trackEvent(GuestQuoteEvent.Register)
    }
    function getRegistrationInformation(reference: string) {
      return ref(`${t('quoteRegistration.' + reference)}`).value
    }

    return {
      branding,
      sending,
      loading,
      finishRegistration,
      getRegistrationInformation,
      quoteStatus,
      QuoteStatus,
      mdiFileDocumentOutline,
      mdiFormatListBulleted,
      mdiSendVariantOutline
    }
  }
})
</script>
