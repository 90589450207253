<template>
  <div
    class="d-flex justify-space-between align-start"
    :style="!$vuetify.display.mobile ? 'height: 36px' : ''"
  >
    <div class="d-flex flex-row">
      <show-categories-button class="mr-1" />
      <editable-heading :value="collection.name" @input="updateTitle" />
      <v-chip v-if="collection.isArchived" class="ml-2" label variant="outlined" color="grey">
        {{ $t('common.archived') }}
      </v-chip>
    </div>
    <three-point-menu dense>
      <collection-add-to-cart-button :collection="collection" />
      <collection-request-quote v-if="portalSettings.useQuotes" :collection="collection" />
      <collection-share-button :collection="collection" />
      <v-divider class="my-1" />
      <collection-archive-button :is-archived="collection.isArchived" />
      <collection-duplicate-button :id="collection.id" />
      <collection-delete-button :id="collection.id" />
    </three-point-menu>
  </div>
</template>

<script lang="ts">
import EditableHeading from '@/components/input/EditableHeading.vue'
import ShowCategoriesButton from '@/components/ShowCategoriesButton.vue'
import ThreePointMenu from '@/components/three-point-menu/ThreePointMenu.vue'
import CollectionAddToCartButton from '@/modules/collection/components/CollectionAddToCartButton.vue'
import CollectionArchiveButton from '@/modules/collection/components/CollectionArchiveButton.vue'
import CollectionDeleteButton from '@/modules/collection/components/CollectionDeleteButton.vue'
import CollectionDuplicateButton from '@/modules/collection/components/CollectionDuplicateButton.vue'
import CollectionShareButton from '@/modules/collection/components/CollectionShareButton.vue'
import useCollection from '@/modules/collection/useCollection'
import { PortalSettings } from '@/modules/portalSettings/types'
import { defineComponent, PropType } from 'vue'
import { DetailedShopCollection } from '@/modules/collection/types'
import CollectionRequestQuote from '@/modules/collection/components/CollectionRequestQuote.vue'

export default defineComponent({
  name: 'CollectionHeader',
  components: {
    CollectionAddToCartButton,
    CollectionArchiveButton,
    CollectionDeleteButton,
    CollectionDuplicateButton,
    CollectionRequestQuote,
    CollectionShareButton,
    EditableHeading,
    ThreePointMenu,
    ShowCategoriesButton
  },
  props: {
    collection: {
      type: Object as PropType<DetailedShopCollection>,
      required: true
    },
    portalSettings: {
      type: Object as PropType<PortalSettings>,
      required: true
    }
  },
  setup() {
    const updateTitle = (title: string) => {
      useCollection().updateName(title)
    }

    return { updateTitle }
  }
})
</script>
