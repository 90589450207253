import { Event } from '@/modules/tracking/types'

export default class MyCompanyEvent extends Event {
  static ChangeSubsidiary = new MyCompanyEvent('Firma gewechselt')

  static Tabs = class {
    static Employees = new MyCompanyEvent('Tab angeklickt', 'Mitarbeiter')
    static Addresses = new MyCompanyEvent('Tab angeklickt', 'Adressen')
    static OrderApproval = new MyCompanyEvent('Tab angeklickt', 'Bestellfreigabe')
  }

  static OrderLimit = class {
    static Default = class {
      static Activated = new MyCompanyEvent('Bestelllimits aktiviert')
      static Deactivated = new MyCompanyEvent('Bestelllimits deaktiviert')
      static Limit = new MyCompanyEvent('Allgemeines Limit geändert')
      static Assignees = new MyCompanyEvent('Standardfreigabebearbeiter geändert')
    }
    static Individual = class {
      static Assignees = new MyCompanyEvent('Individuelle Standardfreigabebearbeiter geändert')
      static OrderRight = new MyCompanyEvent('Individuelles Bestellrecht geändert')
      static Limit = new MyCompanyEvent('Individuelles Bestelllimit geändert')
    }
  }
  private constructor(action: string, label?: string) {
    super('Meine Firma', action, label ?? '')
  }
}
